var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BreakpointsPx, colors, ImpContainer, ImpDivider, ImpFlex, ImpHidden, ImpPad, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import { useStatsigClient } from '@statsig/react-bindings';
import { useEffect, useMemo, useState } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { MODAL_ENUM, SECTION, setSection, setShowModal } from '../../app/applicationScreenSlice';
import { useImpState } from '../../app/hooks';
import { setLanguage } from '../../app/languageSlice';
import { SCREEN } from '../../app/screenStateSlice';
import { ANALYTICS } from '../../firebase/firebase';
import { setInStoreParams, setUtmSource } from '../../lib/utmManager';
import Gates from '../../statsig/gates';
import { setSessionLanguage } from '../../utils/functionUtils';
import { LanguageCode } from '../../utils/languageConstants';
import { PAGE } from '../../utils/routeConstant';
import Collapse from '../collapse/Collapse';
import IncomeHousingV2 from '../incomeHousingID/IncomeHousingV2';
import LoginModal from '../loginModal/LoginModal';
import PersonalInfo from '../personalinfo/PersonalInfo';
import SelectionModal from '../selectionModal/SelectionModal';
import WelcomeBackCard from '../welcomeBackCard/WelcomeBackCard';
import styles from './Application.module.css';
import InStoreModal from './InStoreModal';
import { RewardsDisplay } from './RewardsDisplay';
import PrefillFlow from '../prove/PrefillFlow';
function ManualApplication() {
    var _a = useImpState(), appScreenState = _a.appScreenState, consumerInfo = _a.consumerInfo, language = _a.language, dispatch = _a.dispatch;
    var _b = useState(false), savePersonalInfo = _b[0], setSavePersonalInfo = _b[1];
    var nextSection = function (curSection) {
        if (appScreenState.section !== curSection) {
            return curSection;
        }
        return (curSection + 1) % (Object.keys(SECTION).length / 2);
    };
    return (_jsxs(ImpPad, __assign({ padding: "0px 24px", mobilePadding: "0px 16px" }, { children: [appScreenState.showWelcomeBackCard && consumerInfo.consumerProfile.name.firstName && (_jsx(WelcomeBackCard, { firstName: consumerInfo.consumerProfile.name.firstName })), _jsx(Collapse, __assign({ title: language.personalInfo, notice: language.personalInfoNotice, number: 1, close: appScreenState.section !== SECTION.PERSONALINFO, dropdownClick: function () {
                    setSavePersonalInfo(!savePersonalInfo);
                    dispatch(setSection(nextSection(SECTION.PERSONALINFO)));
                } }, { children: _jsx(PersonalInfo, { saveData: savePersonalInfo }) })), _jsx(ImpDivider, { color: colors.border.borderVariant }), _jsx(Collapse, __assign({ title: language.incomeVerify, notice: language.personalInfoNotice, number: 2, close: appScreenState.section !== SECTION.INCOME, dropdownClick: function () {
                    setSavePersonalInfo(!savePersonalInfo);
                    dispatch(setSection(nextSection(SECTION.INCOME)));
                } }, { children: _jsx(IncomeHousingV2, {}) })), _jsx(ImpDivider, { color: colors.border.borderVariant }), _jsx(Collapse, { title: language.receiveOffer, number: 3 })] })));
}
export default function Application() {
    var checkGate = useStatsigClient().checkGate;
    var proveEnabled = useMemo(function () { return checkGate(Gates.ProveGate); }, []);
    var _a = useImpState(), language = _a.language, merchantData = _a.merchantData, screenState = _a.screenState, appScreenState = _a.appScreenState, apiScreenState = _a.apiScreenState, dispatch = _a.dispatch, navigate = _a.navigate;
    // workaround for session storage being unreliable. Proactively stuff it in local storage for safer keeping
    var utmSource = sessionStorage.getItem('utm_source');
    if (utmSource) {
        setUtmSource(merchantData.productUUID, utmSource);
    }
    setInStoreParams(merchantData.productUUID);
    useEffect(function () {
        if (screenState.screen == SCREEN.OTPSCREEN) {
            navigate(PAGE.OTP);
        }
    }, [screenState.screen]);
    var switchToSpanish = function () {
        setSessionLanguage(LanguageCode.Spanish, apiScreenState.env);
        dispatch(setLanguage(LanguageCode.Spanish));
        dispatch(setShowModal(MODAL_ENUM.noModal));
    };
    var renderSetLanguageDisclaimerModal = function () {
        return (_jsx(SelectionModal, { header: language.areYouSure, body: language.switchToSpanish, handleCloseBtn: function () {
                dispatch(setShowModal(MODAL_ENUM.noModal));
                ANALYTICS.logEvent(EVENT.LANGUAGE_CANCEL_CLICKED, {
                    page: 'language_selection_personal_information',
                    language: LanguageCode.English,
                });
            }, buttonName1: language.switchToSpanishButton, button1Click: function () {
                switchToSpanish();
                ANALYTICS.logEvent(EVENT.LANGUAGE_CONTINUE_CLICKED, {
                    page: 'language_selection_personal_information',
                    language: LanguageCode.Spanish,
                });
            }, pillBtn1: true }));
    };
    var renderExistingUserModal = function () {
        switch (appScreenState.showModal) {
            case MODAL_ENUM.showLoginModal:
                return _jsx(LoginModal, {});
            case MODAL_ENUM.showWelcomeBackInStoreModal:
                return _jsx(InStoreModal, { sourcePage: "existing" });
            default:
                return null;
        }
    };
    return (_jsxs("div", __assign({ className: styles.container }, { children: [appScreenState.showModal === MODAL_ENUM.showLanguageDisclaimerModal &&
                renderSetLanguageDisclaimerModal(), renderExistingUserModal(), appScreenState.inStoreDevice && !appScreenState.requireFullSSN && (_jsx(InStoreModal, { sourcePage: "signup" })), _jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsx(ImpHidden, __assign({ hideAbove: BreakpointsPx.Mobile, fluid: true }, { children: _jsx(ImpContainer, __assign({ width: "100%", textAlign: "left", backgroundColor: colors.surface.surfaceHigh, borderBottom: "1px solid ".concat(colors.border.borderVariant) }, { children: _jsx(ImpPad, __assign({ padding: '8px 16px' }, { children: _jsx(ImpText, __assign({ color: colors.content.onSurfaceVariant }, { children: language.personalInfoNotice })) })) })) })), _jsx(ImpHidden, __assign({ hideAbove: BreakpointsPx.Application, hideBelow: BreakpointsPx.Mobile }, { children: _jsx(ImpSpacer, { height: "2xl" }) })), _jsx(ImpContainer, __assign({ width: "532px", mobileWidth: "100%", backgroundColor: colors.surface.surfaceHigh, borderRadius: "12px", mobileBorderRadius: "0px", border: "1px solid ".concat(colors.border.borderVariant), mobileBorder: "none" }, { children: proveEnabled ? _jsx(PrefillFlow, {}) : _jsx(ManualApplication, {}) }))] })), _jsx(ImpHidden, __assign({ hideBelow: BreakpointsPx.Application }, { children: _jsxs(ImpFlex, { children: [_jsx(ImpSpacer, { width: "l" }), _jsx(RewardsDisplay, {})] }) }))] })));
}
