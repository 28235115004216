var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ImpAccountStatus, ImpButton, ImpContainer, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import { useEffect } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { submitApplication } from '../../api/api';
import { useResendOtp } from '../../api/login/useLogin';
import { API_RESPONSE_CODE } from '../../api/responseVals';
import { useVerifyOtp } from '../../api/verifyOtp.api';
import { MODAL_ENUM, requireFullSSN, setLoading, setShowLinkHHScreen, setShowModal, } from '../../app/applicationScreenSlice';
import { useAppSelector, useImpState } from '../../app/hooks';
import { setMultiCardUser, setShowSignUp } from '../../app/loginSlice';
import { setIncorrectOtpCode, setReachedMaxRetries, wrongOtpCode } from '../../app/otpScreenSlice';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { applicationStatus, productID } from '../../assets/consts/const';
import { HeaderHandler } from '../../components/HeaderHandler/HeaderHandler';
import LinkHHAccount from '../../components/linkHHAccount/LinkHHAccount';
import Modal from '../../components/modal/Modal';
import WaterfallModal from '../../components/modal/WaterfallModal';
import OtpCardNew from '../../components/otp/OtpCardNew';
import { ANALYTICS } from '../../firebase/firebase';
import useNextPage from '../../hooks/nextPage';
import { formatPhoneNumber } from '../../utils/formatUtils';
import { clearHomePageState, getOfferUUIDs } from '../../utils/functionUtils';
import { merchantInfos } from '../../utils/productUUIDConstants';
import { PAGE } from '../../utils/routeConstant';
import UnfreezeCreditModal from '../otp/UnfreezeCreditModal';
import styles from './OtpVerify.module.css';
export default function OtpVerify() {
    var nextPage = useNextPage();
    var _a = useImpState(), language = _a.language, merchantData = _a.merchantData, screenState = _a.screenState, consumerInfo = _a.consumerInfo, dispatch = _a.dispatch, navigate = _a.navigate, appScreenState = _a.appScreenState, appStatus = _a.appStatus;
    var multiCardUser = useAppSelector(function (state) { return state.loginSelector.multiCardUser; });
    useEffect(function () {
        nextPage();
    }, [screenState.screen]);
    var mutation = useVerifyOtp();
    var submitHandler = function (code) {
        mutation.mutate({ code: code }, {
            onSuccess: function () {
                dispatch(wrongOtpCode(false));
                dispatch(setIncorrectOtpCode(false));
                if (merchantData.rewardDetails.merchantName &&
                    merchantData.productUUID === productID.horizonHobby &&
                    !appScreenState.eligibilityVerified) {
                    dispatch(setShowLinkHHScreen(true));
                    dispatch(setLoading(false));
                    return;
                }
                sendSubmitApplicationRequest();
            },
            onError: function (err) {
                if (err.code === API_RESPONSE_CODE.INCORRECT_AUTH) {
                    dispatch(setIncorrectOtpCode(true));
                    dispatch(wrongOtpCode(true));
                    dispatch(setLoading(false));
                    dispatch(setScreen(SCREEN.OTPSCREEN));
                }
                if (err.code === API_RESPONSE_CODE.EXPIRED_AUTH) {
                    dispatch(setReachedMaxRetries(true));
                    dispatch(setLoading(false));
                    dispatch(setScreen(SCREEN.STATUS_OTP_VERIFICATION_FAILED));
                }
            },
        });
    };
    var sendSubmitApplicationRequest = function () {
        dispatch(submitApplication({
            rewardsInfo: consumerInfo.rewardsInfo,
            consumer: consumerInfo.consumerProfile,
            consumerFactor: consumerInfo.consumerFactor,
            productUUID: merchantData.productUUID,
            isMultiCard: multiCardUser,
            offerUUIDs: getOfferUUIDs(),
        }));
    };
    function backClick() {
        clearHomePageState(dispatch);
        navigate(PAGE.HOME);
    }
    function loadDeeplink() {
        window.open(merchantData.deeplink);
    }
    useEffect(function () {
        window.history.pushState('fake-route', document.title, window.location.href);
        // eslint-disable-next-line no-restricted-globals
        addEventListener('popstate', backClick);
        return function () {
            // eslint-disable-next-line no-restricted-globals
            removeEventListener('popstate', backClick);
            if (window.history.state === 'fake-route') {
                window.history.back();
            }
        };
    }, []);
    var resendOtpMutation = useResendOtp();
    var resendHandler = function () {
        resendOtpMutation.mutate({ phone: consumerInfo.consumerProfile.phone });
    };
    var handleViewDeclineDetailsClick = function () {
        ANALYTICS.logEvent(EVENT.APP_DECLINE_LINK_CLICKED, {
            link: 'view_decline_details',
        });
        if (appStatus.rejectionReasonsURL !== '')
            window.open(appStatus.rejectionReasonsURL);
    };
    var handleGoBackClick = function () {
        clearHomePageState(dispatch);
    };
    var handleHowToUnfreezeModal = function () {
        dispatch(setShowModal(MODAL_ENUM.showHowToUnfreezeModal));
    };
    function getOTPSubline() {
        var phone = consumerInfo.consumerProfile.phone;
        var string_arr = language.otpSubline.split('%s');
        return "".concat(string_arr[0]).concat(formatPhoneNumber(phone)).concat(string_arr[1]);
    }
    var getButtonState = function (loading) {
        if (loading) {
            return 'loading';
        }
        else {
            return 'enabled';
        }
    };
    var renderNotApprovedBody = function () {
        return (_jsxs(ImpContainer, __assign({ textAlign: "center" }, { children: [_jsx(ImpSpacer, { height: "xl" }), _jsx(ImpText, __assign({ typography: "body2", mobileTypography: "body3" }, { children: language.applicationNotApprovedBody.replace('$cardName$', merchantData.rewardDetails.cardName) }))] })));
    };
    var handleApplyAgain = function () {
        dispatch(setScreen(SCREEN.APPLICATION));
        navigate(PAGE.HOME);
        if (appStatus.reApplicationInApp) {
            dispatch(requireFullSSN(false));
            dispatch(setMultiCardUser(false));
            dispatch(setShowSignUp(true));
        }
        ANALYTICS.logEvent(EVENT.APPLICATION_EVALUATION_DECISION_REJECTED_ACTION_CLICKED, {
            product_uuid: merchantData.productUUID,
        });
    };
    var renderDeclineDetailsButton = function () {
        return (_jsxs(ImpContainer, __assign({ textAlign: "center" }, { children: [_jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpButton, __assign({ variant: "text", buttonStyle: "branded", size: "m", state: getButtonState(appScreenState.loading), onClick: function () { return handleViewDeclineDetailsClick(); } }, { children: language.viewDeclineDetailsBtn }))] })));
    };
    var renderApplyAgainButton = function () {
        return (_jsxs(ImpContainer, __assign({ textAlign: "center" }, { children: [_jsx(ImpSpacer, { height: "xl" }), _jsx(ImpButton, __assign({ variant: "primary", buttonStyle: "branded", size: "m", state: getButtonState(appScreenState.loading), onClick: function () { return handleApplyAgain(); } }, { children: language.applyAgain }))] })));
    };
    var renderButtonsGroup = function () {
        return (_jsxs(_Fragment, { children: [renderDeclineDetailsButton(), appStatus.evaluationRejectReapply && renderApplyAgainButton()] }));
    };
    var getApplicationNotApprovedView = function () {
        if (appStatus.showCounterOffer) {
            return (_jsx(WaterfallModal, { handleViewDeclineDetailsClick: handleViewDeclineDetailsClick, showApplyAgainButton: appStatus.evaluationRejectReapply, handleApplyAgainClick: handleApplyAgain }));
        }
        else {
            return (_jsx(ImpAccountStatus, { header: language.applicationNotApprovedTitle, body1: renderNotApprovedBody(), body1CenterAlign: true, buttons: renderButtonsGroup() }));
        }
    };
    var renderStatusModal = function (status) {
        switch (status) {
            case applicationStatus.creditFrozen: {
                ANALYTICS.logEvent(EVENT.FROZEN_CREDIT_VIEWED);
                return (_jsx(Modal, { header: language.afterOtpAppStatusFrozenCreditHeader, body1: language.afterOtpAppStatusFrozenCreditBody, link: true, linkText: language.afterOtpAppStatusFrozenCreditBtn, linkClick: function () {
                        ANALYTICS.logEvent(EVENT.FROZEN_CREDIT_INFO_EDU_VIEWED);
                        handleHowToUnfreezeModal();
                    }, pillBtn1: true }));
            }
            case applicationStatus.inReview: {
                ANALYTICS.logEvent(EVENT.MANUAL_REVIEW_VIEWED);
                return (_jsx(Modal, { header: language.afterOtpAppStatusinReviewHeader, body1: language.afterOtpAppStatusInReviewBody, qrMessage: language.qrDownload.replace('$link$', merchantInfos[merchantData.productUUID].displaySignupLink), showQR: true, qr: merchantData.confirmLaterQR, buttonName1: language.downloadBtn, button1Click: loadDeeplink, pillBtn1: true, hideMobileBtn1: true, includeSupportEmail: true }));
            }
            case applicationStatus.notApproved: {
                ANALYTICS.logEvent(EVENT.APP_DECLINE_VIEWED);
                return getApplicationNotApprovedView();
            }
            case applicationStatus.asyncApplication: {
                return (_jsx(Modal, { header: language.afterOtpAppStatusAsyncApplicationCreditHeader, body1: language.afterOtpAppStatusAsyncApplicationCreditBody, includeSupportEmail: true, pillBtn1: true, delay: 10000 }));
            }
            default: {
                return (_jsx(Modal, { header: language.appStatusFailedHeader, body1: language.appStatusFailedBody, buttonName1: language.gotItBtn, button1Click: handleGoBackClick, pillBtn1: true }));
            }
        }
    };
    if (language === null) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return _jsx(_Fragment, {});
    }
    return (_jsxs("div", __assign({ className: styles.container }, { children: [_jsx(HeaderHandler, {}), _jsx("div", __assign({ className: appStatus.status !== '' || appScreenState.showLinkHHScreen
                    ? styles.hide
                    : styles.cardContainer }, { children: _jsx(OtpCardNew, { title: language.otpTitle, onFull: submitHandler, handleResend: resendHandler, bottomSubline: getOTPSubline() }) })), appStatus.status !== '' ? renderStatusModal(appStatus.status) : '', appScreenState.showModal === MODAL_ENUM.showHowToUnfreezeModal && (_jsx(_Fragment, { children: _jsx(UnfreezeCreditModal, {}) })), appScreenState.showLinkHHScreen && appStatus.status === '' && (_jsx(_Fragment, { children: _jsx(LinkHHAccount, { consumer: consumerInfo.consumerProfile, productUUID: merchantData.productUUID, loadingBtn: appScreenState.loading, callback: sendSubmitApplicationRequest }) }))] })));
}
