var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation } from '@tanstack/react-query';
import { login } from './login.api';
import { showError } from '../api';
import { useAppDispatch } from '../../app/hooks';
import { setAuth, setConsumerUUID } from '../../app/consumerInfoSlice';
import { store } from '../../app/store';
export default function useLogin() {
    var dispatch = useAppDispatch();
    var auth = store.getState().consumerInfoSelector.auth;
    return useMutation({
        mutationFn: login,
        onSuccess: function (data) {
            dispatch(setConsumerUUID(data.consumerUUID));
            dispatch(setAuth(__assign(__assign({}, auth), { requestID: data.requestID })));
        },
        onError: function () {
            showError(dispatch);
        },
    });
}
// TODO: should be sending resend=true or something
export var useResendOtp = useLogin;
