import { emptyPaymentAccount } from './PaymentAccount';
import { emptyUserInformation } from './UserInformation';
export var initialLoadHomeResponse = {
    userInformation: emptyUserInformation,
    primaryPaymentAccount: emptyPaymentAccount,
    productAccounts: [],
    actions: [],
    hasCreditCard: false,
    hasSpendCard: false,
};
