import { BASE_URL, ENV, setBaseURL, setEnv, setInternalAuthRequired } from '../app/apiScreenSlice';
import { resetMerchantData, setDeeplink, setProductUUID } from '../app/merchantDataSlice';
import { store } from '../app/store';
import { INTERNAL_AUTH_MERCHANTS } from './environmentsConfig';
import { getProductUUIDFromPartnerKey, MERCHANT_KEY, merchantInfos } from './productUUIDConstants';
export function isLocal(domain) {
    // Checks for common private IP address patterns or 'localhost'
    var localIpRegex = /^(192\.168|10\.|172\.(1[6-9]|2[0-9]|3[0-1]))\.\d+\.\d+$/;
    return domain === ENV.LOCAL || localIpRegex.test(domain);
}
// wonder if this works
export function isDev() {
    return isLocal(window.location.hostname.toLowerCase());
}
export function isStaging() {
    return window.location.hostname.toLowerCase().split('.').includes(ENV.STG);
}
export function isInternalProduction(domain) {
    return domain.includes('int-imprint');
}
// navigates if an unrecognized path is provided
export function navigateToDefault(domain) {
    var toNavigate = 'https://account.imprint.co';
    var env = getEnv(domain)[0];
    if (env === ENV.STG || env === ENV.LOCAL) {
        toNavigate = 'https://account.stg.imprintapi.co';
    }
    window.location.href = toNavigate;
}
export var fetchEnvFromDomain = function (domain, path) {
    var _a;
    var _b = getEnv(domain), env = _b[0], baseURL = _b[1];
    var partnerKey = sessionStorage.getItem('partner-key') || '';
    //HH and WG has legacy url that contains merchant name in domain
    var pUUID = getProductUUIDFromDomain(domain) ||
        getProductUUIDFromDomain(path) ||
        getProductUUIDFromPartnerKey(partnerKey);
    if (pUUID === '') {
        store.dispatch(resetMerchantData());
        navigateToDefault(domain);
        return;
    }
    var requireInternalAuth = requiresInternalAuth(pUUID, env);
    var deeplink = ((_a = merchantInfos[pUUID]) === null || _a === void 0 ? void 0 : _a.displaySignupLink) || '';
    store.dispatch(setDeeplink(deeplink));
    store.dispatch(setProductUUID(pUUID));
    store.dispatch(setBaseURL(baseURL));
    store.dispatch(setEnv(env));
    store.dispatch(setInternalAuthRequired(requireInternalAuth));
};
export var getDomainFromMerchant = function (productUUID) {
    var url = 'https://apply.imprint.co';
    var env = store.getState().apiScreenScreenSelector.env;
    if (env === ENV.STG) {
        url = 'https://apply.stg.imprintapi.co';
    }
    else if (env === ENV.LOCAL) {
        url = 'http://localhost:8080';
    }
    if (merchantInfos[productUUID]) {
        return url + merchantInfos[productUUID].pageToNavigate;
    }
    else {
        return 'https://account.imprint.co';
    }
};
export var getProductUUIDFromDomain = function (domain) {
    var k;
    for (k in MERCHANT_KEY) {
        var keyword = MERCHANT_KEY[k];
        if (domain.includes(keyword.toLowerCase())) {
            return merchantInfos[k].productUUID;
        }
    }
    return '';
};
export function getEnv(domain) {
    if (isLocal(domain)) {
        return [ENV.LOCAL, BASE_URL.STG];
    }
    else if (domain.split('.').includes(ENV.STG)) {
        return [ENV.STG, BASE_URL.STG];
    }
    else {
        return [ENV.PROD, BASE_URL.PROD];
    }
}
export var requiresInternalAuth = function (productUUID, env) {
    if (env !== ENV.PROD) {
        return false;
    }
    return INTERNAL_AUTH_MERCHANTS.includes(productUUID);
};
