var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { autocompleteAddress } from '@imprint-payments/imprint-lib';
import { BreakpointsPx, colors, ImpButton, ImpContainer, ImpFlex, ImpFlexItem, ImpHidden, ImpInlineLink, ImpInput, ImpSelect, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import { useEffect, useRef, useState } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { MODAL_ENUM, SECTION, setFullNumberEntered, setSection, } from '../../app/applicationScreenSlice';
import { setConsumerProfile } from '../../app/consumerInfoSlice';
import { useAppSelector, useImpState } from '../../app/hooks';
import { setEligiblePhoneNumber } from '../../app/loginSlice';
import { SCREEN } from '../../app/screenStateSlice';
import { setAddress1Error, setAddress2Error, setCityError, setEmailError, setFnError, setFullDobError, setLnError, setPhoneNumberError, setStateError, setZipError, } from '../../app/validatorSlice';
import { PARAM_PHONE, PHONE_NUMBER_MAX_LENGTH } from '../../assets/consts/const';
import { ANALYTICS } from '../../firebase/firebase';
import { formatDateValue, formatDob, formatPhoneNumber, formatState, } from '../../utils/formatUtils';
import { getPreapprovalCodeFromSession } from '../../utils/functionUtils';
import { getValidationString, hideWhileTyping, noError, validateAddress, validateBirthDate, validateCity, validateEmail, validateName, validatePhoneNumber, validateState, validateZip, ValidationResponse, } from '../../utils/validateUtils';
import { getAccountLink } from '../AlreadyAppliedText/AlreadyAppliedText';
import { LanguageSelection } from './LanguageSelection';
import { PrescreenLabel } from './PrescreenLabel';
import useCheckoutAndLogin from '../../api/login/useCheckoutAndLogin';
import { InStoreLabels } from './InStoreLabels';
import { getEmployeeId, getStoreNumber } from '../../lib/utmManager';
var inputWarning = [ValidationResponse.emailPotentialTypo];
export default function PersonalInfo(_a) {
    var _this = this;
    var saveData = _a.saveData;
    var _b = useImpState(), language = _b.language, merchantData = _b.merchantData, screenState = _b.screenState, dispatch = _b.dispatch, appScreenState = _b.appScreenState, appStatus = _b.appStatus, loginState = _b.loginState, consumerProfile = _b.consumerProfile, featureFlags = _b.featureFlags;
    //info states
    var _c = useState(consumerProfile.name.firstName), firstName = _c[0], setFirstName = _c[1];
    var _d = useState(false), disabledFirstName = _d[0], setDisabledFirstName = _d[1];
    var _e = useState(consumerProfile.name.lastName), lastName = _e[0], setLastName = _e[1];
    var _f = useState(false), disabledLastName = _f[0], setDisabledLastName = _f[1];
    var _g = useState(true), disabledBtn = _g[0], setDisabledBtn = _g[1];
    var _h = useState(consumerProfile.email), email = _h[0], setEmail = _h[1];
    var _j = useState(false), disabledEmail = _j[0], setDisabledEmail = _j[1];
    var _k = useState(consumerProfile.dob.day === 0 ? '' : String(consumerProfile.dob.day)), birthDayDay = _k[0], setBirthDayDay = _k[1];
    var _l = useState(false), disabledBirthDayDay = _l[0], setDisabledBirthDayDay = _l[1];
    var _m = useState(consumerProfile.dob.month === 0 ? '' : String(consumerProfile.dob.month)), birthDayMonth = _m[0], setBirthDayMonth = _m[1];
    var _o = useState(false), disabledBirthDayMonth = _o[0], setDisabledBirthDayMonth = _o[1];
    var _p = useState(consumerProfile.dob.year === 0 ? '' : String(consumerProfile.dob.year)), birthDayYear = _p[0], setBirthDayYear = _p[1];
    var _q = useState(false), disabledBirthDayYear = _q[0], setDisabledBirthDayYear = _q[1];
    var birthDay = formatDob("".concat(formatDateValue(Number(birthDayMonth)), "/\n    ").concat(formatDateValue(Number(birthDayDay)), "/\n    ").concat(formatDateValue(Number(birthDayYear))));
    var _r = useState(consumerProfile.phone), phoneNumber = _r[0], setPhoneNumber = _r[1];
    var _s = useState(consumerProfile.address.streetLine1), street = _s[0], setStreet = _s[1];
    var _t = useState(false), disabledStreet = _t[0], setDisabledStreet = _t[1];
    var _u = useState(consumerProfile.address.streetLine2), apartment = _u[0], setApartment = _u[1];
    var _v = useState(false), disabledApartment = _v[0], setDisabledApartment = _v[1];
    var _w = useState(consumerProfile.address.city), city = _w[0], setCity = _w[1];
    var _x = useState(false), disabledCity = _x[0], setDisabledCity = _x[1];
    var _y = useState(consumerProfile.address.zipCode), zip = _y[0], setZip = _y[1];
    var _z = useState(false), disabledZip = _z[0], setDisabledZip = _z[1];
    var _0 = useState(consumerProfile.address.state), state = _0[0], setState = _0[1];
    var _1 = useState(false), disabledState = _1[0], setDisabledState = _1[1];
    //view control state
    var _2 = useState(false), showMoreFields = _2[0], setShowMoreFields = _2[1];
    var _3 = useState(appScreenState.requireFullSSN ||
        !appScreenState.fullNumberEntered ||
        !loginState.loginFlow.loginError), disabledInputField = _3[0], setDisabledInputField = _3[1];
    var _4 = useState(false), isMultiCardApplicationFlow = _4[0], setIsMultiCardApplicationFlow = _4[1];
    var _5 = useState(false), birthDayChecked = _5[0], setBirthDayChecked = _5[1];
    var validator = useAppSelector(function (state) { return state.validatorSelector; });
    // Set to track whether each section's log event has been triggered
    var visitedSectionsRef = useRef(new Set());
    useEffect(function () {
        return function () {
            // Clear prefilled phone when the component unmounts
            sessionStorage.removeItem(PARAM_PHONE);
        };
    }, []);
    useEffect(function () {
        if (!appScreenState.requireFullSSN) {
            var section = appScreenState.section;
            if (visitedSectionsRef.current.has(section)) {
                return;
            }
            visitedSectionsRef.current.add(section);
            if (section === SECTION.INCOME) {
                ANALYTICS.logEvent(EVENT.INCOME_HOUSING_VIEWED);
            }
            else {
                ANALYTICS.logEvent(EVENT.PERSONAL_INFO_VIEWED);
            }
        }
    }, [appScreenState.section]);
    useEffect(function () {
        if (appScreenState.showWelcomeBackCard) {
            ANALYTICS.logEvent(EVENT.RETURNING_USER_PERSONAL_INFO_VIEWED, {
                product_uuid: merchantData.productUUID,
            });
            if (loginState.multiCardUser) {
                setIsMultiCardApplicationFlow(true);
            }
            else {
                setIsMultiCardApplicationFlow(false);
            }
        }
    }, [appScreenState.showWelcomeBackCard, loginState.multiCardUser]);
    useEffect(function () {
        if (appScreenState.showWelcomeBackCard && loginState.multiCardUser) {
            consumerProfile.name.firstName !== '' && setDisabledFirstName(true);
            consumerProfile.name.lastName !== '' && setDisabledLastName(true);
            consumerProfile.email !== '' && setDisabledEmail(true);
            consumerProfile.dob.day !== 0 && setDisabledBirthDayDay(true);
            consumerProfile.dob.month !== 0 && setDisabledBirthDayMonth(true);
            consumerProfile.dob.year !== 0 && setDisabledBirthDayYear(true);
            consumerProfile.address.streetLine1 !== '' && setDisabledStreet(true);
            consumerProfile.address.streetLine2 !== '' && setDisabledApartment(true);
            consumerProfile.address.city !== '' && setDisabledCity(true);
            consumerProfile.address.zipCode !== '' && setDisabledZip(true);
            consumerProfile.address.state !== '' && setDisabledState(true);
        }
        // if user reapply after evaluation, we enable input fileds,
        // same behavior as mobile (after click "edit info" in mobile)
        // for income housing, we only enable ssn4/fullSSN
        if (appStatus.reApplicationInApp && appScreenState.requireFullSSN) {
            setDisabledFirstName(true);
            setDisabledLastName(true);
            setDisabledEmail(true);
            setDisabledBirthDayDay(true);
            setDisabledBirthDayMonth(true);
            setDisabledBirthDayYear(true);
            setDisabledStreet(true);
            setDisabledApartment(true);
            setDisabledCity(true);
            setDisabledZip(true);
            setDisabledState(true);
        }
    }, [
        appScreenState.showWelcomeBackCard,
        loginState.multiCardUser,
        consumerProfile,
        appStatus.reApplicationInApp,
    ]);
    useEffect(function () {
        if (appScreenState.showModal === MODAL_ENUM.showLoginModal) {
            setPhoneNumber('');
        }
    }, [appScreenState.showModal]);
    useEffect(function () {
        if (validator.phoneNumberError === ValidationResponse.valid &&
            (validator.emailError === ValidationResponse.valid ||
                validator.emailError === ValidationResponse.emailPotentialTypo) &&
            (isMultiCardApplicationFlow || validator.birthDateError === ValidationResponse.valid) &&
            validator.address1Error === ValidationResponse.valid &&
            (validator.address2Error === ValidationResponse.valid ||
                validator.address2Error === ValidationResponse.empty) &&
            validator.cityError === ValidationResponse.valid &&
            validator.zipError === ValidationResponse.valid &&
            validator.stateError === ValidationResponse.valid &&
            validator.fnError === ValidationResponse.valid &&
            validator.lnError === ValidationResponse.valid) {
            setDisabledBtn(false);
        }
        else {
            setDisabledBtn(true);
        }
    }, [state, validator]);
    var getPersonalInfoButtonState = function () {
        if (disabledBtn) {
            return 'disabled';
        }
        return 'enabled';
    };
    useEffect(function () {
        setFirstName(consumerProfile.name.firstName);
        setLastName(consumerProfile.name.lastName);
        setEmail(consumerProfile.email);
        setBirthDayDay(consumerProfile.dob.day === 0 ? '' : String(consumerProfile.dob.day));
        setBirthDayMonth(consumerProfile.dob.month === 0 ? '' : String(consumerProfile.dob.month));
        setBirthDayYear(consumerProfile.dob.year === 0 ? '' : String(consumerProfile.dob.year));
        if (consumerProfile.phone) {
            setPhoneNumber(formatPhoneNumber(consumerProfile.phone));
        }
        else {
            // Prefill phone number if it is passed through a query parameter
            prefillPhoneNumberFromQueryParam();
        }
        setStreet(consumerProfile.address.streetLine1);
        setApartment(consumerProfile.address.streetLine2);
        setCity(consumerProfile.address.city);
        setZip(consumerProfile.address.zipCode);
        setState(consumerProfile.address.state);
    }, [consumerProfile]);
    var prefillPhoneNumberFromQueryParam = function () {
        var phone = sessionStorage.getItem(PARAM_PHONE);
        if (phone) {
            setPhoneNumber(formatPhoneNumber(phone));
        }
    };
    useEffect(function () {
        if (phoneNumber.length === PHONE_NUMBER_MAX_LENGTH && !appScreenState.requireFullSSN) {
            dispatch(setFullNumberEntered(true));
        }
        else if (!appScreenState.requireFullSSN) {
            dispatch(setFullNumberEntered(false));
            setDisabledInputField(true);
        }
        var validationResponse = validatePhoneNumber(phoneNumber);
        if (phoneNumber.length < PHONE_NUMBER_MAX_LENGTH &&
            hideWhileTyping.includes(validationResponse)) {
            dispatch(setPhoneNumberError(ValidationResponse.empty));
        }
        else {
            dispatch(setPhoneNumberError(validationResponse));
        }
    }, [phoneNumber, appScreenState.requireFullSSN]);
    useEffect(function () {
        var validationResponse = validateName(firstName, true);
        if (!hideWhileTyping.includes(validationResponse)) {
            dispatch(setFnError(validationResponse));
        }
        else {
            dispatch(setFnError(ValidationResponse.empty));
        }
    }, [firstName]);
    useEffect(function () {
        var validationResponse = validateName(lastName, false);
        if (!hideWhileTyping.includes(validationResponse)) {
            dispatch(setLnError(validationResponse));
        }
        else {
            dispatch(setLnError(ValidationResponse.empty));
        }
    }, [lastName]);
    useEffect(function () {
        var validationResponse = validateEmail(email);
        if (!hideWhileTyping.includes(validationResponse)) {
            dispatch(setEmailError(validationResponse));
        }
        else {
            dispatch(setEmailError(ValidationResponse.empty));
        }
    }, [email]);
    useEffect(function () {
        var validationResponse = validateAddress(street);
        if (validationResponse === ValidationResponse.invalidAddressPOBox) {
            ANALYTICS.logEvent(EVENT.PERSONAL_INFO_ADDRESS_INVALID, {
                product_uuid: merchantData.productUUID,
                field: 'street_address',
                text_input: street,
            });
        }
        if (!hideWhileTyping.includes(validationResponse)) {
            dispatch(setAddress1Error(validationResponse));
        }
        else {
            dispatch(setAddress1Error(ValidationResponse.empty));
        }
    }, [street]);
    useEffect(function () {
        var validationResponse = validateAddress(apartment, true);
        if (validationResponse === ValidationResponse.invalidAddressPOBox) {
            ANALYTICS.logEvent(EVENT.PERSONAL_INFO_ADDRESS_INVALID, {
                product_uuid: merchantData.productUUID,
                field: 'apartment',
                text_input: apartment,
            });
        }
        if (!hideWhileTyping.includes(validationResponse)) {
            dispatch(setAddress2Error(validationResponse));
        }
    }, [apartment]);
    useEffect(function () {
        var validationResponse = validateCity(city);
        if (!hideWhileTyping.includes(validationResponse)) {
            dispatch(setCityError(validationResponse));
        }
        else {
            dispatch(setCityError(ValidationResponse.empty));
        }
    }, [city]);
    useEffect(function () {
        var validationResponse = validateZip(zip);
        if (!hideWhileTyping.includes(validationResponse)) {
            dispatch(setZipError(validationResponse));
        }
        else {
            dispatch(setZipError(ValidationResponse.empty));
        }
    }, [zip]);
    useEffect(function () {
        var validationResponse = validateState(state);
        if (!hideWhileTyping.includes(validationResponse)) {
            dispatch(setStateError(validationResponse));
        }
        else {
            dispatch(setStateError(ValidationResponse.empty));
        }
    }, [state]);
    useEffect(function () {
        var validationResponse = validateBirthDate(birthDay);
        if (!hideWhileTyping.includes(validationResponse)) {
            dispatch(setFullDobError(validationResponse));
        }
        else {
            dispatch(setFullDobError(ValidationResponse.empty));
        }
    }, [birthDayDay, birthDayMonth, birthDayYear]);
    useEffect(function () {
        if (saveData && !disabledBtn) {
            handleContinueClick();
        }
    }, [saveData]);
    useEffect(function () {
        if (appScreenState.fullNumberEntered === true &&
            !appScreenState.requireFullSSN &&
            screenState.screen !== SCREEN.INCOME_HOUSING_MODAL &&
            !loginState.showSignUp &&
            validator.phoneNumberError === ValidationResponse.valid) {
            loginHandler();
        }
    }, [
        appScreenState.fullNumberEntered,
        appScreenState.requireFullSSN,
        loginState.showSignUp,
        validator.phoneNumberError,
    ]);
    var checkoutMutation = useCheckoutAndLogin();
    useEffect(function () {
        if (appScreenState.showWelcomeBackCard && loginState.eligiblePhoneNumber !== '')
            setPhoneNumber(formatPhoneNumber(loginState.eligiblePhoneNumber));
    }, [loginState.eligiblePhoneNumber]);
    var loginHandler = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch(setEligiblePhoneNumber(phoneNumber
                .split('')
                .filter(function (val) { return !isNaN(+val) && val != ' '; })
                .join('')));
            checkoutMutation.mutate({ phone: phoneNumber });
            return [2 /*return*/];
        });
    }); };
    var handleContinueClick = function () {
        var _a;
        ANALYTICS.logEvent(EVENT.PERSONAL_INFO_CONTINUE_CLICKED);
        var date = new Date(birthDay);
        dispatch(setConsumerProfile({
            name: {
                firstName: firstName,
                lastName: lastName,
            },
            email: email,
            dob: {
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDate(),
            },
            phone: phoneNumber
                .split('')
                .filter(function (val) { return !isNaN(+val) && val != ' '; })
                .join(''),
            address: {
                streetLine1: street,
                streetLine2: apartment,
                city: city,
                zipCode: zip,
                state: state,
                country: 'US',
            },
        }));
        dispatch(setSection(SECTION.INCOME));
        var incomeHeader = (_a = document.getElementById('personalInfoHeader')) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        var bottomIncomeHeader = incomeHeader.bottom;
        // -32 to account for padding + margins
        document.body.scrollTop = bottomIncomeHeader + window.scrollY - 32; // For Safari
        document.documentElement.scrollTop = bottomIncomeHeader + window.scrollY - 32; // For Chrome, Firefox, IE and Opera
    };
    useEffect(function () {
        setDisabledInputField(appScreenState.requireFullSSN ||
            !appScreenState.fullNumberEntered ||
            !loginState.loginFlow.loginError);
        if (loginState.showSignUp && !appScreenState.requireFullSSN) {
            setDisabledInputField(false);
        }
    }, [
        appScreenState.fullNumberEntered,
        loginState.loginFlow.loginError,
        appScreenState.requireFullSSN,
        loginState.showSignUp,
    ]);
    useEffect(function () {
        var _a, _b, _c;
        // @ts-ignore-next-line
        var autocomplete = (_c = (_b = (_a = window.google) === null || _a === void 0 ? void 0 : _a.maps) === null || _b === void 0 ? void 0 : _b.places) === null || _c === void 0 ? void 0 : _c.Autocomplete;
        var field = document.getElementById('searchAddress');
        if (!autocomplete || !field) {
            return;
        }
        autocompleteAddress(field, autocomplete, function (address) {
            if (address) {
                var streetLine1 = address.streetLine1, streetLine2 = address.streetLine2, city_1 = address.city, state_1 = address.state, zipCode = address.zipCode;
                setStreet(streetLine1);
                setApartment(streetLine2);
                setCity(city_1);
                setState(state_1);
                setZip(zipCode);
            }
        });
        // @ts-ignore-next-line
    }, [window.google, document]);
    var birthdayView = (_jsxs(_Fragment, { children: [_jsx(ImpSpacer, { height: "m" }), _jsx(ImpText, __assign({ color: colors.content.onSurfaceDisabled, typography: "body3" }, { children: language.birthDay })), _jsx(ImpSpacer, { height: "xs" }), _jsxs(ImpFlex, __assign({ direction: "row", gap: "m" }, { children: [_jsx(ImpFlexItem, __assign({ flex: "1" }, { children: _jsx(ImpSelect, { options: language.months.map(function (val, index) {
                                return { value: String(index + 1), label: val };
                            }), defaultOption: {
                                value: birthDayMonth,
                                label: language.months[parseInt(birthDayMonth) - 1],
                            }, label: language.month, showError: !noError.includes(validator.birthDateError), isDisabled: disabledInputField || disabledBirthDayMonth, onOptionChange: function (value) {
                                setBirthDayMonth(value);
                                if (birthDayChecked) {
                                    dispatch(setFullDobError(validateBirthDate(formatDob("".concat(formatDateValue(Number(value)), "/\n                        ").concat(formatDateValue(Number(birthDayDay)), "/\n                        ").concat(formatDateValue(Number(birthDayYear)))))));
                                }
                            }, instrumentation: function () {
                                return ANALYTICS.logEvent(EVENT.PERSONAL_INFO_FORM_FILLED_OUT, {
                                    field: 'birthday_month',
                                });
                            } }) })), _jsx(ImpFlexItem, __assign({ flex: "1" }, { children: _jsx(ImpInput, { label: language.day, value: birthDayDay, showError: !noError.includes(validator.birthDateError), errorMessage: '', isDisabled: disabledInputField || disabledBirthDayDay, maxLength: 2, onChange: function (e) { return setBirthDayDay(e.target.value); }, instrumentation: function () {
                                return ANALYTICS.logEvent(EVENT.PERSONAL_INFO_FORM_FILLED_OUT, {
                                    field: 'birthday_day',
                                });
                            }, onBlur: function () {
                                if (birthDayChecked) {
                                    dispatch(setFullDobError(validateBirthDate(birthDay)));
                                }
                            }, numeric: true }) })), _jsx(ImpFlexItem, __assign({ flex: "1" }, { children: _jsx(ImpInput, { label: language.year, value: birthDayYear, showError: !noError.includes(validator.birthDateError), errorMessage: '', isDisabled: disabledInputField || disabledBirthDayYear, maxLength: 4, onChange: function (e) { return setBirthDayYear(e.target.value); }, instrumentation: function () {
                                return ANALYTICS.logEvent(EVENT.PERSONAL_INFO_FORM_FILLED_OUT, {
                                    field: 'birthday_year',
                                });
                            }, onBlur: function () {
                                setBirthDayChecked(true);
                                dispatch(setFullDobError(validateBirthDate(birthDay)));
                            }, numeric: true }) }))] })), !noError.includes(validator.birthDateError) && (_jsxs(_Fragment, { children: [_jsx(ImpSpacer, { height: "xs" }), _jsx(ImpText, __assign({ typography: "body3", color: colors.content.onBackgroundError }, { children: getValidationString(validator.birthDateError, language) }))] }))] }));
    var AddressView = (_jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", gap: "m", align: "normal", fluid: true }, { children: [_jsx(ImpFlexItem, __assign({ flexGrow: 1 }, { children: _jsx(ImpInput, { id: "searchAddress", label: showMoreFields ? language.street : language.homeAddress, value: street, showError: !noError.includes(validator.address1Error), errorMessage: getValidationString(validator.address1Error, language), isDisabled: disabledInputField || disabledStreet, onChange: function (e) { return setStreet(e.target.value); }, instrumentation: function () {
                            return ANALYTICS.logEvent(EVENT.PERSONAL_INFO_FORM_FILLED_OUT, {
                                field: 'street_address',
                            });
                        }, onFocus: function () { return setShowMoreFields(true); }, onBlur: function () { return dispatch(setAddress1Error(validateAddress(street, false))); }, caption: language.personalInfoAddressInstruction, maxWidth: "100%" }) })), showMoreFields && (_jsxs(_Fragment, { children: [_jsx(ImpInput, { id: "apartment", label: language.apartment, value: apartment, showError: !noError.includes(validator.address2Error), errorMessage: getValidationString(validator.address2Error, language), isDisabled: disabledInputField || disabledApartment, onChange: function (e) { return setApartment(e.target.value); }, instrumentation: function () {
                                return ANALYTICS.logEvent(EVENT.PERSONAL_INFO_FORM_FILLED_OUT, {
                                    field: 'apartment',
                                });
                            }, onBlur: function () { return dispatch(setAddress2Error(validateAddress(apartment, true))); }, maxWidth: "100%" }), _jsx(ImpInput, { id: "city", label: language.city, value: city, showError: !noError.includes(validator.cityError), errorMessage: getValidationString(validator.cityError, language), isDisabled: disabledInputField || disabledCity, onChange: function (e) { return setCity(e.target.value); }, instrumentation: function () {
                                return ANALYTICS.logEvent(EVENT.PERSONAL_INFO_FORM_FILLED_OUT, {
                                    field: 'city',
                                });
                            }, onBlur: function () { return dispatch(setCityError(validateCity(city))); }, maxWidth: "100%" }), _jsxs(ImpFlex, __assign({ direction: "row", gap: "m" }, { children: [_jsx(ImpFlexItem, __assign({ flexGrow: 1 }, { children: _jsx(ImpInput, { maxLength: 5, id: "zip", label: language.zip, value: zip, showError: !noError.includes(validator.zipError), errorMessage: getValidationString(validator.zipError, language), isDisabled: disabledInputField || disabledZip, onChange: function (e) { return setZip(e.target.value); }, instrumentation: function () {
                                            return ANALYTICS.logEvent(EVENT.PERSONAL_INFO_FORM_FILLED_OUT, {
                                                field: 'zip_code',
                                            });
                                        }, onBlur: function () { return dispatch(setZipError(validateZip(zip))); }, numeric: true }) })), _jsx(ImpFlexItem, __assign({ flexGrow: 1 }, { children: _jsx(ImpSelect, { id: "option", maxLength: 2, defaultOption: { value: state, label: state }, options: language.stateOption.map(function (val) {
                                            return { value: val, label: val };
                                        }), label: language.state, showError: !noError.includes(validator.stateError), errorMessage: getValidationString(validator.stateError, language), isDisabled: disabledInputField || disabledState, onOptionChange: function (value) {
                                            setState(formatState(value.toUpperCase()));
                                        }, instrumentation: function () {
                                            ANALYTICS.logEvent(EVENT.PERSONAL_INFO_FORM_FILLED_OUT, {
                                                field: 'state',
                                            });
                                        } }) }))] }))] }))] })) })));
    var emailOnBlur = function () {
        var validationResponse = validateEmail(email);
        dispatch(setEmailError(validationResponse));
        switch (validationResponse) {
            case ValidationResponse.emailPotentialTypo: {
                ANALYTICS.logEvent(EVENT.PERSONAL_INFO_EMAIL_WARNING, {
                    product_uuid: merchantData.productUUID,
                    text_input: email,
                });
                return;
            }
            case ValidationResponse.invalidEmail: {
                ANALYTICS.logEvent(EVENT.PERSONAL_INFO_EMAIL_INVALID, {
                    product_uuid: merchantData.productUUID,
                    text_input: email,
                });
                return;
            }
        }
    };
    var preapprovalCode = getPreapprovalCodeFromSession();
    var storeNum = getStoreNumber(merchantData.productUUID);
    var employeeId = getEmployeeId(merchantData.productUUID);
    return (_jsxs(ImpContainer, { children: [_jsx(ImpSpacer, { mobileHeight: "xs" }), _jsx(LanguageSelection, {}), preapprovalCode && _jsx(PrescreenLabel, { code: preapprovalCode }), (storeNum || employeeId) && _jsx(InStoreLabels, { storeNum: storeNum, employeeId: employeeId }), _jsx(ImpSpacer, { height: "m" }), _jsx(ImpInput, { label: language.phoneNumber, value: phoneNumber, showError: !noError.includes(validator.phoneNumberError), showWarning: validator.phoneNumberError === ValidationResponse.emailPotentialTypo, errorMessage: getValidationString(validator.phoneNumberError, language), maxLength: PHONE_NUMBER_MAX_LENGTH, onChange: function (e) { return setPhoneNumber(formatPhoneNumber(e.target.value)); }, instrumentation: function () {
                    return ANALYTICS.logEvent(EVENT.MOBILE_PHONE_NUMBER_FILLED_OUT, {
                        application_product_uuid: merchantData.productUUID,
                    });
                }, isDisabled: appScreenState.requireFullSSN ||
                    (appScreenState.showWelcomeBackCard && phoneNumber !== '') ||
                    appStatus.reApplicationInApp, onBlur: function () {
                    dispatch(setPhoneNumberError(validatePhoneNumber(phoneNumber)));
                }, caption: language.personalInfoNumberInstruction, numeric: true, maxWidth: "100%" }), _jsx(ImpSpacer, { height: "m" }), _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsxs(ImpFlex, __assign({ direction: "row", gap: "m" }, { children: [_jsx(ImpFlexItem, __assign({ flex: "1" }, { children: _jsx(ImpInput, { label: language.firstName, value: firstName, showError: !noError.includes(validator.fnError), errorMessage: getValidationString(validator.fnError, language), isDisabled: disabledInputField || disabledFirstName, onChange: function (e) { return setFirstName(e.target.value); }, instrumentation: function () {
                                    return ANALYTICS.logEvent(EVENT.PERSONAL_INFO_FORM_FILLED_OUT, {
                                        field: 'first_name',
                                    });
                                }, onBlur: function () { return dispatch(setFnError(validateName(firstName, true))); } }) })), _jsx(ImpFlexItem, __assign({ flex: "1" }, { children: _jsx(ImpInput, { label: language.lastName, value: lastName, showError: !noError.includes(validator.lnError), errorMessage: getValidationString(validator.lnError, language), isDisabled: disabledInputField || disabledLastName, onChange: function (e) { return setLastName(e.target.value); }, instrumentation: function () {
                                    return ANALYTICS.logEvent(EVENT.PERSONAL_INFO_FORM_FILLED_OUT, {
                                        field: 'last_name',
                                    });
                                }, onBlur: function () { return dispatch(setLnError(validateName(lastName, false))); } }) }))] })) })), _jsx(ImpSpacer, { height: "m" }), _jsx(ImpInput, { label: language.email, value: email, showError: !noError.includes(validator.emailError), showWarning: inputWarning.includes(validator.emailError), errorMessage: getValidationString(validator.emailError, language), isDisabled: disabledInputField || disabledEmail, onChange: function (e) { return setEmail(e.target.value); }, instrumentation: function () {
                    return ANALYTICS.logEvent(EVENT.PERSONAL_INFO_FORM_FILLED_OUT, {
                        field: 'email',
                    });
                }, onBlur: function () { return emailOnBlur(); }, maxWidth: "100%" }), !isMultiCardApplicationFlow && birthdayView, _jsx(ImpSpacer, { height: "m" }), AddressView, _jsx(ImpSpacer, { height: "xl" }), _jsx(ImpButton, __assign({ variant: "primary", buttonStyle: "branded", size: "fill", state: getPersonalInfoButtonState(), onClick: handleContinueClick }, { children: language.continueBtn })), _jsxs(ImpHidden, __assign({ hideAbove: BreakpointsPx.Mobile }, { children: [_jsx(ImpSpacer, { height: "l" }), _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpFlex, __assign({ fluid: true, justify: "center" }, { children: _jsx(ImpInlineLink, __assign({ url: getAccountLink(window.location.hostname.toLowerCase()), color: "var(--merchant-color)", onClick: function () {
                                    return ANALYTICS.logEvent(EVENT.APPLICATION_ALREADY_APPLIED_SECONDARY_CLICKED);
                                } }, { children: _jsx(ImpText, __assign({ typography: "body2", color: "var(--merchant-color)" }, { children: language.checkApplicationStatusHeader })) })) })) }))] })), _jsx(ImpSpacer, { height: "xl" })] }));
}
