var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { colors, ImpButton, ImpDivider, ImpFlex, ImpPad, ImpSpacer, ImpSurface, ImpText, spacing, } from '@imprint-payments/imprint-ui';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY_PREFILL } from './PrefillFlow';
export default function InfoFields() {
    var queryClient = useQueryClient();
    var data = queryClient.getQueryData([QUERY_KEY_PREFILL]);
    if (!data) {
        // This should never happen, safeguarded in the previous step
        return null;
    }
    return (_jsx(ImpFlex, __assign({ fluid: true, direction: 'column' }, { children: _jsxs(ImpPad, __assign({ padding: spacing.xl }, { children: [_jsxs(ImpFlex, __assign({ fluid: true, direction: "column", align: 'center' }, { children: [_jsx(ImpText, __assign({ typography: 'headline3' }, { children: "Personal info" })), _jsx(ImpSpacer, { height: spacing.xs }), _jsx(ImpText, __assign({ typography: 'body2', color: colors.content.onSurfaceVariant }, { children: "Welcome, ".concat(data.firstName, "! We found the information below. Please check that everything is correct before continuing.") }))] })), _jsx(ImpSpacer, { height: spacing.xl }), _jsx(ImpSurface, __assign({ fluid: true, surfaceColor: colors.surface.surfaceMid, hasBorderRadius: true }, { children: _jsx(ImpPad, __assign({ paddingLeft: "l", paddingRight: "l" }, { children: _jsxs(ImpFlex, __assign({ fluid: true, direction: 'column' }, { children: [_jsx(InfoField, { label: 'Legal name', content: "".concat(data.firstName, " ").concat(data.lastName) }), _jsx(ImpDivider, {}), _jsx(InfoField, { label: 'Personal email address', content: "".concat(data.email) }), _jsx(ImpDivider, {}), _jsx(InfoField, { label: 'Birthday', content: formatDob(data.dob) }), _jsx(ImpDivider, {}), _jsx(InfoField, { label: 'Full SSN', content: formatSSN(data.ssn9) }), _jsx(ImpDivider, {}), _jsx(InfoField, { label: 'Home address', content: _jsx(Address, { addr: data.address }) })] })) })) })), _jsx(ImpPad, __assign({ padding: spacing.l }, { children: _jsxs(ImpFlex, __assign({ fluid: true, direction: 'column', gap: spacing.xs }, { children: [_jsx(ImpButton, __assign({ variant: 'secondary', buttonStyle: 'branded' }, { children: "Edit Info" })), _jsx(ImpButton, __assign({ variant: 'primary', buttonStyle: 'branded' }, { children: "Continue" }))] })) }))] })) })));
}
function InfoField(props) {
    return (_jsx(_Fragment, { children: _jsx(ImpPad, __assign({ paddingTop: "l", paddingBottom: "l" }, { children: _jsxs(ImpFlex, __assign({ fluid: true, direction: 'column' }, { children: [_jsx(ImpText, __assign({ typography: 'body3', color: colors.content.onSurfaceVariant }, { children: props.label })), _jsx(ImpText, __assign({ typography: 'body1', color: colors.content.onSurface }, { children: props.content }))] })) })) }));
}
/**
 * Format a date of birth to a string of the form "MM/DD/YYYY"
 * @param dob the date of birth, as a GetPrefillDataResponse['dob']
 * @returns a string of the form "MM/DD/YYYY"
 */
function formatDob(dob) {
    var formatter = new Intl.NumberFormat('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
    });
    return "".concat(formatter.format(dob.month), "/").concat(formatter.format(dob.day), "/").concat(dob.year);
}
/**
 * Format a social security number as a string of the form "••• ••• ####"
 * @param ssn9 the social security number to format
 * @returns a string of the form "••• ••• ####"
 */
function formatSSN(ssn9) {
    return "\u2022\u2022\u2022 \u2022\u2022\u2022 ".concat(ssn9.slice(-4));
}
/**
 * Component to render a full address from a {@link GetPrefillDataResponse.address}
 * @param address the address to render
 * @returns a JSX element containing the formatted address
 */
function Address(_a) {
    var addr = _a.addr;
    return (_jsxs(_Fragment, { children: [addr.streetLine1, " ", addr.streetLine2, " ", _jsx("br", {}), addr.city, ", ", addr.state, " ", addr.zipCode] }));
}
