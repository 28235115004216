var _a;
import { createSlice } from '@reduxjs/toolkit';
import { ValidationResponse } from '../utils/validateUtils';
var initialState = {
    fnError: ValidationResponse.empty,
    lnError: ValidationResponse.empty,
    emailError: ValidationResponse.empty,
    hhEmailError: ValidationResponse.empty,
    subscribeEmailError: ValidationResponse.empty,
    birthDateError: ValidationResponse.empty,
    phoneNumberError: ValidationResponse.empty,
    lastFourError: ValidationResponse.empty,
    fullSSNError: ValidationResponse.invalidSSN,
    address1Error: ValidationResponse.empty,
    address2Error: ValidationResponse.empty,
    cityError: ValidationResponse.empty,
    zipError: ValidationResponse.empty,
    stateError: ValidationResponse.empty,
};
export var validatorSlice = createSlice({
    name: 'validator',
    initialState: initialState,
    reducers: {
        setFnError: function (state, action) {
            state.fnError = action.payload;
        },
        setLnError: function (state, action) {
            state.lnError = action.payload;
        },
        setSubscribeEmailError: function (state, action) {
            state.subscribeEmailError = action.payload;
        },
        setEmailError: function (state, action) {
            state.emailError = action.payload;
        },
        setFullDobError: function (state, action) {
            state.birthDateError = action.payload;
        },
        setPhoneNumberError: function (state, action) {
            state.phoneNumberError = action.payload;
        },
        setSsnLast4Error: function (state, action) {
            state.lastFourError = action.payload;
        },
        setFullSsnError: function (state, action) {
            state.fullSSNError = action.payload;
        },
        setAddress1Error: function (state, action) {
            state.address1Error = action.payload;
        },
        setAddress2Error: function (state, action) {
            state.address2Error = action.payload;
        },
        setCityError: function (state, action) {
            state.cityError = action.payload;
        },
        setZipError: function (state, action) {
            state.zipError = action.payload;
        },
        setStateError: function (state, action) {
            state.stateError = action.payload;
        },
        resetInputValidation: function () {
            initialState;
        },
        resetPopupValidation: function (state) {
            state.subscribeEmailError = initialState.subscribeEmailError;
        },
    },
});
export var setFnError = (_a = validatorSlice.actions, _a.setFnError), setLnError = _a.setLnError, setSubscribeEmailError = _a.setSubscribeEmailError, setEmailError = _a.setEmailError, setFullDobError = _a.setFullDobError, setPhoneNumberError = _a.setPhoneNumberError, setSsnLast4Error = _a.setSsnLast4Error, setFullSsnError = _a.setFullSsnError, setAddress1Error = _a.setAddress1Error, setAddress2Error = _a.setAddress2Error, setCityError = _a.setCityError, setZipError = _a.setZipError, setStateError = _a.setStateError, resetInputValidation = _a.resetInputValidation, resetPopupValidation = _a.resetPopupValidation;
export default validatorSlice.reducer;
