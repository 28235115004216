var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    status: '',
    showResult: false,
    expiresAt: '',
    productAccountUUID: '',
    showCounterOffer: false,
    rejectionReasonsURL: '',
    frozenExpiresAt: '',
    applicationUUID: '',
    reApplicationInApp: false,
    evaluationRejectReapply: false,
};
var applicationStatusSlice = createSlice({
    name: 'applicationStatus',
    initialState: initialState,
    reducers: {
        setAppStatus: function (state, action) {
            state.status = action.payload;
        },
        setShowResult: function (state, action) {
            state.showResult = action.payload === null ? true : action.payload;
        },
        setExpiresAt: function (state, action) {
            state.expiresAt = action.payload;
        },
        setProductAccountUUID: function (state, action) {
            state.productAccountUUID = action.payload;
        },
        setShowCounterOffer: function (state, action) {
            state.showCounterOffer = action.payload;
        },
        setRejectionReasonsURL: function (state, action) {
            state.rejectionReasonsURL = action.payload;
        },
        setFrozenExpiresAt: function (state, action) {
            state.frozenExpiresAt = action.payload;
        },
        setApplicationUUID: function (state, action) {
            state.applicationUUID = action.payload;
        },
        setReApplicationInApp: function (state, action) {
            state.reApplicationInApp = action.payload;
        },
        setEvaluationRejectReapply: function (state, action) {
            state.evaluationRejectReapply = action.payload;
        },
    },
});
export var setAppStatus = (_a = applicationStatusSlice.actions, _a.setAppStatus), setShowResult = _a.setShowResult, setExpiresAt = _a.setExpiresAt, setProductAccountUUID = _a.setProductAccountUUID, setShowCounterOffer = _a.setShowCounterOffer, setRejectionReasonsURL = _a.setRejectionReasonsURL, setFrozenExpiresAt = _a.setFrozenExpiresAt, setApplicationUUID = _a.setApplicationUUID, setReApplicationInApp = _a.setReApplicationInApp, setEvaluationRejectReapply = _a.setEvaluationRejectReapply;
export default applicationStatusSlice.reducer;
