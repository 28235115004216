import { jsx as _jsx } from "react/jsx-runtime";
import OtpCardNew from '../otp/OtpCardNew';
import { useImpState } from '../../app/hooks';
import { formatPhoneNumber } from '@imprint-payments/imprint-lib';
import { useVerifyOtp } from '../../api/verifyOtp.api';
import { useResendOtp } from '../../api/login/useLogin';
import { setIncorrectOtpCode, wrongOtpCode } from '../../app/otpScreenSlice';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getPrefillData, } from '../../api/getPrefillData/getPrefillData.api';
import { QUERY_KEY_PREFILL } from './PrefillFlow';
export default function EarlyOtp(_a) {
    var nextStep = _a.nextStep;
    var _b = useImpState(), consumerInfo = _b.consumerInfo, dispatch = _b.dispatch;
    var phone = consumerInfo.consumerProfile.phone;
    var verifyOtpMutation = useVerifyOtp();
    var resendOtpMutation = useResendOtp();
    var appScreenState = useImpState().appScreenState;
    var queryClient = useQueryClient();
    var prefillDataMutation = useMutation({
        mutationFn: getPrefillData,
        retry: false,
        onSuccess: function (data) {
            queryClient.setQueryData([QUERY_KEY_PREFILL], data);
            nextStep();
        },
        onError: function (error) {
            nextStep();
        },
    });
    return (_jsx(OtpCardNew, { title: "Enter the 6-digit code sent to ".concat(formatPhoneNumber(phone)), onFull: function (code) {
            verifyOtpMutation.mutate({ code: code }, {
                onSuccess: function () {
                    dispatch(wrongOtpCode(false));
                    dispatch(setIncorrectOtpCode(false));
                    prefillDataMutation.mutate({
                        ssn4: consumerInfo.consumerProfile.ssn4 || '',
                        sessionId: appScreenState.proveSessionId,
                    });
                },
            });
        }, handleResend: function () {
            resendOtpMutation.mutate({ phone: phone });
        } }));
}
