var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import styles from './ButtonServicing.module.css';
import { KEYTYPE } from '../../utils/keyConstants';
export var ButtonColorScheme;
(function (ButtonColorScheme) {
    ButtonColorScheme["fillBranded"] = "fillBranded";
    ButtonColorScheme["fillImprint"] = "fillImprint";
    ButtonColorScheme["ghostBranded"] = "ghostBranded";
    ButtonColorScheme["ghostImprint"] = "ghostImprint";
    ButtonColorScheme["emptyBranded"] = "emptyBranded";
    ButtonColorScheme["emptyImprint"] = "emptyImprint";
})(ButtonColorScheme || (ButtonColorScheme = {}));
export var ButtonSize;
(function (ButtonSize) {
    ButtonSize["small"] = "small";
    ButtonSize["default"] = "default";
    ButtonSize["xsmall"] = "xsmall";
})(ButtonSize || (ButtonSize = {}));
export default function ButtonServicing(_a) {
    var value = _a.value, pill = _a.pill, empty = _a.empty, borderless = _a.borderless, icon = _a.icon, disabled = _a.disabled, hideOnMobile = _a.hideOnMobile, loading = _a.loading, handleClick = _a.handleClick, colorScheme = _a.colorScheme, size = _a.size, overrideColor = _a.overrideColor;
    useEffect(function () { }, [icon, disabled]);
    var getClasses = function () {
        var classList = [''];
        if (disabled) {
            classList.push(styles.disabled);
        }
        if (pill) {
            classList.push(styles.pill);
        }
        if (empty) {
            classList.push(styles.empty);
        }
        if (borderless) {
            classList.push(styles.borderless);
        }
        if (hideOnMobile) {
            classList.push(styles.hideOnMobile);
        }
        // prettier-ignore
        if (colorScheme) {
            switch (colorScheme) {
                case ButtonColorScheme.fillBranded:
                    classList.push(styles.fillBranded);
                    break;
                case ButtonColorScheme.fillImprint:
                    classList.push(styles.fillImprint);
                    break;
                case ButtonColorScheme.ghostBranded:
                    classList.push(styles.ghostBranded);
                    break;
                case ButtonColorScheme.ghostImprint:
                    classList.push(styles.ghostImprint);
                    break;
                case ButtonColorScheme.emptyBranded:
                    classList.push(styles.emptyBranded);
                    break;
                case ButtonColorScheme.emptyImprint:
                default:
                    classList.push(styles.emptyImprint);
                    break;
            }
        }
        if (size) {
            switch (size) {
                case ButtonSize.small:
                    classList.push(styles.small);
                    break;
                case ButtonSize.xsmall:
                    classList.push(styles.xsmall);
                    break;
                case ButtonSize.default:
                default:
                    break;
            }
        }
        return classList.join(' ');
    };
    return (_jsxs("div", { children: [_jsx("button", __assign({ className: getClasses(), onClick: !disabled && !loading ? handleClick : undefined, style: {
                    color: overrideColor ? overrideColor : undefined,
                }, tabIndex: 0, onKeyDown: function (e) {
                    if (e.key.toUpperCase() === KEYTYPE.ENTER && !disabled && !loading) {
                        handleClick();
                    }
                } }, { children: !loading ? value : '' })), getClasses().includes(styles.pill) && !disabled && loading && (_jsxs("div", __assign({ className: styles.spinner }, { children: [_jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {})] })))] }));
}
