var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpButton, ImpContainer, ImpFlex, ImpOtpField, ImpPad, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { MODAL_ENUM } from '../../app/applicationScreenSlice';
import { useAppSelector, useImpState } from '../../app/hooks';
import { setIncorrectOtpCode } from '../../app/otpScreenSlice';
import imprintLogo from '../../assets/icons/logo-imprint.svg';
import { ANALYTICS } from '../../firebase/firebase';
import { getContactSupportLink } from '../../utils/functionUtils';
export default function OtpCardNew(_a) {
    var title = _a.title, showLogoHeader = _a.showLogoHeader, onFull = _a.onFull, handleGoBack = _a.handleGoBack, handleResend = _a.handleResend, bottomSubline = _a.bottomSubline;
    var _b = useImpState(), merchantData = _b.merchantData, language = _b.language, dispatch = _b.dispatch;
    var showLoginModal = useAppSelector(function (state) {
        return state.applicationScreenSelector.showModal === MODAL_ENUM.showLoginModal;
    });
    var _c = useState(''), input = _c[0], setInput = _c[1];
    var otpScreenState = useAppSelector(function (state) { return state.otpScreenSelector; });
    var _d = useState(false), clearWithError = _d[0], setClearWithError = _d[1];
    var _e = useState(false), clear = _e[0], setClear = _e[1];
    useEffect(function () {
        if (input !== '') {
            setClearWithError(false);
            setClear(false);
            dispatch(setIncorrectOtpCode(false));
        }
    }, [input]);
    useEffect(function () {
        if (otpScreenState.isIncorrectOtpCode) {
            ANALYTICS.logEvent(EVENT.OTP_TRY_AGAIN_VIEWED);
            setClearWithError(true);
        }
    }, [otpScreenState.isIncorrectOtpCode]);
    var handleFull = function (otp) {
        if (otpScreenState.isIncorrectOtpCode) {
            ANALYTICS.logEvent(EVENT.OTP_TRY_AGAIN_SUBMITTED);
        }
        else {
            ANALYTICS.logEvent(EVENT.OTP_SUBMITTED);
        }
        onFull(otp);
    };
    var handleResendFn = function () {
        ANALYTICS.logEvent(EVENT.OTP_TRY_AGAIN_RESEND_CODE_CLICKED);
        setClear(true);
        dispatch(setIncorrectOtpCode(false));
        setClearWithError(false);
        handleResend();
    };
    return (_jsxs(ImpContainer, __assign({ textAlign: "center" }, { children: [showLogoHeader && (_jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsx(ImpContainer, __assign({ width: "162px" }, { children: _jsx("img", { draggable: "false", alt: "imprint logo", src: imprintLogo, tabIndex: 0 }) })), _jsx(ImpSpacer, { height: 'xl' })] }))), _jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsx(ImpText, __assign({ typography: "headline2", mobileTypography: "headline3" }, { children: title })), _jsx(ImpSpacer, { height: 'xl' })] })), _jsx(ImpContainer, { children: _jsx(ImpOtpField, { numInputs: 6, onChange: setInput, onFull: handleFull, clearWithError: clearWithError, clear: clear, autoComplete: "one-time-code", autoFocus: true }) }), _jsx(ImpPad, __assign({ padding: "24px 0px 16px" }, { children: _jsx(ImpText, __assign({ typography: "body2", color: otpScreenState.isIncorrectOtpCode
                        ? colors.content.onBackgroundError
                        : colors.content.onBackgroundVariant }, { children: otpScreenState.isIncorrectOtpCode
                        ? language.otpError2
                        : bottomSubline })) })), _jsxs(ImpFlex, __assign({ direction: "column", align: "center", justify: "center" }, { children: [_jsxs(ImpContainer, __assign({ width: 'min(356px, 100%)' }, { children: [_jsx(ImpButton, __assign({ variant: "secondary", size: "fill", onClick: handleResendFn }, { children: language.sendCodeAgain })), _jsx(ImpSpacer, { height: "xl" })] })), _jsx(ImpButton, __assign({ variant: "text", buttonStyle: "branded", size: "hug", onClick: function () {
                            var _a;
                            (_a = window
                                .open(getContactSupportLink(merchantData.productUUID, language.selected), '_blank')) === null || _a === void 0 ? void 0 : _a.focus();
                            if (showLoginModal) {
                                ANALYTICS.logEvent(EVENT.RECOGNIZED_CONTACT_SUPPORT_CLICKED, {
                                    application_product_uuid: merchantData.productUUID,
                                });
                            }
                        } }, { children: language.contactSupportBtn }))] }))] })));
}
