var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpFlex, ImpPad, ImpSpacer, ImpText, colors } from '@imprint-payments/imprint-ui';
import { EVENT } from '../../analytics/analyticsConsts';
import { MODAL_ENUM, setShowModal } from '../../app/applicationScreenSlice';
import { useAppSelector, useImpState } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { setLanguage } from '../../app/languageSlice';
import { ANALYTICS } from '../../firebase/firebase';
import { setSessionLanguage } from '../../utils/functionUtils';
import { KEYTYPE } from '../../utils/keyConstants';
import { LANGUAGE_TO_TEXT, LanguageCode } from '../../utils/languageConstants';
import styles from './PersonalInfo.module.css';
function SelectedLanguageOption(_a) {
    var language = _a.language;
    var _b = useImpState(), languageCode = _b.languageCode, dispatch = _b.dispatch;
    var env = useAppSelector(function (state) { return state.apiScreenScreenSelector.env; });
    var isSelected = languageCode === language;
    var changeLanguage = function (language) {
        ANALYTICS.logEvent(EVENT.LANGUAGE_SELECTION_CLICKED, {
            page: 'languageSelectionPage',
            language: language,
        });
        if (language === LanguageCode.English) {
            setSessionLanguage(LanguageCode.English, env);
            dispatch(setLanguage(LanguageCode.English));
        }
        if (language === LanguageCode.Spanish && languageCode !== language) {
            dispatch(setShowModal(MODAL_ENUM.showLanguageDisclaimerModal));
        }
    };
    return (_jsx("div", __assign({ className: styles.languageSelectItem, onClick: function () { return changeLanguage(language); }, onKeyDown: function (e) {
            if (e.key.toUpperCase() === KEYTYPE.ENTER) {
                changeLanguage(language);
            }
        }, tabIndex: 0 }, { children: _jsxs(ImpFlex, __assign({ fluid: true, direction: "row", align: "center" }, { children: [_jsx(ImpText, __assign({ color: isSelected ? 'var(--merchant-color)' : colors.content.onBackgroundVariant, typography: "body3" }, { children: LANGUAGE_TO_TEXT[language] })), _jsx(ImpSpacer, { width: "s" }), _jsx("div", __assign({ className: styles.circle }, { children: isSelected ? _jsx("div", { className: styles.dot }) : '' }))] })) })));
}
export function LanguageSelection() {
    var language = useAppSelector(selectLanguage);
    return (_jsx(ImpPad, __assign({ padding: "16px 0px" }, { children: _jsx(ImpFlex, __assign({ direction: "column", fluid: true }, { children: _jsxs(ImpFlex, __assign({ direction: "row", justify: "space-between", align: "center", mobileGap: "s", fluid: true }, { children: [_jsx(ImpText, __assign({ typography: "body3", color: colors.content.onBackgroundVariant }, { children: language.preferredLanguage })), _jsxs(ImpFlex, __assign({ direction: "row", gap: "l", align: "center" }, { children: [_jsx(SelectedLanguageOption, { language: LanguageCode.English }), _jsx(SelectedLanguageOption, { language: LanguageCode.Spanish })] }))] })) })) })));
}
