import { useEffect, useState } from "react";
export var useExternalScript = function (url) {
    var _a = useState(url ? "loading" : "idle"), state = _a[0], setState = _a[1];
    useEffect(function () {
        if (!url) {
            setState("idle");
            return;
        }
        var script = document.querySelector("script[src=\"".concat(url, "\"]"));
        var handleScript = function (e) {
            setState(e.type === "load" ? "ready" : "error");
        };
        if (!script) {
            script = document.createElement("script");
            script.type = "application/javascript";
            script.src = url;
            script.async = true;
            document.body.appendChild(script);
            script.addEventListener("load", handleScript);
            script.addEventListener("error", handleScript);
        }
        script.addEventListener("load", handleScript);
        script.addEventListener("error", handleScript);
        return function () {
            script.removeEventListener("load", handleScript);
            script.removeEventListener("error", handleScript);
        };
    }, [url]);
    return state;
};
