var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ImpButton, ImpCardThumbnail, ImpConfirmAddressPage, ImpContainer, ImpDivider, ImpFlex, ImpSpacer, ImpText, colors } from '@imprint-payments/imprint-ui';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EVENT } from '../../analytics/analyticsConsts';
import { confirm, requestPhysicalCard } from '../../api/api';
import { setRedirectToLogin } from '../../app/acceptScreenSlice';
import { CARD_SELECTION_SCREEN, setCardDesignSelectionScreen, setSelectedCardDesignID, } from '../../app/cardDesignSlice';
import { useAppSelector, useImpState } from '../../app/hooks';
import ButtonServicing, { ButtonColorScheme, } from '../../components/buttonServicing/ButtonServicing';
import Spinner from '../../components/spinner/Spinner';
import { ANALYTICS } from '../../firebase/firebase';
import { convertAmountToString, redirectToAccountWithCookie, } from '../../utils/functionUtils';
import styles from './CardDesign.module.css';
function CardDesignConfirmation() {
    var _a = useImpState(), language = _a.language, merchantData = _a.merchantData, consumerInfo = _a.consumerInfo, appStatus = _a.appStatus, apiScreenState = _a.apiScreenState, dispatch = _a.dispatch;
    var cardDesignOptionsImages = merchantData.cardDesignOptionsImages;
    var selectionID = useAppSelector(function (state) { return state.cardDesignSelector.selectedCardDesignID; });
    var productUUID = useAppSelector(function (state) { return state.merchantDataSelector.productUUID; });
    var multiCardUser = useAppSelector(function (state) { return state.loginSelector.multiCardUser; });
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.CONFIRM_ADDRESS_VIEWED);
    }, []);
    var handleConfirmAddressClicked = function () {
        var isMultiCard = multiCardUser === true ? 'true' : 'false';
        ANALYTICS.logEvent(EVENT.CONFIRM_ADDRESS_CONFIRM_CLICKED);
        dispatch(requestPhysicalCard(consumerInfo.auth.token, appStatus.productAccountUUID)).then(function () {
            redirectToAccountWithCookie(consumerInfo.auth.token, apiScreenState.deviceID, consumerInfo.consumerUUID, productUUID, isMultiCard);
        });
    };
    function getAddress() {
        var address = consumerInfo.consumerProfile.address;
        return {
            line1: address.streetLine1,
            line2: address.streetLine2,
            line3: "".concat(address.city, ", ").concat(address.state, " ").concat(address.zipCode),
        };
    }
    if (appStatus.productAccountUUID === '') {
        return _jsx(Spinner, {});
    }
    return (_jsx(ImpConfirmAddressPage, { pageState: 'enabled', displayImage: cardDesignOptionsImages[selectionID].render, title: language.confirmHomeAddress, subtitle: language.confirmHomeAddressSubtitle, displayText: getAddress(), primaryActionButtonInfo: {
            text: language.confirmAddressBtn,
            onClick: handleConfirmAddressClicked,
        }, secondaryActionButtonInfo: {
            text: language.notNow,
            onClick: function () {
                ANALYTICS.logEvent(EVENT.CONFIRM_ADDRESS_NOT_NOW_CLICKED);
                dispatch(setCardDesignSelectionScreen(CARD_SELECTION_SCREEN.CONFIRM_ADDRESS_LATER));
            },
        } }));
}
function ConfirmAddressLater() {
    var _a = useImpState(), language = _a.language, consumerInfo = _a.consumerInfo, apiScreenState = _a.apiScreenState, dispatch = _a.dispatch;
    var productUUID = useAppSelector(function (state) { return state.merchantDataSelector.productUUID; });
    var multiCardUser = useAppSelector(function (state) { return state.loginSelector.multiCardUser; });
    var isMultiCard = multiCardUser === true ? 'true' : 'false';
    var handleSoundsGood = function () {
        ANALYTICS.logEvent(EVENT.CONFIRM_ADDRESS_LATER_SOUNDS_GOOD_CLICKED);
        dispatch(setRedirectToLogin(true));
        redirectToAccountWithCookie(consumerInfo.auth.token, apiScreenState.deviceID, consumerInfo.consumerUUID, productUUID, isMultiCard);
    };
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.CONFIRM_ADDRESS_LATER_VIEWED);
    }, []);
    return (_jsx(ImpContainer, __assign({ width: "min(100% - 32px, 1200px)" }, { children: _jsxs("div", __assign({ className: styles.confirmLaterBody }, { children: [_jsxs("h1", __assign({ className: styles.confirmLaterTitle }, { children: [language.youCanStillGet, _jsx("br", {}), language.aPhysicalCardLater] })), _jsx("p", __assign({ className: styles.confirmLaterText }, { children: language.requestInImprintApp })), _jsx("div", __assign({ className: styles.soundsGoodButtonContainer }, { children: _jsx(ButtonServicing, { value: language.soundsGood, colorScheme: ButtonColorScheme.fillBranded, handleClick: handleSoundsGood }) }))] })) })));
}
function MainCardDisplay(_a) {
    var cardInfo = _a.cardInfo, img = _a.img;
    if (!cardInfo) {
        return _jsx(_Fragment, {});
    }
    var language = useImpState().language;
    return (_jsx(ImpContainer, __assign({ textAlign: "center" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center", fluid: true }, { children: [_jsxs("div", __assign({ className: styles.mainCard }, { children: [_jsx("img", { className: styles.mainCard, src: img, tabIndex: 0, alt: "main card image" }), (cardInfo === null || cardInfo === void 0 ? void 0 : cardInfo.status) === CardDesignStatus.NOT_SELECTABLE && (_jsx("div", __assign({ className: styles.outOfStockOverlay }, { children: _jsx("div", __assign({ className: styles.outOfStockBadge }, { children: cardInfo.reason })) })))] })), _jsx(ImpSpacer, { height: "m" }), _jsx(ImpText, __assign({ typography: "label1" }, { children: cardInfo.title })), _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onBackgroundVariant }, { children: (cardInfo === null || cardInfo === void 0 ? void 0 : cardInfo.fee) ? convertAmountToString(cardInfo.fee) : language.free }))] })) })));
}
var CardDesignStatus;
(function (CardDesignStatus) {
    CardDesignStatus["SELECTABLE"] = "SELECTABLE";
    CardDesignStatus["NOT_SELECTABLE"] = "NOT_SELECTABLE";
    CardDesignStatus["HIDDEN"] = "HIDDEN";
})(CardDesignStatus || (CardDesignStatus = {}));
function SelectCard() {
    var _a, _b;
    var cardDesignOptions = useAppSelector(function (state) { return state.consumerInfoSelector.cardOffer.cardDesignOptions; });
    var _c = useImpState(), language = _c.language, merchantData = _c.merchantData, consumerInfo = _c.consumerInfo, dispatch = _c.dispatch;
    var cardDesignOptionsImages = merchantData.cardDesignOptionsImages;
    var cardDesignInfoMap = cardDesignOptions.reduce(function (acc, current) {
        acc[current.cardDesignID] = current;
        return acc;
    }, {});
    var _d = useState(''), currentSelection = _d[0], setCurrentSelection = _d[1];
    var selectionID = useAppSelector(function (state) { return state.cardDesignSelector.selectedCardDesignID; });
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.CARD_ART_SELECTION_PAGE_VIEWED);
        var firstSelectable = cardDesignOptions === null || cardDesignOptions === void 0 ? void 0 : cardDesignOptions.find(function (option) { return option.status === CardDesignStatus.SELECTABLE; });
        if (firstSelectable) {
            setCurrentSelection(firstSelectable.cardDesignID);
        }
    }, []);
    function handleContinue() {
        ANALYTICS.logEvent(EVENT.CARD_ART_SELECTION_CHANGED, {
            card_design_id: currentSelection,
        });
        dispatch(confirm({
            token: consumerInfo.auth.token,
            applicationUUID: consumerInfo.auth.applicationUUID,
            cardDesignID: currentSelection,
            sendSms: false, // in store applications not supported for card design flow
        }));
        dispatch(setSelectedCardDesignID(currentSelection));
        dispatch(setCardDesignSelectionScreen(CARD_SELECTION_SCREEN.CONFIRM_ADDRESS));
    }
    var primaryButtonState = ((_a = cardDesignInfoMap[currentSelection]) === null || _a === void 0 ? void 0 : _a.status) === CardDesignStatus.SELECTABLE
        ? 'enabled'
        : 'disabled';
    return (_jsx(ImpContainer, __assign({ width: "min(100% - 32px, 400px)" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsx("div", __assign({ className: styles.header, tabIndex: 0 }, { children: language.chooseYourDesign })), _jsx(MainCardDisplay, { cardInfo: cardDesignInfoMap[currentSelection], img: (_b = cardDesignOptionsImages[currentSelection]) === null || _b === void 0 ? void 0 : _b.physical }), _jsx(ImpSpacer, { height: "l" }), _jsx(ImpDivider, { color: colors.border.borderVariant }), _jsx("div", __assign({ className: styles.cardSelection }, { children: cardDesignOptions.map(function (cardDesignOption, index) {
                        var _a;
                        var cardInfo = cardDesignOption;
                        var imageSrc = (_a = cardDesignOptionsImages[cardInfo.cardDesignID]) === null || _a === void 0 ? void 0 : _a.physical;
                        if ((cardInfo === null || cardInfo === void 0 ? void 0 : cardInfo.status) === CardDesignStatus.SELECTABLE ||
                            (cardInfo === null || cardInfo === void 0 ? void 0 : cardInfo.status) === CardDesignStatus.NOT_SELECTABLE) {
                            return (_jsx(ImpCardThumbnail, { id: "card-thumbnail-".concat(index), isSelected: currentSelection === cardDesignOption.cardDesignID, src: imageSrc, onClick: function () {
                                    ANALYTICS.logEvent(EVENT.CARD_ART_SELECTION_CHANGED, {
                                        card_status: cardDesignOption.status,
                                        card_design_id: cardDesignOption.cardDesignID,
                                    });
                                    setCurrentSelection(cardDesignOption.cardDesignID);
                                } }, index));
                        }
                        else {
                            return _jsx(React.Fragment, {}, index);
                        }
                    }) })), _jsx(ImpSpacer, { height: "xl" }), _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpButton, __assign({ size: "fill", onClick: handleContinue, buttonStyle: 'branded', state: primaryButtonState }, { children: language.confirmCard })) }))] })) })));
}
function CardDesignScreen(_a) {
    var screen = _a.screen;
    switch (screen) {
        case CARD_SELECTION_SCREEN.LOADING:
            return _jsx(Spinner, {});
        case CARD_SELECTION_SCREEN.CONFIRM_ADDRESS:
            return _jsx(CardDesignConfirmation, {});
        case CARD_SELECTION_SCREEN.CARD_SELECTION:
            return _jsx(SelectCard, {});
        case CARD_SELECTION_SCREEN.CONFIRM_ADDRESS_LATER:
            return _jsx(ConfirmAddressLater, {});
        default:
            return null;
    }
}
export default function CardDesignSelectionFlow() {
    var cardDesignSelector = useAppSelector(function (state) { return state.cardDesignSelector; });
    var dispatch = useDispatch();
    var _a = useImpState(), merchantData = _a.merchantData, appStatus = _a.appStatus;
    var cardDesignOptions = useAppSelector(function (state) { return state.consumerInfoSelector.cardOffer.cardDesignOptions; });
    var cardDesignOptionsImages = merchantData.cardDesignOptionsImages;
    useEffect(function () {
        var _a;
        if (appStatus.productAccountUUID) {
            dispatch(setCardDesignSelectionScreen(CARD_SELECTION_SCREEN.CONFIRM_ADDRESS));
        }
        else if (cardDesignOptionsImages[(_a = cardDesignOptions[0]) === null || _a === void 0 ? void 0 : _a.cardDesignID]) {
            dispatch(setCardDesignSelectionScreen(CARD_SELECTION_SCREEN.CARD_SELECTION));
        }
        else {
            dispatch(setCardDesignSelectionScreen(CARD_SELECTION_SCREEN.LOADING));
        }
    }, [cardDesignOptionsImages, cardDesignOptions]);
    return (_jsxs(ImpContainer, __assign({ width: "100%" }, { children: [_jsx(ImpSpacer, { height: "2xl", mobileHeight: "xl" }), _jsx(ImpFlex, __assign({ direction: "row", justify: "center", fluid: true }, { children: _jsx(CardDesignScreen, { screen: cardDesignSelector.screen }) }))] })));
}
