import { createSlice } from '@reduxjs/toolkit';
export var SCREEN;
(function (SCREEN) {
    SCREEN[SCREEN["OFFER"] = 0] = "OFFER";
    SCREEN[SCREEN["APPLICATION"] = 1] = "APPLICATION";
    SCREEN[SCREEN["OTPSCREEN"] = 2] = "OTPSCREEN";
    SCREEN[SCREEN["CONFIRM"] = 3] = "CONFIRM";
    SCREEN[SCREEN["TRANSITION"] = 4] = "TRANSITION";
    SCREEN[SCREEN["ERROR"] = 5] = "ERROR";
    SCREEN[SCREEN["STATUS"] = 6] = "STATUS";
    SCREEN[SCREEN["ACCEPT"] = 7] = "ACCEPT";
    SCREEN[SCREEN["ONE_TIME_PAYMENT"] = 8] = "ONE_TIME_PAYMENT";
    SCREEN[SCREEN["ACCOUNT"] = 9] = "ACCOUNT";
    SCREEN[SCREEN["DOCUMENTS"] = 10] = "DOCUMENTS";
    SCREEN[SCREEN["LOGIN"] = 11] = "LOGIN";
    SCREEN[SCREEN["DOB"] = 12] = "DOB";
    SCREEN[SCREEN["PIN"] = 13] = "PIN";
    SCREEN[SCREEN["SERVICE_OTPSCREEN"] = 14] = "SERVICE_OTPSCREEN";
    SCREEN[SCREEN["VERIFICATION_FAILED"] = 15] = "VERIFICATION_FAILED";
    SCREEN[SCREEN["ACCOUNT_PENDING"] = 16] = "ACCOUNT_PENDING";
    SCREEN[SCREEN["INCOME_HOUSING_MODAL"] = 17] = "INCOME_HOUSING_MODAL";
    SCREEN[SCREEN["DUPLICATE_APP"] = 18] = "DUPLICATE_APP";
    SCREEN[SCREEN["APPLICATION_IN_REVIEW"] = 19] = "APPLICATION_IN_REVIEW";
    SCREEN[SCREEN["APPLICATION_NOT_APPROVED"] = 20] = "APPLICATION_NOT_APPROVED";
    SCREEN[SCREEN["CREDIT_FROZEN"] = 21] = "CREDIT_FROZEN";
    SCREEN[SCREEN["APPLICATION_EXPIRED"] = 22] = "APPLICATION_EXPIRED";
    SCREEN[SCREEN["APPLICATION_ACTIVE_USER"] = 23] = "APPLICATION_ACTIVE_USER";
    SCREEN[SCREEN["ACCOUNT_LOCKED"] = 24] = "ACCOUNT_LOCKED";
    SCREEN[SCREEN["STATUS_OTP_VERIFICATION_FAILED"] = 25] = "STATUS_OTP_VERIFICATION_FAILED";
    SCREEN[SCREEN["CREATE_PIN"] = 26] = "CREATE_PIN";
    SCREEN[SCREEN["COMPLETE_ON_PHONE"] = 27] = "COMPLETE_ON_PHONE";
})(SCREEN || (SCREEN = {}));
var initialState = {
    screen: SCREEN.APPLICATION,
};
var screenStateSlice = createSlice({
    name: 'screenState',
    initialState: initialState,
    reducers: {
        setScreen: function (state, action) {
            state.screen = action.payload;
        },
    },
});
export var setScreen = screenStateSlice.actions.setScreen;
export default screenStateSlice.reducer;
