import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { GoogleAuthProvider } from 'firebase/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import { useEffect } from 'react';
import { setInternalAuthRequired } from '../../app/apiScreenSlice';
import { useImpState } from '../../app/hooks';
import { auth } from '../../firebase/firebase';
var uiConfig = {
    signInFlow: 'popup',
    signInOptions: [GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
        signInSuccessWithAuthResult: function () { return false; },
    },
};
function FirebaseLogin() {
    var dispatch = useImpState().dispatch;
    useEffect(function () {
        // Initialize the FirebaseUI Widget using Firebase.
        var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
        ui.start('#firebaseui-auth-container', {
            signInFlow: 'popup',
            signInOptions: [GoogleAuthProvider.PROVIDER_ID],
            callbacks: {
                signInSuccessWithAuthResult: function (authResult, redirectUrl) {
                    // Handle the result
                    dispatch(setInternalAuthRequired(false));
                    return true;
                },
            },
        });
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx("link", { type: "text/css", rel: "stylesheet", href: "https://www.gstatic.com/firebasejs/ui/6.0.1/firebase-ui-auth.css" }), _jsx("div", { id: "firebaseui-auth-container" })] }));
}
export default FirebaseLogin;
