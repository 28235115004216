var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import LetsGetStarted from './LetsGetStarted';
import { useEffect, useState } from 'react';
import EarlyOtp from './EarlyOtp';
import { colors, ImpSurface } from '@imprint-payments/imprint-ui';
import IdentityResult from './IdentityResult';
import { useImpState } from '../../app/hooks';
import { setProveSessionId } from '../../app/applicationScreenSlice';
import InfoFields from './InfoFields';
export var QUERY_KEY_PREFILL = 'prefillData';
var PrefillStep;
(function (PrefillStep) {
    PrefillStep[PrefillStep["LetsGetStarted"] = 0] = "LetsGetStarted";
    PrefillStep[PrefillStep["Otp"] = 1] = "Otp";
    PrefillStep[PrefillStep["IdentityResult"] = 2] = "IdentityResult";
    PrefillStep[PrefillStep["InfoFields"] = 3] = "InfoFields";
})(PrefillStep || (PrefillStep = {}));
export default function PrefillFlow() {
    var _a;
    var _b = useState(PrefillStep.LetsGetStarted), step = _b[0], setStep = _b[1];
    var dispatch = useImpState().dispatch;
    useEffect(function () {
        dispatch(setProveSessionId(generateSessionId()));
    }, []);
    return (_jsx(ImpSurface, __assign({ surfaceColor: colors.surface.surfaceLow }, { children: (_a = {},
            _a[PrefillStep.LetsGetStarted] = (_jsx(LetsGetStarted, { nextStep: function () { return setStep(PrefillStep.Otp); } })),
            _a[PrefillStep.Otp] = _jsx(EarlyOtp, { nextStep: function () { return setStep(PrefillStep.IdentityResult); } }),
            _a[PrefillStep.IdentityResult] = (_jsx(IdentityResult, { nextStep: function (showPrefill) {
                    if (showPrefill)
                        setStep(PrefillStep.InfoFields);
                    else
                        console.log('go to manual app!');
                } })),
            _a[PrefillStep.InfoFields] = _jsx(InfoFields, {}),
            _a)[step] })));
}
/**
 * Generates a random 6 alphanumeric character string for use as a session ID.
 * @returns {string} A randomly generated 6 character string.
 */
function generateSessionId() {
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var sessionId = '';
    for (var i = 0; i < 6; i++) {
        sessionId += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return sessionId;
}
