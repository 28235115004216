var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpDivider, ImpFlex, ImpPad, ImpText, colors } from '@imprint-payments/imprint-ui';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
export function PrescreenLabel(_a) {
    var code = _a.code;
    var language = useAppSelector(selectLanguage);
    return (_jsxs(ImpFlex, __assign({ direction: "column", fluid: true }, { children: [_jsx(ImpDivider, { color: colors.border.borderVariant }), _jsx(ImpPad, __assign({ padding: "16px 0px", fluid: true }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "flex-start" }, { children: [_jsx(ImpText, __assign({ typography: "body3", color: colors.content.onSurfaceVariant }, { children: language.prescreenCodeLabel })), _jsx(ImpText, { children: code })] })) }))] })));
}
