import { emptyAddress } from './Address';
import { emptyDob } from './Dob';
var emptyName = {
    firstName: '',
    lastName: '',
};
export var emptyConsumerProfile = {
    name: emptyName,
    phone: '',
    email: '',
    address: emptyAddress,
    dob: emptyDob,
};
