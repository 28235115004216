var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { colors, ImpButton, ImpCard, ImpContainer, ImpDivider, ImpFlex, ImpModal, ImpModalHeader, ImpPad, ImpSpacer, ImpSurface, ImpText, spacing, useModal, } from '@imprint-payments/imprint-ui';
import { useStatsigClient } from '@statsig/react-bindings';
import QRCode from 'qrcode.react';
import { useEffect } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { useImpState } from '../../app/hooks';
import { getLandingPageUrl, IMPRINT_STORE_HELP_URL, PARAM_SHOW_WPP, } from '../../assets/consts/inStoreConsts';
import { ANALYTICS } from '../../firebase/firebase';
import Gates from '../../statsig/gates';
import { getDomainFromMerchant } from '../../utils/environments';
import { QR_QUERY_PARAMS } from '../../utils/functionUtils';
export var navigateToLP = function (productUUID) {
    window.location.href = getLandingPageUrl(productUUID);
};
function InStoreModal(_a) {
    var sourcePage = _a.sourcePage;
    var _b = useModal(true), isInStoreModalOpen = _b[0], isInStoreModalClosing = _b[1], _ = _b[2], closeInStoreModal = _b[3];
    return (_jsx(ImpModal, __assign({ isOpen: isInStoreModalOpen, isClosing: isInStoreModalClosing }, { children: {
            signup: _jsx(SignupModal, { closeModal: closeInStoreModal }),
            offer: _jsx(OfferModal, {}),
            existing: _jsx(WelcomeBackModal, {}),
        }[sourcePage] })));
}
var SignupModal = function (_a) {
    var closeModal = _a.closeModal;
    var _b = useImpState(), merchantData = _b.merchantData, language = _b.language;
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.signupModalViewed);
        return function () { return ANALYTICS.logEvent(EVENT.signupModalClosed); };
    }, []);
    var qrLink = getDomainFromMerchant(merchantData.productUUID) + '?' + sessionStorage.getItem(QR_QUERY_PARAMS);
    console.log("qrLink: ".concat(qrLink));
    return (_jsx(ImpSurface, __assign({ fluid: true }, { children: _jsx(ImpPad, __assign({ paddingTop: "xl", paddingBottom: "xl" }, { children: _jsxs(ImpFlex, __assign({ gap: "3xl", mobileGap: "3xl", direction: "column", fluid: true, align: "center" }, { children: [_jsx(ImpPad, __assign({ fluid: true, paddingLeft: "xl", paddingRight: "xl" }, { children: _jsx(ImpModalHeader, { closeModal: closeModal }) })), _jsx(ImpContainer, __assign({ backgroundColor: 'var(--merchant-color)', borderRadius: spacing.s }, { children: _jsx(ImpPad, __assign({ padding: spacing.l }, { children: _jsx(QRCode, { value: qrLink, size: 132, fgColor: colors.content.onSurface }) })) })), _jsx(ImpFlex, __assign({ align: "center", direction: "column", fluid: true }, { children: _jsx(ImpContainer, __assign({ textAlign: "center" }, { children: _jsxs(ImpPad, __assign({ paddingLeft: "xl", paddingRight: "xl" }, { children: [_jsx(ImpText, __assign({ color: 'var(--merchant-color)', typography: "headline3" }, { children: language.forTheBestExp })), _jsx(ImpSpacer, { height: spacing.s }), _jsx(ImpText, __assign({ color: 'var(--merchant-color)', typography: "display3" }, { children: language.scanQRApply }))] })) })) })), _jsxs(ImpFlex, __assign({ align: "center", fluid: true, direction: "column" }, { children: [_jsx(ImpDivider, { color: 'var(--merchant-color)' }), _jsx(ImpSpacer, { height: spacing.xl }), _jsx(ImpButton, __assign({ onClick: closeModal, variant: "secondary", buttonStyle: "branded" }, { children: language.continueOnThisDevice }))] }))] })) })) })));
};
export var OfferModal = function () {
    var _a = useImpState(), merchantData = _a.merchantData, language = _a.language;
    var checkGate = useStatsigClient().checkGate;
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.offerModalViewed);
    }, []);
    function makeLoginQrLink() {
        var loginUrl = getDomainFromMerchant(''); // login link
        var qrUrl = new URL(loginUrl);
        // Use URLSearchParams to manage the query parameters
        var params = new URLSearchParams(qrUrl.search);
        params.set(PARAM_SHOW_WPP, 'true'); // Add or update the parameter
        // Set the modified search parameters back to the URL
        qrUrl.search = params.toString();
        return qrUrl.toString();
    }
    var qrLink = checkGate(Gates.AddToWalletGate) ? makeLoginQrLink() : IMPRINT_STORE_HELP_URL;
    console.log("qrLink: ".concat(qrLink));
    return (_jsx(ImpSurface, __assign({ fluid: true }, { children: _jsx(ImpPad, __assign({ paddingTop: "2xl", paddingBottom: "xl" }, { children: _jsxs(ImpFlex, __assign({ gap: "3xl", mobileGap: "3xl", direction: "column", fluid: true, align: "center" }, { children: [_jsx(ImpContainer, __assign({ backgroundColor: 'var(--merchant-color)', borderRadius: spacing.s }, { children: _jsx(ImpPad, __assign({ padding: spacing.l }, { children: _jsx(QRCode, { value: qrLink, size: 132, fgColor: colors.content.onSurface }) })) })), _jsx(ImpCard, { heightPx: 216, widthPx: 343, borderRadiusPx: 8, src: merchantData.cardImg, hoverable: true, shimmer: true }), _jsx(ImpFlex, __assign({ align: "center", direction: "column", fluid: true }, { children: _jsx(ImpContainer, __assign({ textAlign: "center" }, { children: _jsxs(ImpPad, __assign({ paddingLeft: "xl", paddingRight: "xl" }, { children: [_jsx(ImpText, __assign({ color: 'var(--merchant-color)', typography: "display3" }, { children: language.scanQRUseCardNow })), _jsx(ImpSpacer, { height: spacing.s }), _jsx(ImpText, __assign({ color: 'var(--merchant-color)', typography: "headline3" }, { children: language.orClickTheLink }))] })) })) })), _jsxs(ImpFlex, __assign({ align: "center", fluid: true, direction: "column" }, { children: [_jsx(ImpDivider, { color: 'var(--merchant-color)' }), _jsx(ImpSpacer, { height: spacing.xl }), _jsx(ImpButton, __assign({ onClick: function () { return navigateToLP(merchantData.productUUID); }, variant: "secondary", buttonStyle: "color", color: 'var(--merchant-color)' }, { children: language.goBackToLP }))] }))] })) })) })));
};
var WelcomeBackModal = function () {
    var _a = useImpState(), merchantData = _a.merchantData, language = _a.language;
    var qrLink = getDomainFromMerchant('');
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.welcomeBackModalViewed);
    }, []);
    console.log("qrLink: ".concat(qrLink));
    return (_jsx(ImpSurface, __assign({ fluid: true }, { children: _jsx(ImpPad, __assign({ paddingTop: "2xl", paddingBottom: "xl" }, { children: _jsxs(ImpFlex, __assign({ gap: "3xl", mobileGap: "3xl", direction: "column", fluid: true, align: "center" }, { children: [_jsx(ImpContainer, __assign({ backgroundColor: 'var(--merchant-color)', borderRadius: spacing.s }, { children: _jsx(ImpPad, __assign({ padding: spacing.l }, { children: _jsx(QRCode, { value: qrLink, size: 132, fgColor: colors.content.onSurface }) })) })), _jsx(ImpCard, { heightPx: 216, widthPx: 343, borderRadiusPx: 8, src: merchantData.cardImg, shimmer: true }), _jsx(ImpFlex, __assign({ align: "center", direction: "column", fluid: true }, { children: _jsx(ImpContainer, __assign({ textAlign: "center" }, { children: _jsxs(ImpPad, __assign({ paddingLeft: "xl", paddingRight: "xl" }, { children: [_jsx(ImpText, __assign({ color: 'var(--merchant-color)', typography: "display3" }, { children: language.welcomeBackExclamation })), _jsx(ImpSpacer, { height: spacing.s }), _jsx(ImpText, __assign({ color: 'var(--merchant-color)', typography: "headline3", handleSuperscript: true }, { children: language.thankYouFor.replace('$cardName$', merchantData.rewardDetails.cardName) }))] })) })) })), _jsxs(ImpFlex, __assign({ align: "center", fluid: true, direction: "column" }, { children: [_jsx(ImpDivider, { color: 'var(--merchant-color)' }), _jsx(ImpSpacer, { height: spacing.xl }), _jsx(ImpButton, __assign({ onClick: function () { return navigateToLP(merchantData.productUUID); }, variant: "secondary", buttonStyle: "color", color: 'var(--merchant-color)' }, { children: language.goBackToLP }))] }))] })) })) })));
};
export default InStoreModal;
