var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import DOMPurify from 'dompurify';
import Cookies from 'universal-cookie';
import { REAPPLICATION_ACCOUNT_STATUS } from '../api/api';
import { SIGN_UP_ELIGIBILITY_STATUS } from '../api/apiConstants';
import { ENV } from '../app/apiScreenSlice';
import { MODAL_ENUM, requireFullSSN, SECTION, setEligibilityVerified, setFullNumberEntered, setInStoreDevice, setSection, setShowModal, setShowWelcomeBackCard, } from '../app/applicationScreenSlice';
import { setAppStatus } from '../app/applicationStatusSlice';
import { resetConsumerInfoSlice, setConsumerFactor, setConsumerProfile, } from '../app/consumerInfoSlice';
import { setMultiCardUser, setShowSignUp } from '../app/loginSlice';
import { setIncorrectOtpCode, setReachedMaxRetries } from '../app/otpScreenSlice';
import { SCREEN, setScreen } from '../app/screenStateSlice';
import { resetInputValidation } from '../app/validatorSlice';
import { PARAM_IN_STORE, PARAM_SHOW_WPP } from '../assets/consts/inStoreConsts';
import { ANALYTICS } from '../firebase/firebase';
import { COOKIEKEY, DOMAIN } from './cookieConstants';
import { LANGUAGE_HEADER, LanguageCode } from './languageConstants';
import { ZendeskUrl } from './merchantLinks';
import { merchantInfos } from './productUUIDConstants';
import { PAGE } from './routeConstant';
export var clearHomePageState = function (dispatch) {
    dispatch(setMultiCardUser(false));
    dispatch(setShowWelcomeBackCard(false));
    dispatch(setShowSignUp(false));
    dispatch(setAppStatus(''));
    dispatch(setSection(SECTION.PERSONALINFO));
    dispatch(requireFullSSN(false));
    dispatch(resetConsumerInfoSlice());
    dispatch(setFullNumberEntered(false));
    dispatch(resetInputValidation());
    dispatch(setEligibilityVerified(false));
    dispatch(setShowModal(MODAL_ENUM.noModal));
    dispatch(setScreen(SCREEN.APPLICATION));
    return;
};
export var handleRedirectCookie = function (token, deviceID, consumerUUID, productUUID, isMultiCard) {
    var domain = window.location.hostname.toLowerCase();
    var domainParse = domain.split('.');
    console.log('running redirect');
    if (domain === ENV.LOCAL) {
        console.log('saving', token);
        saveCookie(COOKIEKEY.JWT, token);
        saveCookie(COOKIEKEY.DEVICEID, deviceID);
        saveCookie(COOKIEKEY.CONSUMERUUID, consumerUUID);
        saveCookie(COOKIEKEY.REDIRECTED, 'true');
        saveCookie(COOKIEKEY.PRODUCTUUID, productUUID);
        saveCookie(COOKIEKEY.ISMULTICARD, isMultiCard);
    }
    else if (domainParse.includes(ENV.STG)) {
        console.log('stg');
        saveCookie(COOKIEKEY.JWT, token, DOMAIN.STG);
        saveCookie(COOKIEKEY.DEVICEID, deviceID, DOMAIN.STG);
        saveCookie(COOKIEKEY.CONSUMERUUID, consumerUUID, DOMAIN.STG);
        saveCookie(COOKIEKEY.REDIRECTED, 'true', DOMAIN.STG);
        saveCookie(COOKIEKEY.PRODUCTUUID, productUUID, DOMAIN.STG);
        saveCookie(COOKIEKEY.ISMULTICARD, isMultiCard, DOMAIN.STG);
    }
    else {
        saveCookie(COOKIEKEY.JWT, token, DOMAIN.PROD);
        saveCookie(COOKIEKEY.DEVICEID, deviceID, DOMAIN.PROD);
        saveCookie(COOKIEKEY.CONSUMERUUID, consumerUUID, DOMAIN.PROD);
        saveCookie(COOKIEKEY.REDIRECTED, 'true', DOMAIN.PROD);
        saveCookie(COOKIEKEY.PRODUCTUUID, productUUID, DOMAIN.PROD);
        saveCookie(COOKIEKEY.ISMULTICARD, isMultiCard, DOMAIN.PROD);
    }
};
export var getCookieValueFor = function (key) {
    // Cookies currently in format: 'key1=value1; key2=value2; ...;'
    var cookies = new Cookies();
    return cookies.get(key);
};
export var removeCookieFor = function (key) {
    var cookies = new Cookies();
    var domain = window.location.hostname.toLowerCase();
    var domainParse = domain.split('.');
    var path = '/';
    if (domain === ENV.LOCAL) {
        return cookies.remove(key);
    }
    else if (domainParse.includes(ENV.STG)) {
        return cookies.remove(key, { path: path, domain: DOMAIN.STG });
    }
    else {
        return cookies.remove(key, { path: path, domain: DOMAIN.PROD });
    }
};
export var saveCookie = function (key, value, domain) {
    // key-value that you want to add will be overwritten if it existed previously or not.
    var cookieMaxAgeSecs = 5 * 60;
    if (domain !== undefined) {
        document.cookie = "".concat(key, "=").concat(value, "; path=/; secure; sameSite=Lax; domain=").concat(domain, "; max-age=").concat(cookieMaxAgeSecs);
    }
    else {
        // for localhost test
        document.cookie = "".concat(key, "=").concat(value, "; path=/; secure; sameSite=Lax; max-age=").concat(cookieMaxAgeSecs);
    }
};
export var sanitize = function (url) {
    // strip special  characters
    return DOMPurify.sanitize(url).replace(/[^a-zA-Z0-9-._~:/?#[@\]!$&'*+,;=]/g, '');
};
export var validateUrlOrReturnEmpty = function (url) {
    // URL validation
    var regex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    var isValid = regex.test(url);
    return isValid ? url : '';
};
export var QR_QUERY_PARAMS = 'full_query'; // excludes in_store query param
export var setSessionUrlParam = function (link, dispatch) {
    var urlSearchParams = new URLSearchParams(link);
    if (urlSearchParams.get(PARAM_IN_STORE) === 'true') {
        // consume the query parameter
        dispatch(setInStoreDevice(true));
        urlSearchParams.delete(PARAM_IN_STORE);
    }
    var params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length !== 0) {
        for (var key in params) {
            var clean = sanitize(params[key]);
            sessionStorage.setItem(key, clean);
        }
        sessionStorage.setItem(QR_QUERY_PARAMS, urlSearchParams.toString());
    }
};
export var setSessionLanguage = function (language, env) {
    switch (env) {
        case ENV.STG:
            saveCookie(LANGUAGE_HEADER, language, DOMAIN.STG);
            break;
        case ENV.PROD:
            saveCookie(LANGUAGE_HEADER, language, DOMAIN.PROD);
            break;
        default:
            saveCookie(LANGUAGE_HEADER, language);
    }
};
export var getLanguageFromCookie = function () {
    var languageVal = getCookieValueFor(COOKIEKEY.LANGUAGE_HEADER);
    switch (languageVal) {
        case LanguageCode.Spanish:
            return LanguageCode.Spanish;
        default:
            return LanguageCode.English;
    }
};
export var convertAmountToString = function (am) {
    if (!am) {
        return '';
    }
    return am.displaySymbol + am.displayValue;
};
export var clearServiceLoginState = function (dispatch) {
    dispatch(setIncorrectOtpCode(false));
    dispatch(setReachedMaxRetries(false));
    dispatch(resetInputValidation());
    return;
};
export var reApplicationSetUpAfterPrompt = function (promptData, dispatch, getState) {
    var consumerProfile = getState().consumerInfoSelector.consumerProfile;
    var consumerFactor = getState().consumerInfoSelector.consumerFactor;
    var eligiblePhoneNumber = getState().loginSelector.eligiblePhoneNumber;
    if (promptData.signUpEligibilityStatus === SIGN_UP_ELIGIBILITY_STATUS.ELIGIBLE ||
        promptData.signUpEligibilityStatus === SIGN_UP_ELIGIBILITY_STATUS.REAPPLY) {
        dispatch(setConsumerProfile(__assign(__assign({}, consumerProfile), { phone: eligiblePhoneNumber })));
    }
    if (promptData.consumer) {
        dispatch(setConsumerProfile(__assign(__assign({}, consumerProfile), { address: promptData.consumer.address, name: {
                firstName: promptData.consumer.name.firstName,
                lastName: promptData.consumer.name.lastName,
            }, phone: promptData.consumer.phone, email: promptData.consumer.email, dob: promptData.consumer.dob })));
    }
    if (promptData.consumerFactors) {
        dispatch(setConsumerFactor(__assign(__assign({}, consumerFactor), { income: promptData.consumerFactors.income, incomeType: promptData.consumerFactors.incomeType, housingType: promptData.consumerFactors.housingType, housingCost: promptData.consumerFactors.housingCost })));
    }
    // single card user would be flagged as rejection/frozen in prompt response
    dispatch(setMultiCardUser(false));
    dispatch(setShowSignUp(true));
    dispatch(setShowWelcomeBackCard(true));
    dispatch(setShowModal(MODAL_ENUM.noModal));
    clearServiceLoginState(dispatch);
};
export var reApplicationSetUpAfterHome = function (dispatch, homeData, accountStatus) {
    switch (accountStatus) {
        case REAPPLICATION_ACCOUNT_STATUS.EXPIRED_OFFER:
            if (homeData &&
                homeData.actions.length === 1 &&
                !(homeData.productAccounts && homeData.productAccounts.length >= 1)) {
                dispatch(setMultiCardUser(false));
            }
            break;
    }
    dispatch(setShowSignUp(true));
    dispatch(setShowWelcomeBackCard(true));
    dispatch(setShowModal(MODAL_ENUM.noModal));
};
export var getContactSupportLink = function (productUUID, selectedLanguage) {
    var _a, _b;
    var selectedLanguageCode = selectedLanguage;
    return (_b = (_a = merchantInfos[productUUID]) === null || _a === void 0 ? void 0 : _a.zendesk[selectedLanguageCode]) !== null && _b !== void 0 ? _b : ZendeskUrl.DEFAULT;
};
export var redirectTo = function (label, newTab, inStoreOnboarding, findAccount) {
    if (label === void 0) { label = 'account'; }
    if (newTab === void 0) { newTab = false; }
    if (inStoreOnboarding === void 0) { inStoreOnboarding = false; }
    if (findAccount === void 0) { findAccount = false; }
    var domain = window.location.hostname.toLowerCase();
    var domainParse = domain.split('.');
    ANALYTICS.analyticsUtil.writeStoredEvents();
    var url = '';
    if (domain === ENV.LOCAL) {
        // TODO: for local test: set url as localhost port
        url = 'http://localhost:8081';
    }
    else if (domainParse.includes(ENV.STG)) {
        url = 'https://' + label + DOMAIN.STG;
    }
    else {
        url = 'https://' + label + DOMAIN.PROD;
    }
    if (inStoreOnboarding) {
        url += "?".concat(PARAM_SHOW_WPP, "=true");
    }
    else if (findAccount) {
        url += PAGE.FIND_ACCOUNT;
    }
    newTab ? window.open(url, '_blank') : window.location.replace(url);
};
export var redirectToAccountWithCookie = function (token, deviceID, consumerUUID, productUUID, isMultiCard, inStoreOnboarding) {
    if (inStoreOnboarding === void 0) { inStoreOnboarding = false; }
    handleRedirectCookie(token, deviceID, consumerUUID, productUUID, isMultiCard);
    redirectTo('account', false, inStoreOnboarding);
};
export var addQueryParamToUrl = function (url, key, value) {
    var urlObject = new URL(url);
    var searchParams = urlObject.searchParams;
    // If the key already exists, update its value; otherwise, add a new key-value pair.
    searchParams.set(key, value);
    // Update the URL with the modified search parameters.
    urlObject.search = searchParams.toString();
    return urlObject.toString();
};
export var getOfferUUIDs = function () {
    var _a;
    var OFFER_CONFIG_UUIDS = 'offerConfigUUIDs';
    if (sessionStorage && sessionStorage.getItem(OFFER_CONFIG_UUIDS) !== '') {
        return (_a = sessionStorage
            .getItem(OFFER_CONFIG_UUIDS)) === null || _a === void 0 ? void 0 : _a.split(',').map(function (item) { return item.trim(); });
    }
    return [];
};
export var getPreapprovalCodeFromSession = function () {
    var PREAPPROVAL_KEY = 'preapproval_code';
    if (sessionStorage && sessionStorage.getItem(PREAPPROVAL_KEY) !== '') {
        return sessionStorage.getItem(PREAPPROVAL_KEY);
    }
};
