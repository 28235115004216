export var PAGE;
(function (PAGE) {
    PAGE["HOME"] = "/";
    PAGE["START"] = "/start";
    PAGE["OTP"] = "/otp";
    PAGE["OFFER"] = "/offer";
    PAGE["ERROR"] = "/error";
    PAGE["ACCEPT"] = "/accept";
    PAGE["ACCOUNT_STATUS"] = "/account-status";
    PAGE["FIND_ACCOUNT"] = "/find-user-account";
    PAGE["CHECK_APPLICATION"] = "/checkApplication";
    PAGE["HEB"] = "/heb/credit";
    PAGE["CM"] = "/centralmarket/credit";
    PAGE["HICV"] = "/holidayinnclub/credit";
    PAGE["HH_CREDIT"] = "/horizonhobby/credit";
    PAGE["WESTGATE"] = "/westgateresorts/credit";
    PAGE["TURKISH"] = "/turkishairlines/premier/credit";
    PAGE["EB"] = "/eddiebauer/credit";
    PAGE["BB"] = "/brooksbrothers/credit";
    PAGE["BOOKING"] = "/booking/credit";
    PAGE["CRATE_BARREL"] = "/crateandbarrel/credit";
    PAGE["CREATE_PIN"] = "/create-pin";
    PAGE["COMPLETE_ON_PHONE"] = "/complete-on-phone";
})(PAGE || (PAGE = {}));
