var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { setAppStatus, setShowResult } from '../../app/applicationStatusSlice';
import { setConsumerProfile } from '../../app/consumerInfoSlice';
import { useAppSelector, useImpState } from '../../app/hooks';
import { OTPFLOW, setOtpFlow } from '../../app/otpScreenSlice';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { clearHomePageState } from '../../utils/functionUtils';
import { merchantInfos } from '../../utils/productUUIDConstants';
import { PAGE } from '../../utils/routeConstant';
import { ValidationResponse } from '../../utils/validateUtils';
import Modal from '../modal/Modal';
export default function appStatus() {
    var _a = useImpState(), language = _a.language, merchantData = _a.merchantData, consumerProfile = _a.consumerProfile, appStatus = _a.appStatus, dispatch = _a.dispatch, navigate = _a.navigate;
    var _b = useState(appStatus.showResult), showStatusCheckResult = _b[0], setShowStatusCheckResult = _b[1];
    var _c = useState(''), birthDay = _c[0], setBirthDay = _c[1];
    var _d = useState(''), phoneNumber = _d[0], setPhoneNumber = _d[1];
    var _e = useState(''), fullSSN = _e[0], setFullSSN = _e[1];
    var _f = useState(true), disabledBtn = _f[0], setDisabledBtn = _f[1];
    var _g = useState(true), birthDayErr = _g[0], setBirthDayErr = _g[1];
    var _h = useState(true), phoneNumberErr = _h[0], setPhoneNumberErr = _h[1];
    var _j = useState(true), SSNErr = _j[0], setSSNErr = _j[1];
    var _k = useState(window.matchMedia('(min-width: 768px)').matches), matches = _k[0], setMatches = _k[1];
    var validator = useAppSelector(function (state) { return state.validatorSelector; });
    useEffect(function () {
        window
            .matchMedia('(min-width: 768px)')
            .addEventListener('change', function (e) { return setMatches(e.matches); });
    }, []);
    useEffect(function () {
        setDisabledBtn(true);
        if (validator.birthDateError === ValidationResponse.valid &&
            validator.phoneNumberError === ValidationResponse.valid &&
            validator.fullSSNError === ValidationResponse.valid &&
            birthDay !== '' &&
            phoneNumber !== '' &&
            fullSSN !== '') {
            setDisabledBtn(false);
        }
    }, [validator, birthDay, fullSSN, phoneNumber]);
    function backClick() {
        dispatch(setScreen(SCREEN.APPLICATION));
        dispatch(setOtpFlow(OTPFLOW.APPLICATION));
        setShowStatusCheckResult(false);
        dispatch(setShowResult(false));
        navigate(PAGE.HOME);
    }
    useEffect(function () {
        window.history.pushState('fake-route', document.title, window.location.href);
        addEventListener('popstate', backClick);
        return function () {
            removeEventListener('popstate', backClick);
            if (window.history.state === 'fake-route') {
                window.history.back();
            }
        };
    }, []);
    var handleContinueClick = function () {
        // TODO: Make an API call to pull application status
        dispatch(setAppStatus(''));
        dispatch(setScreen(SCREEN.OTPSCREEN));
        dispatch(setOtpFlow(OTPFLOW.STATUS));
        var date = new Date(birthDay);
        dispatch(setConsumerProfile(__assign(__assign({}, consumerProfile), { dob: {
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDate(),
            }, phone: phoneNumber
                .split('')
                .filter(function (val) { return !isNaN(+val) && val != ' '; })
                .join(''), ssn: fullSSN.trim().replace(/[^\d]/g, '') })));
        navigate(PAGE.OTP);
    };
    function loadDeeplink() {
        window.open(merchantData.deeplink);
    }
    return (_jsx(Modal, { header: language.checkStatusInMobileHeader, body1: language.checkStatusInMobileBody, qrMessage: language.qrDownload.replace('$link$', merchantInfos[merchantData.productUUID].displaySignupLink), buttonName1: language.downloadBtn, button1Click: loadDeeplink, hideMobileBtn1: true, showQR: true, qr: merchantData.checkApplicationQR, pillBtn1: !matches, buttonName2: language.goBackBtn, pillBtn2: matches, button2Click: function () {
            clearHomePageState(dispatch);
            navigate(PAGE.HOME);
        } }));
}
