import { useImpState } from '../app/hooks';
import { SCREEN } from '../app/screenStateSlice';
import { merchantInfos } from '../utils/productUUIDConstants';
import { PAGE } from '../utils/routeConstant';
export default function useNextPage() {
    var _a = useImpState(), merchantData = _a.merchantData, screenState = _a.screenState, navigate = _a.navigate;
    var nextPage = function () {
        var _a, _b;
        var screen = screenState.screen;
        switch (screen) {
            case SCREEN.APPLICATION: {
                navigate((_b = (_a = merchantInfos[merchantData.productUUID]) === null || _a === void 0 ? void 0 : _a.pageToNavigate) !== null && _b !== void 0 ? _b : PAGE.HOME);
                break;
            }
            case SCREEN.OFFER: {
                navigate(PAGE.OFFER);
                break;
            }
            case SCREEN.OTPSCREEN: {
                navigate(PAGE.OTP);
                break;
            }
            case SCREEN.CREATE_PIN: {
                navigate(PAGE.CREATE_PIN);
                break;
            }
            case SCREEN.COMPLETE_ON_PHONE: {
                navigate(PAGE.COMPLETE_ON_PHONE);
                break;
            }
            case SCREEN.ERROR: {
                navigate(PAGE.ERROR);
                break;
            }
            case SCREEN.ACCEPT: {
                navigate(PAGE.ACCEPT);
                break;
            }
            case SCREEN.ACCOUNT_LOCKED:
            case SCREEN.APPLICATION_IN_REVIEW:
            case SCREEN.APPLICATION_NOT_APPROVED:
            case SCREEN.CREDIT_FROZEN:
            case SCREEN.APPLICATION_EXPIRED:
            case SCREEN.APPLICATION_ACTIVE_USER:
            case SCREEN.STATUS_OTP_VERIFICATION_FAILED: {
                navigate(PAGE.ACCOUNT_STATUS);
                break;
            }
            case SCREEN.SERVICE_OTPSCREEN:
            case SCREEN.PIN:
            case SCREEN.DOB:
            case SCREEN.ACCOUNT_PENDING:
            case SCREEN.INCOME_HOUSING_MODAL:
            case SCREEN.DUPLICATE_APP:
            case SCREEN.VERIFICATION_FAILED: {
                break;
            }
            default: {
                navigate(PAGE.ERROR);
                break;
            }
        }
    };
    return nextPage;
}
