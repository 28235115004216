import { jsx as _jsx } from "react/jsx-runtime";
import { ImpHeader } from '@imprint-payments/imprint-ui';
import { useImpState } from '../../app/hooks';
import { getDomainFromMerchant } from '../../utils/environments';
import { TrailingContent } from '../AlreadyAppliedText/AlreadyAppliedText';
import { LockupImage } from '../LockupImage/LockupImage';
export function HeaderHandler(_a) {
    var _b = _a.showRewardsButton, showRewardsButton = _b === void 0 ? false : _b;
    var merchantData = useImpState().merchantData;
    var _c = merchantData.rewardDetails, cardName = _c.cardName, primaryValueProp = _c.primaryValueProp;
    return (_jsx(ImpHeader, { leadingContent: _jsx(LockupImage, {}), centerText: cardName, trailingContent: _jsx(TrailingContent, { showRewardsButton: showRewardsButton }), valuePropHeaderConfig: {
            cardImageSrc: merchantData.cardImg,
            valueProps: [cardName],
            onValuePropClick: function () {
                window.location.href = getDomainFromMerchant(merchantData.productUUID);
            },
        } }));
}
