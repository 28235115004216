var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpContainer, ImpOtpField, ImpSpacer, ImpText, colors, useSnackbar, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { createPin } from '../../api/createPin/createPin';
import { loadHome } from '../../api/loadHome/loadHome';
import { verifyPin } from '../../api/verifyPin/verifyPin';
import { setLoading } from '../../app/applicationScreenSlice';
import { useAppSelector, useImpState } from '../../app/hooks';
import { setLockOutMessage, setLoginFlow, setPinError } from '../../app/loginSlice';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import imprintLogo from '../../assets/icons/logo-imprint.svg';
import { ANALYTICS } from '../../firebase/firebase';
import { ValidationResponse, isValidPin } from '../../utils/validateUtils';
import ButtonServicing, { ButtonColorScheme } from '../buttonServicing/ButtonServicing';
import Spinner from '../spinner/Spinner';
import styles from './PINEntry.module.css';
import { handleLoadHomeSuccess } from './loadHomeHandlers';
export default function PINEntry(_a) {
    var newPin = _a.newPin;
    var _b = useImpState(), loginState = _b.loginState, dispatch = _b.dispatch, appScreenState = _b.appScreenState, language = _b.language;
    var _c = useState(''), input = _c[0], setInput = _c[1];
    var _d = useState(''), pinToConfirm = _d[0], setPinToConfirm = _d[1];
    var _e = useState(0), curr = _e[0], setCurr = _e[1];
    var _f = useState(ValidationResponse.empty), error = _f[0], setError = _f[1];
    var _g = useState(false), confirmingPin = _g[0], setConfirmingPin = _g[1];
    var _h = useState(false), invalidPinCriteria = _h[0], setInvalidPinCriteria = _h[1];
    var _j = useState(false), clearInput = _j[0], setClearInput = _j[1];
    var _k = useState(false), showError = _k[0], setShowError = _k[1];
    var loginFlow = useAppSelector(function (state) { return state.loginSelector.loginFlow; });
    var displayTemporarySnackbar = useSnackbar().displayTemporarySnackbar;
    useEffect(function () {
        if (![ValidationResponse.valid, ValidationResponse.empty].includes(error)) {
            ANALYTICS.logEvent(EVENT.CONFIRM_PIN_TRY_AGAIN_VIEWED);
            setShowError(true);
        }
    }, [error]);
    useEffect(function () {
        setShowError(loginFlow.pinError);
        if (!newPin) {
            if (loginFlow.pinError) {
                ANALYTICS.logEvent(EVENT.ENTER_PIN_TRY_AGAIN_VIEWED);
                setError(ValidationResponse.invalidPin);
            }
            else {
                setError(ValidationResponse.empty);
            }
        }
        else {
            if (loginFlow.pinError) {
                setError(ValidationResponse.invalidPin);
            }
            else {
                setError(ValidationResponse.valid);
            }
        }
    }, [loginFlow.pinError]);
    useEffect(function () {
        if (confirmingPin) {
            ANALYTICS.logEvent(EVENT.CONFIRM_PIN_VIEWED);
        }
    }, [confirmingPin]);
    useEffect(function () {
        if (!newPin && loginFlow.pinError && curr > 0) {
            dispatch(setLoginFlow(__assign(__assign({}, loginFlow), { pinError: false })));
        }
        if (newPin &&
            [ValidationResponse.invalidPin, ValidationResponse.invalidPinNoMatch].includes(error) &&
            curr > 0) {
            setError(ValidationResponse.empty);
        }
    }, [input, pinToConfirm]);
    function handleError(r) {
        setError(r);
    }
    function focusOnInputBox(inputBox) {
        setCurr(inputBox);
        var input = document.getElementById(inputBox.toString());
        if (input != null) {
            input.focus();
        }
    }
    function clearPinToConfirm() {
        // clear pin to confirm
        setPinToConfirm('');
        // set input boxes
        var inputBoxes = document.querySelectorAll('input');
        inputBoxes.forEach(function (inputBox) {
            inputBox.value = '';
        });
        focusOnInputBox(0);
    }
    function clearError() {
        setError(ValidationResponse.empty);
    }
    function handleChange(s) {
        setClearInput(false);
        if (input !== '') {
            setShowError(false);
            setInvalidPinCriteria(false);
        }
        setClearInput(false);
        setInput(s);
    }
    var handleInputFull = function (s) {
        if (!newPin) {
            ANALYTICS.logEvent(EVENT.ENTER_PIN_SUBMITTED);
            checkPinWithBE(s);
        }
        else if (confirmingPin) {
            ANALYTICS.logEvent(EVENT.CONFIRM_PIN_SUBMITTED);
            comparePins();
        }
        else {
            ANALYTICS.logEvent(EVENT.CREATE_PIN_SUBMITTED);
            validatePIN(s);
        }
    };
    function validatePIN(p) {
        var pinValidity = isValidPin(p);
        if (pinValidity !== ValidationResponse.valid) {
            ANALYTICS.logEvent(EVENT.CREATE_PIN_INVALID_PIN_VIEWED);
            handleError(pinValidity);
            setInvalidPinCriteria(true);
        }
        else {
            setPinToConfirm(p);
            setError(pinValidity);
            setConfirmingPin(true);
            setClearInput(true);
        }
    }
    var handleVerifyPinSuccess = function () {
        dispatch(setLoginFlow(__assign(__assign({}, loginFlow), { pinError: false })));
        loadHome({
            onSuccess: function () {
                // how do we know this is false at this point?
                handleLoadHomeSuccess({
                    isReapplication: false,
                });
            },
            onFail: handleLoadHomeFail,
        });
    };
    // function for existing pin
    function checkPinWithBE(p) {
        verifyPin({
            pin: p,
            onSuccess: handleVerifyPinSuccess,
            onIncorrectEntry: function (message) {
                dispatch(setPinError(true));
                dispatch(setLockOutMessage(message));
                setShowError(true);
            },
            onError: function () {
                dispatch(setPinError(true));
                setShowError(true);
                displayTemporarySnackbar({ text: language.somethingWentWrong });
            },
            onLockout: function (message) {
                dispatch(setLoading(false));
                dispatch(setScreen(SCREEN.VERIFICATION_FAILED));
                dispatch(setLockOutMessage(message));
            },
        });
    }
    // functions for create pin
    function comparePins() {
        if (input === pinToConfirm) {
            handleMatchingPins();
        }
        else {
            handleNonMatchingPins();
        }
    }
    function handleLoadHomeFail() {
        displayTemporarySnackbar({ text: language.somethingWentWrong });
    }
    function handleCreatePinSuccess() {
        loadHome({
            onSuccess: function () {
                return handleLoadHomeSuccess({
                    isReapplication: false,
                });
            },
            onFail: handleLoadHomeFail,
        });
    }
    function handleMatchingPins() {
        setError(ValidationResponse.valid);
        createPin({
            pin: input,
            onSuccess: handleCreatePinSuccess,
            onFail: function () { return displayTemporarySnackbar({ text: language.somethingWentWrong }); },
        });
    }
    function handleNonMatchingPins() {
        setError(ValidationResponse.invalidPinNoMatch);
        setShowError(true);
    }
    return (_jsxs("div", __assign({ className: styles.pinContainer }, { children: [_jsx("div", __assign({ className: styles.imprintLogoContainer }, { children: _jsx("img", { draggable: "false", alt: "logo", className: styles.imprintLogo, src: imprintLogo }) })), _jsx("div", __assign({ className: styles.smallHeader }, { children: newPin
                    ? confirmingPin
                        ? language.confirmYourPin
                        : language.createAPIN
                    : language.enterYourImprintPin })), _jsx(ImpOtpField, { autoFocus: true, numInputs: 4, onChange: handleChange, onFull: handleInputFull, clearWithError: showError || invalidPinCriteria, clear: clearInput, mask: true }), showError && _jsx("div", __assign({ className: styles.pinErrorMessage }, { children: loginState.lockOutMessage })), error === ValidationResponse.invalidPinNoMatch && (_jsx("div", __assign({ className: styles.pinErrorMessage }, { children: language.makeSurePinsMatch }))), newPin && (_jsxs(ImpContainer, { children: [_jsx(ImpSpacer, { height: "xl" }), _jsx(ImpText, __assign({ typography: "body3", color: error === ValidationResponse.invalidPin ? colors.content.onBackgroundError : '#5e5d5c' }, { children: language.pinCreationNote }))] })), confirmingPin && _jsx("div", __assign({ className: styles.confirmingPin }, { children: language.confirmingPin })), confirmingPin && !appScreenState.loading && (_jsx("div", __assign({ className: styles.resetButtonContainer }, { children: _jsx(ButtonServicing, { value: language.resetPin, pill: true, empty: true, handleClick: function () {
                        if (confirmingPin &&
                            [ValidationResponse.valid, ValidationResponse.empty].includes(error)) {
                            ANALYTICS.logEvent(EVENT.CONFIRM_PIN_TRY_AGAIN_RESET_PIN_CLICKED);
                        }
                        else if (confirmingPin) {
                            ANALYTICS.logEvent(EVENT.CONFIRM_PIN_RESET_PIN_CLICKED);
                        }
                        setClearInput(true);
                        clearError();
                        clearPinToConfirm();
                        setConfirmingPin(false);
                    }, colorScheme: ButtonColorScheme.ghostImprint }) }))), appScreenState.loading && _jsx(Spinner, {})] })));
}
