import english from '../assets/languages/en.json';
import spanish from '../assets/languages/es.json';
import { LanguageCode } from './languageConstants';
export var codeToLocalization = function (code) {
    switch (code) {
        case LanguageCode.English: {
            return english;
        }
        case LanguageCode.Spanish: {
            return spanish;
        }
        default: {
            return english;
        }
    }
};
