var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { colors, ImpButton, ImpContainer, ImpDivider, ImpFlex, ImpPad, ImpSpacer, ImpSurface, ImpText, spacing, } from '@imprint-payments/imprint-ui';
import { useStatsigClient } from '@statsig/react-bindings';
import QRCode from 'qrcode.react';
import { useEffect } from 'react';
import { EVENT } from '../analytics/analyticsConsts';
import { useImpState } from '../app/hooks';
import { IMPRINT_STORE_HELP_URL, PARAM_SHOW_WPP } from '../assets/consts/inStoreConsts';
import { navigateToLP } from '../components/application/InStoreModal';
import { HeaderHandler } from '../components/HeaderHandler/HeaderHandler';
import { ANALYTICS } from '../firebase/firebase';
import Gates from '../statsig/gates';
import { getDomainFromMerchant } from '../utils/environments';
import { usePostAcceptPage } from './CreatePINPage';
export function CompleteOnPhonePage() {
    var _a = useImpState(), merchantData = _a.merchantData, language = _a.language;
    var checkGate = useStatsigClient().checkGate;
    usePostAcceptPage();
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.offerModalViewed);
    }, []);
    var qrLink = checkGate(Gates.AddToWalletGate) ? makeLoginQrLink() : IMPRINT_STORE_HELP_URL;
    return (_jsxs("div", { children: [_jsx(HeaderHandler, {}), _jsx(ImpSpacer, { height: "2xl", mobileHeight: "xl" }), _jsx(ImpFlex, __assign({ fluid: true, justify: "center" }, { children: _jsx(ImpPad, __assign({ padding: "0px 16px" }, { children: _jsx(ImpContainer, __assign({ borderRadius: "12px", border: "1px solid ".concat(colors.border.borderVariant) }, { children: _jsx(ImpSurface, __assign({ fluid: true, hasBorderRadius: true }, { children: _jsx(ImpPad, __assign({ paddingTop: "2xl", paddingBottom: "xl" }, { children: _jsxs(ImpFlex, __assign({ gap: "3xl", mobileGap: "3xl", direction: "column", fluid: true, align: "center" }, { children: [_jsx(ImpFlex, __assign({ align: "center", direction: "column", fluid: true }, { children: _jsx(ImpContainer, __assign({ textAlign: "center", maxWidth: "600px", width: "100%" }, { children: _jsxs(ImpPad, __assign({ paddingLeft: "xl", paddingRight: "xl" }, { children: [_jsx(ImpText, __assign({ color: 'var(--merchant-color)', typography: "display3" }, { children: language.scanQRUseCardNow })), _jsx(ImpSpacer, { height: spacing.s }), _jsx(ImpText, __assign({ color: 'var(--merchant-color)', typography: "headline3" }, { children: language.orClickTheLink }))] })) })) })), _jsx(ImpContainer, __assign({ backgroundColor: 'var(--merchant-color)', borderRadius: spacing.s }, { children: _jsx(ImpPad, __assign({ padding: spacing.s }, { children: _jsx(QRCode, { value: qrLink, size: 132, fgColor: colors.content.onSurface }) })) })), _jsxs(ImpFlex, __assign({ align: "center", fluid: true, direction: "column" }, { children: [_jsx(ImpDivider, { color: 'var(--merchant-color)' }), _jsx(ImpSpacer, { height: spacing.xl }), _jsx(ImpButton, __assign({ onClick: function () { return navigateToLP(merchantData.productUUID); }, variant: "secondary", buttonStyle: "color", color: 'var(--merchant-color)' }, { children: language.goBackToLP }))] }))] })) })) })) })) })) }))] }));
    function makeLoginQrLink() {
        var loginUrl = getDomainFromMerchant(''); // login link
        var qrUrl = new URL(loginUrl);
        // Use URLSearchParams to manage the query parameters
        var params = new URLSearchParams(qrUrl.search);
        params.set(PARAM_SHOW_WPP, 'true'); // Add or update the parameter
        // Set the modified search parameters back to the URL
        qrUrl.search = params.toString();
        return qrUrl.toString();
    }
}
