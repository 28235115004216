var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { formatDisplayAmount } from '@imprint-payments/imprint-lib';
import { colors, Icons, ImpBulletList, ImpInlineLink, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import { useState } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { confirm } from '../../api/api';
import { URL } from '../../api/apiConstants';
import { setRedirectToLogin } from '../../app/acceptScreenSlice';
import { useAppSelector, useImpState } from '../../app/hooks';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { ANALYTICS } from '../../firebase/firebase';
import { displayNumberNone, formatRewardsBonus } from '../../utils/formatUtils';
import { clearHomePageState, getOfferUUIDs, redirectToAccountWithCookie, } from '../../utils/functionUtils';
import { getEmployeeId, getStoreNumber } from '../../lib/utmManager';
import { merchantInfos } from '../../utils/productUUIDConstants';
import { PAGE } from '../../utils/routeConstant';
import { SdkEvent, sendMessageToHost } from '../../utils/sdkPostMessageHandler';
import { navigateToLP } from '../application/InStoreModal';
var getFeeTableData = function (consumerInfo, language) {
    var _a, _b, _c, _d;
    return [
        {
            title: language.annualFee,
            trailingSubtitle: displayNumberNone(consumerInfo.cardOffer.fees.annualFee, language),
        },
        {
            title: language.overlimitFee,
            trailingSubtitle: displayNumberNone(consumerInfo.cardOffer.fees.overlimitFee, language),
        },
        {
            title: language.internationalFee,
            trailingSubtitle: displayNumberNone(consumerInfo.cardOffer.fees.internationalFee, language),
        },
        {
            title: language.lateFee,
            trailingSubtitle: "".concat(language.upto, " ").concat(displayNumberNone((_b = (_a = consumerInfo.cardOffer.fees) === null || _a === void 0 ? void 0 : _a.lateFee) === null || _b === void 0 ? void 0 : _b.additionalTime, language)),
        },
        {
            title: language.returnedFee,
            trailingSubtitle: "".concat(language.upto, " ").concat(displayNumberNone((_d = (_c = consumerInfo.cardOffer.fees) === null || _c === void 0 ? void 0 : _c.returnedPaymentFee) === null || _d === void 0 ? void 0 : _d.additionalTime, language)),
        },
    ];
};
var useRewardsTableData = function () {
    var _a = useImpState(), merchantData = _a.merchantData, language = _a.language;
    var offerData = [];
    if (merchantData.rewardDetails.rewardsBonus) {
        var offerUUIDsSet = new Set(getOfferUUIDs());
        for (var i = 0; i < merchantData.rewardDetails.rewardsBonus.length; i++) {
            var rewardsBonus = merchantData.rewardDetails.rewardsBonus[i];
            if (rewardsBonus.offerConfigUUIDs) {
                for (var j = 0; j < rewardsBonus.offerConfigUUIDs.length; j++) {
                    var offerUUID = rewardsBonus.offerConfigUUIDs[j];
                    if ((offerUUIDsSet.size === 0 && offerUUID === 'default') ||
                        offerUUIDsSet.has(offerUUID)) {
                        offerData.push({
                            title: rewardsBonus.title,
                            subtitle: formatRewardsBonus(rewardsBonus),
                            content: (_jsx("div", { dangerouslySetInnerHTML: { __html: rewardsBonus.items.text }, style: {
                                    color: colors.content.onSurfaceVariant,
                                } })),
                            badge: {
                                leadingIcon: Icons.timer,
                                children: language.limitedTimeOfferBadge,
                                color: 'var(--merchant-color)',
                                variant: 'primary',
                            },
                        });
                        break;
                    }
                }
            }
            else {
                offerData.push({
                    title: rewardsBonus.title,
                    subtitle: formatRewardsBonus(rewardsBonus),
                    content: (_jsx("div", { dangerouslySetInnerHTML: { __html: rewardsBonus.items.text }, style: {
                            color: colors.content.onSurfaceVariant,
                        } })),
                });
            }
        }
    }
    return offerData;
};
var getDisclosureLinks = function (productUUID, languageCode) {
    return {
        rewardsTermsAndConditions: merchantInfos[productUUID].rewardsTerms[languageCode],
        cca: merchantInfos[productUUID].cardholderAgreement[languageCode],
    };
};
export var useOfferPageProps = function (setOpenInStoreModal) {
    var _a = useState('enabled'), pageState = _a[0], setPageState = _a[1];
    var rewardsListItems = useRewardsTableData();
    var _b = useImpState(), appScreenState = _b.appScreenState, acceptScreenState = _b.acceptScreenState, appStatus = _b.appStatus, consumerInfo = _b.consumerInfo, language = _b.language, languageCode = _b.languageCode, merchantData = _b.merchantData, dispatch = _b.dispatch, navigate = _b.navigate, apiScreenState = _b.apiScreenState;
    var multiCardUser = useAppSelector(function (state) { return state.loginSelector.multiCardUser; });
    var redirectToLogin = function () {
        dispatch(setRedirectToLogin(true));
        redirectToAccountWithCookie(consumerInfo.auth.token, apiScreenState.deviceID, consumerInfo.consumerUUID, merchantData.productUUID, multiCardUser === true ? 'true' : 'false', true);
    };
    var inStoreOnboarding = getEmployeeId(merchantData.productUUID) || getStoreNumber(merchantData.productUUID);
    var _c = getDisclosureLinks(merchantData.productUUID, languageCode), rewardsTermsAndConditions = _c.rewardsTermsAndConditions, cca = _c.cca;
    var hasAcceptedOffer = Boolean(appStatus.productAccountUUID);
    var handlePrimaryAction = function () {
        setPageState('submitting');
        if (hasAcceptedOffer || acceptScreenState.cardDesignSelection) {
            ANALYTICS.logEvent(EVENT.OFFER_PAGE_CONTINUE_CLICKED);
            dispatch(setScreen(SCREEN.ACCEPT));
            navigate(URL.ACCEPT);
        }
        else {
            // accept offer
            ANALYTICS.logEvent(EVENT.OFFER_PAGE_ACCEPT_OFFER_CLICKED);
            dispatch(confirm({
                token: consumerInfo.auth.token,
                applicationUUID: consumerInfo.auth.applicationUUID,
                sendSms: appScreenState.inStoreDevice,
                onSuccess: function () {
                    if (appScreenState.inStoreDevice) {
                        dispatch(setScreen(SCREEN.CREATE_PIN));
                        navigate(PAGE.CREATE_PIN);
                    }
                    else if (inStoreOnboarding)
                        redirectToLogin();
                    else {
                        sendMessageToHost(SdkEvent.acceptedOffer);
                        dispatch(setScreen(SCREEN.ACCEPT));
                        navigate(URL.ACCEPT);
                    }
                },
            }));
        }
    };
    var handleSecondaryAction = function () {
        ANALYTICS.logEvent(EVENT.OFFER_NO_THANKS_CLICKED);
    };
    var handleLeaveOfferClick = function (inStoreDevice, productUUID) {
        sendMessageToHost(SdkEvent.actionRequired);
        clearHomePageState(dispatch);
        inStoreDevice ? navigateToLP(productUUID) : navigate(PAGE.HOME);
    };
    function getPrimaryButtonLabel() {
        if (hasAcceptedOffer) {
            // User has already accepted the offer; show a different button label if they navigated back to this screen
            return language.continueBtn;
        }
        else if (acceptScreenState.cardDesignSelection) {
            // if merchant has card design selection, wording and action change
            return language.acceptAndContinue;
        }
        else {
            return language.acceptOfferBtn;
        }
    }
    var offerPageProps = {
        display: {
            title: "".concat(language.offerIntro, " ").concat(merchantData.rewardDetails.cardName),
            displayImage: merchantData.cardOfferImg,
            pageState: pageState,
        },
        info: {
            creditLimit: {
                title: formatDisplayAmount(consumerInfo.cardOffer.limit),
                subtitle: language.creditLimit,
                showIcon: true,
            },
            secondHeaderItem: {
                title: formatDisplayAmount(consumerInfo.cardOffer.apr, true),
                subtitle: language.apr,
                showIcon: true,
            },
            rewardsList: {
                title: language.yourRewards,
                items: rewardsListItems,
            },
            feesList: {
                title: language.fees + '<sup>1</sup>',
                items: getFeeTableData(consumerInfo, language),
            },
            actionDisclaimer: hasAcceptedOffer
                ? language.alreadyAcceptedOffer
                : language.affectCreditScoreStr,
            feesDisclosure: (_jsxs(_Fragment, { children: [_jsx(ImpSpacer, { height: "m" }), _jsx(ImpBulletList, { items: [
                            _jsxs(ImpText, __assign({ typography: "body3", color: colors.content.onSurfaceVariant }, { children: [language.offerDisclosure1, _jsx(ImpInlineLink, __assign({ url: rewardsTermsAndConditions, color: "var(--merchant-color)" }, { children: language.offerDisclosure2 })), language.offerDisclosure3, _jsx(ImpInlineLink, __assign({ url: consumerInfo.cardOffer.cardholderAgreementURL || cca, color: "var(--merchant-color)" }, { children: language.offerDisclosure4 })), language.offerDisclosure5] })),
                        ], type: "numeric", bulletStyle: {
                            color: colors.content.onSurfaceVariant,
                            typography: 'body3',
                        } })] })),
        },
        modals: {
            edu: {
                creditLimitTitle: language.creditLimit,
                creditLimitSubtitle: language.creditLimitDefinition,
                aprTitle: language.apr,
                aprSubtitle: language.aprDefinition,
            },
            rejectionModal: {
                title: language.rejectOfferTitle,
                subtitle: language.rejectOfferSubtitle.replace('${1}', appStatus.expiresAt),
                primaryButton: {
                    text: language.rejectOfferPrimaryButtonText,
                    // onclick is managed by the modal within imprint-ui
                    onClick: function () { },
                },
                secondaryButton: {
                    text: language.rejectOfferSecondaryButtonText,
                    onClick: function () {
                        return handleLeaveOfferClick(appScreenState.inStoreDevice, merchantData.productUUID);
                    },
                },
            },
        },
        actions: __assign({ 
            // if merchant has card design selection, wording and action change
            primaryButton: {
                text: getPrimaryButtonLabel(),
                onClick: handlePrimaryAction,
            } }, (!hasAcceptedOffer && {
            secondaryButton: {
                text: language.noThanksBtn,
                onClick: handleSecondaryAction,
            },
        })),
    };
    return offerPageProps;
};
