export var setUtmSource = function (productUUID, utm) {
    if (!utm) {
        utm = document.referrer;
    }
    localStorage.setItem('utm_source_' + productUUID, utm);
};
export var getUtmSource = function (productUUID) {
    var utm = sessionStorage.getItem('utm_source');
    if (!utm) {
        utm = localStorage.getItem('utm_source_' + productUUID);
    }
    return utm;
};
var PARAM_STORE_NUMBER = 'store_number';
var PARAM_EMPLOYEE_ID = 'employee_id';
export function setInStoreParams(productUUID) {
    var storeNum = sessionStorage.getItem(PARAM_STORE_NUMBER);
    storeNum && localStorage.setItem(PARAM_STORE_NUMBER + productUUID, storeNum);
    var employeeId = sessionStorage.getItem(PARAM_EMPLOYEE_ID);
    employeeId && localStorage.setItem(PARAM_EMPLOYEE_ID + productUUID, employeeId);
}
export function getStoreNumber(productUUID) {
    var _a;
    return ((_a = sessionStorage.getItem(PARAM_STORE_NUMBER)) !== null && _a !== void 0 ? _a : localStorage.getItem(PARAM_STORE_NUMBER + productUUID));
}
export function getEmployeeId(productUUID) {
    var _a;
    return ((_a = sessionStorage.getItem(PARAM_EMPLOYEE_ID)) !== null && _a !== void 0 ? _a : localStorage.getItem(PARAM_EMPLOYEE_ID + productUUID));
}
