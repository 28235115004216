import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectConsumerInfo } from './consumerInfoSelectors';
import { selectFeatureFlags } from './featureFlagSelectors';
import { selectLanguage, selectLanguageCode } from './languageSelectors';
import { selectMerchantData } from './merchantDataSelectors';
import { selectApiScreenState, selectAppStatus, selectApplicationScreenSelector, selectConsumerProfile, selectLoginSelector, selectScreenState, } from './selectors';
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export var useAppDispatch = function () { return useDispatch(); };
export var useAppSelector = useSelector;
export var useImpState = function () {
    var language = useAppSelector(selectLanguage);
    var languageCode = useAppSelector(selectLanguageCode);
    var merchantData = useAppSelector(selectMerchantData);
    var featureFlags = useAppSelector(selectFeatureFlags);
    var consumerInfo = useAppSelector(selectConsumerInfo);
    var screenState = useAppSelector(selectScreenState);
    var appScreenState = useAppSelector(selectApplicationScreenSelector);
    var loginState = useAppSelector(selectLoginSelector);
    var appStatus = useAppSelector(selectAppStatus);
    var apiScreenState = useAppSelector(selectApiScreenState);
    var consumerProfile = useAppSelector(selectConsumerProfile);
    var acceptScreenState = useAppSelector(function (state) { return state.acceptScreenSelector; });
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    return {
        language: language,
        languageCode: languageCode,
        merchantData: merchantData,
        featureFlags: featureFlags,
        consumerInfo: consumerInfo,
        screenState: screenState,
        appScreenState: appScreenState,
        loginState: loginState,
        appStatus: appStatus,
        apiScreenState: apiScreenState,
        consumerProfile: consumerProfile,
        acceptScreenState: acceptScreenState,
        dispatch: dispatch,
        navigate: navigate,
    };
};
