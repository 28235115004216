// client keys
export var statsigSandboxKey = 'client-ySFqbVNlH2cZg13P87ICu9FzBldwkBAkCAi5nGlOY7x';
export var statsigConsumerUUIDKey = 'client-R0WMjlq9MzzhOA3VPESk7MaIcfJhfBZviD04J0hHB45';
export var statsigEnvironment = (function () {
    var url = new URL(window.location.href);
    var domain = url.hostname;
    var environment;
    if (domain === 'apply.imprint.co') {
        environment = 'production';
    }
    else if (domain === 'apply.stg.imprintapi.co') {
        environment = 'staging';
    }
    else {
        environment = 'development';
    }
    return { tier: environment };
})();
