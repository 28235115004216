import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpConfirmAddressPage, ImpSpacer } from '@imprint-payments/imprint-ui';
import { useEffect } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { requestPhysicalCard } from '../../api/api';
import { setConfirmAddressLater, setRedirectToLogin } from '../../app/acceptScreenSlice';
import { useAppSelector, useImpState } from '../../app/hooks';
import { ANALYTICS } from '../../firebase/firebase';
import useNextPage from '../../hooks/nextPage';
import CardDesignSelectionFlow from '../../pages/cardDesign/CardDesign';
import { handleRedirectCookie, redirectTo, redirectToAccountWithCookie, } from '../../utils/functionUtils';
import Modal from '../modal/Modal';
import Spinner from '../spinner/Spinner';
export default function CardLater() {
    var nextPage = useNextPage();
    var _a = useImpState(), language = _a.language, merchantData = _a.merchantData, screenState = _a.screenState, consumerInfo = _a.consumerInfo, apiScreenState = _a.apiScreenState, appStatus = _a.appStatus, acceptScreenState = _a.acceptScreenState, dispatch = _a.dispatch, navigate = _a.navigate;
    var productUUID = useAppSelector(function (state) { return state.merchantDataSelector.productUUID; });
    var multiCardUser = useAppSelector(function (state) { return state.loginSelector.multiCardUser; });
    useEffect(function () {
        nextPage();
    }, [screenState.screen]);
    var handleConfirmAddress = function () {
        var isMultiCard = multiCardUser === true ? 'true' : 'false';
        ANALYTICS.logEvent(EVENT.CONFIRM_ADDRESS_CONFIRM_CLICKED);
        dispatch(requestPhysicalCard(consumerInfo.auth.token, appStatus.productAccountUUID)).then(function () {
            redirectToAccountWithCookie(consumerInfo.auth.token, apiScreenState.deviceID, consumerInfo.consumerUUID, productUUID, isMultiCard);
        });
    };
    var handleNotNow = function () {
        ANALYTICS.logEvent(EVENT.CONFIRM_ADDRESS_NOT_NOW_CLICKED);
        dispatch(setConfirmAddressLater(true));
    };
    var handleSoundsGood = function () {
        var isMultiCard = multiCardUser === true ? 'true' : 'false';
        ANALYTICS.logEvent(EVENT.CONFIRM_ADDRESS_LATER_SOUNDS_GOOD_CLICKED);
        handleRedirectCookie(consumerInfo.auth.token, apiScreenState.deviceID, consumerInfo.consumerUUID, productUUID, isMultiCard);
        dispatch(setConfirmAddressLater(false));
        dispatch(setRedirectToLogin(true));
        redirectTo('account');
    };
    function getAddress() {
        var address = consumerInfo.consumerProfile.address;
        return {
            line1: address.streetLine1,
            line2: address.streetLine2,
            line3: "".concat(address.city, ", ").concat(address.state, " ").concat(address.zipCode),
        };
    }
    return (_jsx(_Fragment, { children: acceptScreenState.confirmLater ? (_jsx(Modal, { header: language.cardLater, body1: language.requestCardLater, buttonName1: language.soundsGood, pillBtn1: true, button1Click: handleSoundsGood })) : acceptScreenState.redirectToLogin ? (_jsx(Modal, { header: language.redirectToNewAccountLogin, body1: language.continueToRedirect, buttonName1: language.continueBtn, pillBtn1: true, button1Click: function () { return redirectTo('account'); } })) : acceptScreenState.cardDesignSelection ? (_jsx(CardDesignSelectionFlow, {})) : appStatus.productAccountUUID === '' ? (_jsx(Spinner, {})) : (_jsxs(_Fragment, { children: [_jsx(ImpSpacer, { height: "2xl", mobileHeight: "xl" }), _jsx(ImpConfirmAddressPage, { pageState: 'enabled', displayImage: merchantData.cardOfferImg, title: language.acceptedTitle, subtitle: language.shipToNewAddress, displayText: getAddress(), primaryActionButtonInfo: {
                        text: language.confirmAddressBtn,
                        onClick: handleConfirmAddress,
                    }, secondaryActionButtonInfo: {
                        text: language.notNowBtn,
                        onClick: handleNotNow,
                    } })] })) }));
}
