import { PKG } from "../../analytics/analyticsConsts";
import { REAPPLICATION_ACCOUNT_STATUS, setOfferPageForCard } from "../../api/api";
import { AppHistory } from "../../app/History";
import { setLoading } from "../../app/applicationScreenSlice";
import { setApplicationUUID } from "../../app/applicationStatusSlice";
import { prefillConsumerProfileFromHome } from "../../app/consumerInfoSlice";
import { SCREEN, setScreen } from "../../app/screenStateSlice";
import { store } from "../../app/store";
import { ANALYTICS } from "../../firebase/firebase";
import { AccountStatus, ActionType } from "../../types";
import { reApplicationSetUpAfterHome } from "../../utils/functionUtils";
import { PAGE } from "../../utils/routeConstant";
var handlePendingOffer = function (dispatch, productUUID, applicationUUID) {
    var _a;
    dispatch(setOfferPageForCard(productUUID, applicationUUID));
    dispatch(setScreen(SCREEN.OFFER));
    (_a = AppHistory.navigate) === null || _a === void 0 ? void 0 : _a.call(AppHistory, PAGE.OFFER);
};
var handleRejected = function (dispatch, action, canApply) {
    if (action.params.applicationUUID) {
        dispatch(setApplicationUUID(action.params.applicationUUID));
    }
    if (canApply === true) {
        reApplicationSetUpAfterHome(dispatch);
    }
    else {
        dispatch(setScreen(SCREEN.APPLICATION_NOT_APPROVED));
    }
};
var parseActions = function (actions) {
    var _a;
    var currMerchantProductUUID = store.getState().merchantDataSelector.productUUID;
    var homeResponse = store.getState().homeSelector;
    var dispatch = store.dispatch;
    var hasMerchantAction = false;
    for (var _i = 0, actions_1 = actions; _i < actions_1.length; _i++) {
        var action = actions_1[_i];
        if (!action.params || !action.type) {
            continue;
        }
        var productUUID = action.params.productUUID;
        if (productUUID !== currMerchantProductUUID) {
            continue;
        }
        hasMerchantAction = true;
        switch (action.type) {
            case ActionType.ShowExpiredApplicationScreen:
                reApplicationSetUpAfterHome(dispatch, homeResponse, REAPPLICATION_ACCOUNT_STATUS.EXPIRED_OFFER);
                dispatch(setScreen(SCREEN.APPLICATION));
                break;
            case ActionType.ShowOfferScreen:
                handlePendingOffer(dispatch, action.params.productUUID, action.params.applicationUUID);
                break;
            case ActionType.ShowRejectionScreen:
                handleRejected(dispatch, action, ((_a = homeResponse.creditAccount) === null || _a === void 0 ? void 0 : _a.canApply) === true);
                break;
            default:
                ANALYTICS.funcError(PKG, "parseActions", "unexpected action type: ".concat(action.type));
                dispatch(setScreen(SCREEN.ACCOUNT_PENDING));
                break;
        }
    }
    return hasMerchantAction;
};
var parseProductAccounts = function (productAccounts) {
    var _a, _b;
    var hasProductAccount = false;
    var merchantData = store.getState().merchantDataSelector;
    var dispatch = store.dispatch;
    var currMerchantProductUUID = merchantData.productUUID;
    for (var _i = 0, productAccounts_1 = productAccounts; _i < productAccounts_1.length; _i++) {
        var productAccount = productAccounts_1[_i];
        var productUUID = productAccount.productUUID, productAccountStatus = productAccount.productAccountStatus;
        if (productUUID !== currMerchantProductUUID) {
            continue;
        }
        hasProductAccount = true;
        switch (productAccountStatus) {
            case AccountStatus.Active:
                dispatch(setScreen(SCREEN.APPLICATION_ACTIVE_USER));
                break;
            case AccountStatus.ManualReview:
                dispatch(setScreen(SCREEN.APPLICATION_IN_REVIEW));
                break;
            case AccountStatus.Frozen:
                reApplicationSetUpAfterHome(dispatch);
                dispatch(setScreen(SCREEN.CREDIT_FROZEN));
                break;
            case AccountStatus.OfferPending:
                if (!((_a = productAccount.params) === null || _a === void 0 ? void 0 : _a.applicationUUID)) {
                    // what should we do here? is this possible?
                    continue;
                }
                handlePendingOffer(dispatch, productAccount.productAccountUUID, (_b = productAccount.params) === null || _b === void 0 ? void 0 : _b.applicationUUID);
                break;
            default:
                ANALYTICS.funcError(PKG, "parseProductAccounts", "unexpected status: ".concat(productAccountStatus));
                dispatch(setScreen(SCREEN.ACCOUNT_PENDING));
                break;
        }
    }
    return hasProductAccount;
};
export var handleLoadHomeSuccess = function (_a) {
    var isReapplication = _a.isReapplication;
    var home = store.getState().homeSelector;
    var dispatch = store.dispatch;
    // prefill
    dispatch(prefillConsumerProfileFromHome(home.userInformation));
    if (isReapplication) {
        return;
    }
    var result = false;
    // parse actions to see if offer is pending
    if (home.actions) {
        result = parseActions(home.actions);
    }
    // parse product accounts to see if offer is pending
    if (home.productAccounts) {
        result = parseProductAccounts(home.productAccounts);
    }
    // only want to run these if nothing happened in parseActions or parseProductAccounts
    if (!result) {
        reApplicationSetUpAfterHome(dispatch);
        dispatch(setScreen(SCREEN.APPLICATION));
        dispatch(setLoading(false));
    }
};
