import { statsigConsumerUUIDKey, statsigEnvironment } from './const';
import { StatsigClient } from '@statsig/js-client';
import { useMemo, useState } from 'react';
// https://docs.statsig.com/client/javascript-sdk/react#asynchronous-initialization-network
export default function useAsyncClient() {
    var _a = useState(true), isLoading = _a[0], setIsLoading = _a[1];
    var client = useMemo(function () {
        var instance = new StatsigClient(statsigConsumerUUIDKey, {}, {
            environment: statsigEnvironment,
            customUserCacheKeyFunc: function (key, user) {
                var _a, _b;
                return key + user.userID + ((_a = user.customIDs) === null || _a === void 0 ? void 0 : _a.stableID) + ((_b = user.custom) === null || _b === void 0 ? void 0 : _b.productUUID);
            },
        });
        instance
            .initializeAsync()
            .catch(function (err) {
            console.error(err);
        })
            .finally(function () { return setIsLoading(false); });
        return instance;
    }, []);
    return { client: client, isLoading: isLoading };
}
