import { useStatsigUser } from '@statsig/react-bindings';
import { useEffect } from 'react';
import { useImpState } from '../app/hooks';
export default function useStatsigUserUpdaters() {
    var updateUserSync = useStatsigUser().updateUserSync;
    var _a = useImpState(), consumerInfo = _a.consumerInfo, merchantData = _a.merchantData;
    useEffect(function () {
        updateUserSync(function (user) {
            user.custom = {
                'productUUID': merchantData.productUUID,
            };
            return user;
        });
    }, [merchantData.productUUID]);
    useEffect(function () {
        updateUserSync(function (user) {
            user.userID = consumerInfo.consumerUUID;
            return user;
        });
    }, [consumerInfo.consumerUUID]);
}
