var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ImpBulletList, ImpInlineLink, ImpText } from '@imprint-payments/imprint-ui';
import { EVENT } from '../../analytics/analyticsConsts';
import { URL } from '../../api/apiConstants';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { selectMerchantData } from '../../app/merchantDataSelectors';
import { ANALYTICS } from '../../firebase/firebase';
import { LanguageCode } from '../../utils/languageConstants';
import { merchantInfos, PRODUCTUUID } from '../../utils/productUUIDConstants';
export function Terms() {
    var language = useAppSelector(selectLanguage);
    var productUUID = useAppSelector(selectMerchantData).productUUID;
    var textStyle = {
        color: '#A7A6A4',
        typography: 'body3',
        handleSuperscript: true,
    };
    var languageCode = language.selected;
    var cca_link = merchantInfos[productUUID].cardholderAgreement[languageCode];
    var terms = merchantInfos[productUUID].rewardsTerms[languageCode];
    var privacy_link = URL.IMPRINT_PRIVACY;
    var esign_link = URL.IMPRINT_ESIGN;
    var cisn = merchantInfos[productUUID].infoSharingNotice[languageCode];
    function incomeHousingIDTerms4Wording(productUUID) {
        if (productUUID === PRODUCTUUID.HH) {
            return language.incomeHousingIDTerms4HH;
        }
        if (productUUID === PRODUCTUUID.WG) {
            return language.incomeHousingIDTerms4WG;
        }
        if (productUUID === PRODUCTUUID.HEB) {
            return language.incomeHousingIDTerms4HEB;
        }
        if (productUUID === PRODUCTUUID.CM) {
            return language.incomeHousingIDTerms4CM;
        }
        if (productUUID === PRODUCTUUID.TURKISH) {
            return language.incomeHousingIDTerms4Turkish;
        }
        if (productUUID === PRODUCTUUID.BB) {
            return language.incomeHousingIDTerms4BB;
        }
        if (productUUID === PRODUCTUUID.EB) {
            return language.incomeHousingIDTerms4EB;
        }
        //TODO: config any new merchants here
        return language.incomeHousingIDTerms4;
    }
    return (_jsxs(_Fragment, { children: [_jsx(ImpBulletList, { type: "numeric", items: [
                    _jsxs(ImpText, __assign({}, textStyle, { handleSuperscript: true }, { children: [language.incomeHousingIDTerms1, _jsx(ImpInlineLink, __assign({ color: "var(--merchant-color)", url: cca_link, openInNewTab: true, onClick: function () {
                                    return ANALYTICS.logEvent(EVENT.INCOME_HOUSING_LINK_CLICKED, {
                                        link: 'terms_of_service',
                                    });
                                } }, { children: language.incomeHousingIDLink1 })), language.incomeHousingIDTerms2, _jsx(ImpInlineLink, __assign({ color: "var(--merchant-color)", url: terms, openInNewTab: true, onClick: function () {
                                    return ANALYTICS.logEvent(EVENT.INCOME_HOUSING_LINK_CLICKED, {
                                        link: 'rewards_terms_and_conditions',
                                    });
                                } }, { children: language.incomeHousingIDLink5 })), language.incomeHousingIDTerms2, _jsx(ImpInlineLink, __assign({ color: "var(--merchant-color)", url: privacy_link, openInNewTab: true, onClick: function () {
                                    return ANALYTICS.logEvent(EVENT.INCOME_HOUSING_LINK_CLICKED, {
                                        link: 'privacy_policy',
                                    });
                                } }, { children: language.incomeHousingIDLink2 })), language.incomeHousingIDTerms2, _jsx(ImpInlineLink, __assign({ color: "var(--merchant-color)", url: esign_link, openInNewTab: true, onClick: function () {
                                    return ANALYTICS.logEvent(EVENT.INCOME_HOUSING_LINK_CLICKED, {
                                        link: 'e-sign_policy',
                                    });
                                } }, { children: language.incomeHousingIDLink3 })), language.incomeHousingIDTerms3, _jsx(ImpInlineLink, __assign({ color: "var(--merchant-color)", url: cisn, openInNewTab: true, onClick: function () {
                                    return ANALYTICS.logEvent(EVENT.INCOME_HOUSING_LINK_CLICKED, {
                                        link: 'cardholder_information_sharing_notice',
                                    });
                                } }, { children: language.incomeHousingIDLink4 })), incomeHousingIDTerms4Wording(productUUID)] })),
                ], bulletStyle: textStyle }), language.selected === LanguageCode.Spanish && (_jsx(ImpText, __assign({ typography: "body3", color: "#A7A6A4" }, { children: language.incomeHousingIDLanguageDisclosure })))] }));
}
