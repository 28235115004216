import { Method, RequestType, RETRY_CONFIG } from './http/httpConsts';
var URL = {
    LOAD_HOME: '/v2/home',
    CREATE_PIN: '/v1/consumer/pin',
    VERIFY_PIN: '/v1/consumer/mfa/pin',
    CHECKOUT: '/v2/consumer/checkout',
};
export var RequestName;
(function (RequestName) {
    RequestName["LOAD_HOME"] = "LOAD_HOME";
    RequestName["CREATE_PIN"] = "CREATE_PIN";
    RequestName["VERIFY_PIN"] = "VERIFY_PIN";
})(RequestName || (RequestName = {}));
export var API_CONFIG = {
    LOAD_HOME: {
        baseUrl: URL.LOAD_HOME,
        method: Method.GET,
        retryCount: RETRY_CONFIG[Method.GET],
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    CREATE_PIN: {
        baseUrl: URL.CREATE_PIN,
        method: Method.POST,
        retryCount: RETRY_CONFIG[Method.POST],
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    VERIFY_PIN: {
        baseUrl: URL.VERIFY_PIN,
        method: Method.POST,
        retryCount: RETRY_CONFIG[Method.POST],
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
};
