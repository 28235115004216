var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { LanguageCode } from './languageConstants';
import { DocumentUrl, MerchantQRInfo, ZendeskUrl } from './merchantLinks';
import { PAGE } from './routeConstant';
export var PRODUCTUUID = {
    HH: 'PRDT-v1-785c001c-a9b1-4fc2-87d7-2d0b1060e3ed',
    WG: 'PRDT-v1-a0b163f2-21b1-4fcc-8106-660b6c456c71',
    HEB: 'PRDT-v1-e7a1d3ba-da09-45e5-ab1b-f822cd01b3bf',
    CM: 'PRDT-v1-d7a7bcc2-a2cd-41fe-91d2-b6d355b3f68f',
    HICV: 'PRDT-v1-610f2cec-ac8b-4f00-abd2-83dbd74982da',
    TURKISH: 'PRDT-v1-ed952893-358b-4a70-b4bc-24fac624d5aa',
    EB: 'PRDT-v1-13844060-10f9-481b-bbd8-ce554f70422a',
    BB: 'PRDT-v1-028a15e5-acd2-4bfe-959a-19558e2894b6',
    BOOKING: 'PRDT-v1-a0ef33e9-5976-4e56-aed1-2d0d7cf940d5',
    CRATE_BARREL: 'PRDT-v1-30763e2e-7278-43ca-82b0-cbf6cf7351bb',
};
// The keyword contained in url
export var MERCHANT_KEY;
(function (MERCHANT_KEY) {
    MERCHANT_KEY["HEB"] = "heb";
    MERCHANT_KEY["CM"] = "centralmarket";
    MERCHANT_KEY["HH"] = "horizonhobby";
    MERCHANT_KEY["WG"] = "westgate";
    MERCHANT_KEY["HICV"] = "holidayinnclub";
    MERCHANT_KEY["TURKISH"] = "turkishairlines";
    MERCHANT_KEY["EB"] = "eddiebauer";
    MERCHANT_KEY["BB"] = "brooksbrothers";
    MERCHANT_KEY["BOOKING"] = "booking";
    MERCHANT_KEY["CRATE_BARREL"] = "crateandbarrel";
})(MERCHANT_KEY || (MERCHANT_KEY = {}));
function urlPair(en, es) {
    var _a;
    return _a = {},
        _a[LanguageCode.English] = en,
        _a[LanguageCode.Spanish] = es,
        _a;
}
var merchantList = {
    HH: {
        productUUID: PRODUCTUUID.HH,
        cardholderAgreement: urlPair(DocumentUrl.HH_CHA_EN, DocumentUrl.HH_CHA_ES),
        infoSharingNotice: urlPair(DocumentUrl.HH_CISN_EN, DocumentUrl.HH_CISN_ES),
        rewardsTerms: urlPair(DocumentUrl.HH_TERMS_EN, DocumentUrl.HH_TERMS_ES),
        zendesk: urlPair(ZendeskUrl.HH_EN, ZendeskUrl.HH_ES),
        displaySignupLink: 'imprint.co/hh',
        annualFee: '$0',
        qrInfo: MerchantQRInfo.HH,
        pageToNavigate: PAGE.HH_CREDIT,
    },
    WG: {
        productUUID: PRODUCTUUID.WG,
        cardholderAgreement: urlPair(DocumentUrl.WG_CHA_EN, DocumentUrl.WG_CHA_ES),
        infoSharingNotice: urlPair(DocumentUrl.WG_CISN_EN, DocumentUrl.WG_CISN_ES),
        rewardsTerms: urlPair(DocumentUrl.WG_TERMS_EN, DocumentUrl.WG_TERMS_ES),
        zendesk: urlPair(ZendeskUrl.WG_EN, ZendeskUrl.WG_ES),
        displaySignupLink: 'imprint.co/wow',
        annualFee: '$0',
        qrInfo: MerchantQRInfo.WG,
        pageToNavigate: PAGE.WESTGATE,
    },
    HEB: {
        productUUID: PRODUCTUUID.HEB,
        cardholderAgreement: urlPair(DocumentUrl.HEB_CHA_EN, DocumentUrl.HEB_CHA_ES),
        infoSharingNotice: urlPair(DocumentUrl.HEB_CISN_EN, DocumentUrl.HEB_CISN_ES),
        rewardsTerms: urlPair(DocumentUrl.HEB_TERMS_EN, DocumentUrl.HEB_TERMS_ES),
        zendesk: urlPair(ZendeskUrl.HEB_EN, ZendeskUrl.HEB_ES),
        displaySignupLink: 'imprint.co/heb',
        annualFee: '$0',
        pageToNavigate: PAGE.HEB,
    },
    CM: {
        productUUID: PRODUCTUUID.CM,
        cardholderAgreement: urlPair(DocumentUrl.CM_CHA_EN, DocumentUrl.CM_CHA_ES),
        infoSharingNotice: urlPair(DocumentUrl.CM_CISN_EN, DocumentUrl.CM_CISN_ES),
        rewardsTerms: urlPair(DocumentUrl.CM_TERMS_EN, DocumentUrl.CM_TERMS_ES),
        zendesk: urlPair(ZendeskUrl.CM_EN, ZendeskUrl.CM_ES),
        displaySignupLink: 'imprint.co/cm',
        annualFee: '$0',
        pageToNavigate: PAGE.CM,
    },
    HICV: {
        productUUID: PRODUCTUUID.HICV,
        cardholderAgreement: urlPair(DocumentUrl.HICV_CHA_EN, DocumentUrl.HICV_CHA_ES),
        infoSharingNotice: urlPair(DocumentUrl.HICV_CISN_EN, DocumentUrl.HICV_CISN_ES),
        rewardsTerms: urlPair(DocumentUrl.HICV_TERMS_EN, DocumentUrl.HICV_TERMS_ES),
        zendesk: urlPair(ZendeskUrl.HICV_EN, ZendeskUrl.HICV_ES),
        displaySignupLink: 'imprint.co/hicv',
        annualFee: '$0',
        pageToNavigate: PAGE.HICV,
    },
    TURKISH: {
        productUUID: PRODUCTUUID.TURKISH,
        cardholderAgreement: urlPair(DocumentUrl.TURKISH_CHA_EN, DocumentUrl.TURKISH_CHA_ES),
        infoSharingNotice: urlPair(DocumentUrl.TURKISH_CISN_EN, DocumentUrl.TURKISH_CISN_ES),
        rewardsTerms: urlPair(DocumentUrl.TURKISH_TERMS_EN, DocumentUrl.TURKISH_TERMS_ES),
        zendesk: urlPair(ZendeskUrl.TURKISH_EN, ZendeskUrl.TURKISH_ES),
        displaySignupLink: 'imprint.co/turkishairlines',
        annualFee: '$99',
        pageToNavigate: PAGE.TURKISH,
    },
    EB: {
        productUUID: PRODUCTUUID.EB,
        cardholderAgreement: urlPair(DocumentUrl.EB_CHA_EN, DocumentUrl.EB_CHA_ES),
        infoSharingNotice: urlPair(DocumentUrl.EB_CISN_EN, DocumentUrl.EB_CISN_ES),
        rewardsTerms: urlPair(DocumentUrl.EB_TERMS_EN, DocumentUrl.EB_TERMS_ES),
        zendesk: urlPair(ZendeskUrl.EB_EN, ZendeskUrl.EB_ES),
        displaySignupLink: '',
        annualFee: '$0',
        pageToNavigate: PAGE.EB,
    },
    BB: {
        productUUID: PRODUCTUUID.BB,
        cardholderAgreement: urlPair(DocumentUrl.BB_CHA_EN, DocumentUrl.BB_CHA_ES),
        infoSharingNotice: urlPair(DocumentUrl.BB_CISN_EN, DocumentUrl.BB_CISN_ES),
        rewardsTerms: urlPair(DocumentUrl.BB_TERMS_EN, DocumentUrl.BB_TERMS_ES),
        zendesk: urlPair(ZendeskUrl.BB_EN, ZendeskUrl.BB_ES),
        displaySignupLink: '',
        annualFee: '$0',
        pageToNavigate: PAGE.BB,
    },
    BOOKING: {
        productUUID: PRODUCTUUID.BOOKING,
        cardholderAgreement: urlPair(DocumentUrl.DEMO_TERMS, DocumentUrl.DEMO_TERMS),
        infoSharingNotice: urlPair(DocumentUrl.DEMO_CISN, DocumentUrl.DEMO_CISN),
        rewardsTerms: urlPair(DocumentUrl.DEMO_TERMS, DocumentUrl.DEMO_TERMS),
        zendesk: urlPair(ZendeskUrl.DEFAULT, ZendeskUrl.DEFAULT),
        displaySignupLink: '',
        annualFee: '$0',
        pageToNavigate: PAGE.BOOKING,
    },
    CRATE_BARREL: {
        productUUID: PRODUCTUUID.CRATE_BARREL,
        cardholderAgreement: urlPair(DocumentUrl.DEMO_TERMS, DocumentUrl.DEMO_TERMS),
        infoSharingNotice: urlPair(DocumentUrl.DEMO_CISN, DocumentUrl.DEMO_CISN),
        rewardsTerms: urlPair(DocumentUrl.DEMO_TERMS, DocumentUrl.DEMO_TERMS),
        zendesk: urlPair(ZendeskUrl.DEFAULT, ZendeskUrl.DEFAULT),
        displaySignupLink: '',
        annualFee: '$0',
        pageToNavigate: PAGE.CRATE_BARREL,
    },
};
// Can be called by `merchantInfos[productUUID]`
export var merchantInfos = __assign(__assign({}, merchantList), Object.fromEntries(Object.values(merchantList).map(function (merchant) { return [merchant.productUUID, merchant]; })));
// Used for SDK
export var getProductUUIDFromPartnerKey = function (partnerKey) {
    //TODO:[WS] Hard code for Booking now. Should be dependent on `partner-key`
    return PRODUCTUUID.BOOKING;
};
