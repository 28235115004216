var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { BreakpointsPx, ImpHidden, ImpSpacer } from '@imprint-payments/imprint-ui';
import { useEffect } from 'react';
import { useImpState } from '../../app/hooks';
import { HeaderHandler } from '../../components/HeaderHandler/HeaderHandler';
import Application from '../../components/application/Application';
import useNextPage from '../../hooks/nextPage';
import { COOKIEKEY } from '../../utils/cookieConstants';
import { getCookieValueFor } from '../../utils/functionUtils';
import { handleRedirectedFromServicing } from './ApplicationHomeUtils';
export default function ApplicationHome() {
    var nextPage = useNextPage();
    var _a = useImpState(), screenState = _a.screenState, dispatch = _a.dispatch;
    useEffect(function () {
        nextPage();
    }, [screenState.screen]);
    useEffect(function () {
        if (getCookieValueFor(COOKIEKEY.REAPPLICATION) === 'true') {
            dispatch(handleRedirectedFromServicing());
        }
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(HeaderHandler, { showRewardsButton: true }), _jsx(ImpHidden, __assign({ hideBelow: BreakpointsPx.Application }, { children: _jsx(ImpSpacer, { height: "3xl" }) })), _jsx(Application, {})] }));
}
