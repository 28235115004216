var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Icons, ImpButton, ImpContainer, ImpHidden, ImpInlineLink, ImpModal, ImpText, colors, useModal, } from '@imprint-payments/imprint-ui';
import { BreakpointsPx } from '@imprint-payments/imprint-ui';
import { EVENT } from '../../analytics/analyticsConsts';
import { ENV } from '../../app/apiScreenSlice';
import { useImpState } from '../../app/hooks';
import { ANALYTICS } from '../../firebase/firebase';
import { getEnv } from '../../utils/environments';
import RewardsModal from './RewardsModal';
import { getOfferUUIDs } from '../../utils/functionUtils';
export var getAccountLink = function (domain) {
    var toNavigate = 'https://account.imprint.co';
    var env = getEnv(domain)[0];
    if (env === ENV.STG || env === ENV.LOCAL) {
        toNavigate = 'https://account.stg.imprintapi.co';
    }
    return toNavigate;
};
export function TrailingContent(_a) {
    var _b = _a.showRewardsButton, showRewardsButton = _b === void 0 ? false : _b;
    var breakpointToggle = BreakpointsPx.Application;
    return (_jsxs(_Fragment, { children: [_jsx(AlreadyAppliedText, { breakpoint: breakpointToggle }), showRewardsButton && _jsx(RewardsText, { breakpoint: breakpointToggle })] }));
}
function AlreadyAppliedText(_a) {
    var breakpoint = _a.breakpoint;
    var language = useImpState().language;
    return (_jsx(ImpContainer, __assign({ textAlign: "right" }, { children: _jsx(ImpHidden, __assign({ hideBelow: breakpoint }, { children: _jsx(ImpText, __assign({ typography: "body3", color: colors.content.onBackgroundVariant }, { children: _jsx(ImpInlineLink, __assign({ url: getAccountLink(window.location.hostname.toLowerCase()), color: "var(--merchant-color)", onClick: function () {
                        return ANALYTICS.logEvent(EVENT.APPLICATION_ALREADY_APPLIED_CLICKED);
                    } }, { children: language.checkApplicationStatusHeader })) })) })) })));
}
function RewardsText(_a) {
    var breakpoint = _a.breakpoint;
    var language = useImpState().language;
    var _b = useModal(false), isRewardsModalOpen = _b[0], isRewardsModalClosing = _b[1], openRewardsModal = _b[2], closeRewardsModal = _b[3];
    return (_jsxs(ImpContainer, __assign({ textAlign: "right" }, { children: [_jsx(ImpHidden, __assign({ hideAbove: breakpoint }, { children: _jsx(ImpButton, __assign({ buttonStyle: 'color', variant: 'text', color: 'var(--merchant-color)', onClick: function () {
                        var _a;
                        openRewardsModal();
                        ANALYTICS.logEvent(EVENT.APPLICATION_REWARDS_CLICKED, {
                            offer_config_uuids: ((_a = getOfferUUIDs()) !== null && _a !== void 0 ? _a : []).join(','),
                        });
                    }, leadingIcon: Icons.gift }, { children: language.rewards })) })), _jsx(ImpModal, __assign({ isOpen: isRewardsModalOpen, isClosing: isRewardsModalClosing, closeModal: function () {
                    closeRewardsModal();
                }, stickyHeader: true, headerText: language.rewards }, { children: _jsx(ImpContainer, __assign({ backgroundColor: colors.surface.surfaceMid, width: '100%' }, { children: _jsx(ImpContainer, __assign({ width: '100%', textAlign: "center" }, { children: _jsx(RewardsModal, {}) })) })) }))] })));
}
