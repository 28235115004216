var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ImpSpinner } from '@imprint-payments/imprint-ui';
import { useEffect, useRef, useState } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { callPrompt, consumerLogOut } from '../../api/api';
import { resetDeviceID } from '../../app/apiScreenSlice';
import { MODAL_ENUM, setLoading, setShowModal } from '../../app/applicationScreenSlice';
import { resetConsumerInfoSlice } from '../../app/consumerInfoSlice';
import { useAppSelector, useImpState } from '../../app/hooks';
import { setLoginFlow, setMultiCardUser } from '../../app/loginSlice';
import { setIncorrectOtpCode } from '../../app/otpScreenSlice';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import close from '../../assets/icons/close.svg';
import imprintLogo from '../../assets/icons/logo-imprint.svg';
import downloadAppQRCodeGeneric from '../../assets/images/downloadAppQRCode_generic.svg';
import { ANALYTICS } from '../../firebase/firebase';
import useNextPage from '../../hooks/nextPage';
import { formatDateValue, formatDob } from '../../utils/formatUtils';
import { clearServiceLoginState, redirectTo } from '../../utils/functionUtils';
import { KEYTYPE } from '../../utils/keyConstants';
import { validateBirthDate, validatePhoneNumber, ValidationResponse, } from '../../utils/validateUtils';
import ButtonServicing, { ButtonColorScheme } from '../buttonServicing/ButtonServicing';
import DynamicQRCode from '../dynamicQRCode/dynamicQRCode';
import OtpCardNew from '../otp/OtpCardNew';
import PINEntry from '../pinEntry/PINEntry';
import styles from './LoginModal.module.css';
import { useVerifyOtp } from '../../api/verifyOtp.api';
export default function LoginModal() {
    var nextPage = useNextPage();
    var _a = useImpState(), language = _a.language, merchantData = _a.merchantData, screenState = _a.screenState, consumerInfo = _a.consumerInfo, consumerProfile = _a.consumerProfile, loginState = _a.loginState, dispatch = _a.dispatch;
    var otpScreenState = useAppSelector(function (state) { return state.otpScreenSelector; });
    var applicationScreenState = useAppSelector(function (state) { return state.applicationScreenSelector; });
    var _b = useState(''), phoneNumber = _b[0], setPhoneNumber = _b[1];
    var _c = useState(consumerProfile.dob.day === 0 ? '' : String(consumerProfile.dob.day)), birthDayDay = _c[0], setBirthDayDay = _c[1];
    var _d = useState(consumerProfile.dob.month === 0 ? '' : String(consumerProfile.dob.month)), birthDayMonth = _d[0], setBirthDayMonth = _d[1];
    var _e = useState(consumerProfile.dob.year === 0 ? '' : String(consumerProfile.dob.year)), birthDayYear = _e[0], setBirthDayYear = _e[1];
    var bitrhDayMonthString = birthDayMonth === '' ? '' : language.months[Number(birthDayMonth) - 1];
    var birthDay = formatDob("".concat(formatDateValue(Number(birthDayMonth)), "/\n    ").concat(formatDateValue(Number(birthDayDay)), "/\n    ").concat(formatDateValue(Number(birthDayYear))));
    var _f = useState(false), disabledBtn = _f[0], setDisabledBtn = _f[1];
    var _g = useState(false), failedOtp = _g[0], setFailedOtp = _g[1];
    useAppSelector(function (state) { return state.validatorSelector; });
    var outerElementRef = useRef(null);
    useEffect(function () {
        dispatch(setMultiCardUser(true));
    }, []);
    useEffect(function () {
        if (loginState.loginFlow.dobError) {
            setBirthDayDay('');
            setBirthDayMonth('');
            setBirthDayYear('');
        }
    }, [loginState.loginFlow.dobError]);
    useEffect(function () {
        if (screenState.screen === SCREEN.LOGIN) {
            ANALYTICS.logEvent(EVENT.ENTER_PHONE_NUMBER_VIEWED);
        }
        else if (screenState.screen === SCREEN.DOB) {
            ANALYTICS.logEvent(EVENT.VERIFY_DOB_VIEWED);
        }
        else if (screenState.screen === SCREEN.PIN) {
            if (loginState.firstTimeUser) {
                ANALYTICS.logEvent(EVENT.CREATE_PIN_VIEWED);
            }
            else {
                ANALYTICS.logEvent(EVENT.ENTER_PIN_VIEWED);
            }
        }
        else if (screenState.screen === SCREEN.SERVICE_OTPSCREEN) {
            ANALYTICS.logEvent(EVENT.PHONE_NUMBER_RECOGNIZED_OTP_PAGE_VIEWED, {
                application_product_uuid: merchantData.productUUID,
            });
        }
        else if (screenState.screen === SCREEN.VERIFICATION_FAILED) {
            ANALYTICS.logEvent(EVENT.LOGIN_FAILED_VIEWED);
        }
        else if (screenState.screen === SCREEN.ACCOUNT_PENDING) {
            ANALYTICS.logEvent(EVENT.ISSUE_WITH_APPLICATION_PG_VIEWED, {
                application_product_uuid: merchantData.productUUID,
                context: 'user_recognized',
                consumer_uuid: consumerInfo.consumerUUID,
            });
        }
        else if (screenState.screen === SCREEN.INCOME_HOUSING_MODAL) {
            ANALYTICS.logEvent(EVENT.MULTI_CREDIT_APP_INCOME_HOUSING_PAGE_VIEWED, {
                application_product_uuid: merchantData.productUUID,
                context: 'user_recognized',
                consumer_uuid: consumerInfo.consumerUUID,
            });
        }
        nextPage();
    }, [screenState.screen]);
    useEffect(function () {
        if (loginState.loginFlow.dobError) {
            ANALYTICS.logEvent(EVENT.VERIFY_DOB_TRY_AGAIN_VIEWED);
        }
    }, [loginState.loginFlow.dobError]);
    useEffect(function () {
        setDisabledBtn(true);
        if (failedOtp ||
            (validatePhoneNumber(phoneNumber) === ValidationResponse.valid && phoneNumber !== '')) {
            setDisabledBtn(false);
        }
    }, [phoneNumber, failedOtp]);
    useEffect(function () {
        setDisabledBtn(true);
        if (screenState.screen == SCREEN.DOB &&
            validateBirthDate(birthDay) === ValidationResponse.valid &&
            birthDay !== '') {
            setDisabledBtn(false);
        }
    }, [birthDay, screenState.screen]);
    useEffect(function () {
        if (otpScreenState.reachedMaxRetries) {
            setFailedOtp(true);
        }
        else {
            setFailedOtp(false);
        }
    }, [otpScreenState.reachedMaxRetries]);
    useEffect(function () {
        if (loginState.loginFlow.loginError) {
            ANALYTICS.logEvent(EVENT.ENTER_PHONE_NUMBER_NOT_RECOGNIZED_VIEWED);
            dispatch(setLoginFlow(__assign(__assign({}, loginState.loginFlow), { loginError: false })));
        }
    }, [phoneNumber]);
    useEffect(function () {
        if (loginState.loginFlow.dobError) {
            ANALYTICS.logEvent(EVENT.VERIFY_DOB_TRY_AGAIN_CONTINUE_CLICKED);
        }
        if (birthDay !== '') {
            dispatch(setLoginFlow(__assign(__assign({}, loginState.loginFlow), { dobError: false })));
        }
    }, [birthDay]);
    var verifyOtpMutation = useVerifyOtp();
    var submitOtpHandler = function (code) {
        dispatch(setIncorrectOtpCode(false));
        verifyOtpMutation.mutate({ code: code }, {
            onSuccess: function (data) {
                dispatch(setIncorrectOtpCode(false));
                dispatch(setLoading(false));
                // TODO: make nextStep as a enum
                if (data.nextStep === 'verifyDOB') {
                    dispatch(setScreen(SCREEN.DOB));
                }
                else if (data.nextStep === 'verifyPII') {
                    redirectTo('account', false, false, true);
                }
                else if (data.nextStep === 'verifyPIN') {
                    dispatch(setScreen(SCREEN.PIN));
                }
                else if (data.nextStep === 'showSignUp') {
                    dispatch(callPrompt());
                }
            },
            onError: function (err) {
                if (err.code === '101') {
                    dispatch(setIncorrectOtpCode(true));
                    dispatch(setLoading(false));
                }
                else if (err.code === '201') {
                    dispatch(setScreen(SCREEN.VERIFICATION_FAILED));
                    dispatch(setLoading(false));
                }
            },
        });
        return;
    };
    var renderFailedFlowPage = function () {
        var body = language.maxRetriesBody;
        if (failedOtp) {
            body = language.loginOtpFailedBody;
        }
        return (_jsxs("div", __assign({ className: styles.failedFlowContainer }, { children: [_jsx("h1", __assign({ className: "".concat(styles.header, " ").concat(styles.failedHeader) }, { children: language.loginOtpFailedHeader })), _jsx("p", __assign({ className: styles.failedSubline }, { children: body }))] })));
    };
    var renderAccountPendingPage = function () {
        return (_jsxs("div", __assign({ className: styles.continueApplicationContainer }, { children: [_jsx("div", __assign({ className: styles.qrContainer }, { children: _jsx(DynamicQRCode, { height: "152", width: "152", image: downloadAppQRCodeGeneric || '' }) })), _jsxs("h1", __assign({ className: "".concat(styles.continueApplicationHeader, " ").concat(styles.failedHeader) }, { children: [language.accountPendingHeader, _jsx("br", {}), language.accountPendingHeader2.replace('$programName$', merchantData.rewardDetails.cardName)] })), _jsx("p", __assign({ className: styles.continueApplicationBody }, { children: language.accountPendingBody })), _jsx("div", __assign({ className: styles.downloadAppButton }, { children: _jsx(ButtonServicing, { value: language.downloadBtn, handleClick: function () {
                            ANALYTICS.logEvent(EVENT.ISSUE_WITH_APPLICATION_DOWNLOAD_CLICKED, {
                                application_product_uuid: merchantData.productUUID,
                                context: 'user_recognized',
                                consumer_uuid: consumerInfo.consumerUUID,
                            });
                            window.open(merchantData.deeplink);
                        }, colorScheme: ButtonColorScheme.ghostBranded }) })), _jsx(ButtonServicing, { value: language.goBackBtn, handleClick: function () {
                        ANALYTICS.logEvent(EVENT.ISSUE_WITH_APPLICATION_GO_BACK_CLICKED, {
                            application_product_uuid: merchantData.productUUID,
                            context: 'user_recognized',
                            consumer_uuid: consumerInfo.consumerUUID,
                        });
                        handleCloseBtn();
                    }, colorScheme: ButtonColorScheme.fillBranded })] })));
    };
    var handleCloseBtn = function () {
        dispatch(setShowModal(MODAL_ENUM.noModal));
        dispatch(clearServiceLoginState);
        dispatch(consumerLogOut);
        dispatch(resetDeviceID());
        dispatch(resetConsumerInfoSlice());
        dispatch(setScreen(SCREEN.APPLICATION));
        dispatch(setMultiCardUser(false));
    };
    var GetModalContent = function () {
        switch (screenState.screen) {
            case SCREEN.SERVICE_OTPSCREEN: {
                return (_jsx(OtpCardNew, { title: language.existingUserSubline, showLogoHeader: true, onFull: submitOtpHandler, handleResend: function () { }, bottomSubline: language.enterSecurityCode }));
            }
            case SCREEN.PIN: {
                return _jsx(PINEntry, { newPin: loginState.firstTimeUser });
            }
            case SCREEN.ACCOUNT_PENDING: {
                return renderAccountPendingPage();
            }
        }
    };
    var renderModalContent = function () {
        return (_jsxs(_Fragment, { children: [screenState.screen == SCREEN.DOB && (_jsx("div", __assign({ className: styles.imprintLogoContainer }, { children: _jsx("img", { draggable: "false", alt: "imprint logo", className: styles.imprintLogo, src: imprintLogo, tabIndex: 0 }) }))), screenState.screen == SCREEN.VERIFICATION_FAILED && renderFailedFlowPage(), GetModalContent()] }));
    };
    return (_jsx("div", __assign({ className: styles.overlay }, { children: _jsxs("div", __assign({ className: styles.modal }, { children: [_jsx("img", { src: close, alt: "close button of the popup modal", className: styles.close, onClick: function () { return handleCloseBtn(); }, onKeyDown: function (e) {
                        if (e.key.toUpperCase() === KEYTYPE.ENTER) {
                            handleCloseBtn();
                        }
                    }, tabIndex: 0, ref: outerElementRef }), _jsx("div", __assign({ className: styles.container }, { children: applicationScreenState.loading ? _jsx(ImpSpinner, {}) : renderModalContent() }))] })) })));
}
