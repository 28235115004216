import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    featureFlags: {},
};
var featureFlagSlice = createSlice({
    name: 'featureFlags',
    initialState: initialState,
    reducers: {
        setFeatureFlags: function (state, action) {
            state.featureFlags = action.payload;
        },
    },
});
export var setFeatureFlags = featureFlagSlice.actions.setFeatureFlags;
export default featureFlagSlice.reducer;
