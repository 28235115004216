var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { colors, ImpButton, ImpContainer, ImpFlex, ImpOtpField, ImpPad, ImpSpacer, ImpText, useSnackbar, } from '@imprint-payments/imprint-ui';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { EVENT } from '../analytics/analyticsConsts';
import { httpRequestV2 } from '../api/http/client';
import { Method } from '../api/http/httpConsts';
import { useImpState } from '../app/hooks';
import { SCREEN, setScreen } from '../app/screenStateSlice';
import { HeaderHandler } from '../components/HeaderHandler/HeaderHandler';
import { ANALYTICS } from '../firebase/firebase';
import { PAGE } from '../utils/routeConstant';
var Step;
(function (Step) {
    Step[Step["CreatePIN"] = 0] = "CreatePIN";
    Step[Step["ConfirmPIN"] = 1] = "ConfirmPIN";
})(Step || (Step = {}));
var ScreenStatus;
(function (ScreenStatus) {
    ScreenStatus[ScreenStatus["Clear"] = 0] = "Clear";
    ScreenStatus[ScreenStatus["InvalidPIN"] = 1] = "InvalidPIN";
    ScreenStatus[ScreenStatus["PinsDontMatch"] = 2] = "PinsDontMatch";
})(ScreenStatus || (ScreenStatus = {}));
export function CreatePINPage() {
    var _a = useState(''), createInput = _a[0], setCreateInput = _a[1];
    var _b = useImpState(), language = _b.language, navigate = _b.navigate, dispatch = _b.dispatch, consumerInfo = _b.consumerInfo;
    var _c = useState(Step.CreatePIN), step = _c[0], setStep = _c[1];
    var _d = useState(ScreenStatus.Clear), screenStatus = _d[0], setScreenStatus = _d[1];
    var _e = useState(false), clear = _e[0], setClear = _e[1];
    var createPINMutation = useCreatePin();
    var displayTemporarySnackbar = useSnackbar().displayTemporarySnackbar;
    usePostAcceptPage();
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.createPinViewed);
    }, []);
    useEffect(function () {
        setScreenStatus(ScreenStatus.Clear);
        if (step === Step.CreatePIN) {
            setCreateInput('');
            setClear(true);
        }
        if (step === Step.ConfirmPIN) {
            setClear(true);
        }
    }, [step]);
    return (_jsxs("div", { children: [_jsx(HeaderHandler, {}), _jsx(ImpContainer, { children: _jsx(ImpFlex, __assign({ fluid: true, direction: "column", align: "center" }, { children: _jsx(ImpContainer, __assign({ width: "100%", maxWidth: "520px" }, { children: _jsx(ImpPad, __assign({ fluid: true, padding: "0px 16px" }, { children: _jsxs(ImpFlex, __assign({ fluid: true, direction: "column", align: "center" }, { children: [_jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpText, __assign({ typography: "headline2" }, { children: getHeaderText(step) })), step === Step.CreatePIN && (_jsxs(_Fragment, { children: [_jsx(ImpSpacer, { height: "xs" }), _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onSurfaceVariant }, { children: language.createPinSubtitle }))] })), _jsx(ImpSpacer, { height: "xl" }), _jsx(ImpOtpField, { numInputs: 4, onFull: handleFullOTP, mask: true, clearWithError: [ScreenStatus.InvalidPIN, ScreenStatus.PinsDontMatch].includes(screenStatus), onChange: function (val) {
                                            if (val.length > 0) {
                                                setClear(false);
                                                setScreenStatus(ScreenStatus.Clear);
                                            }
                                        }, clear: clear }), _jsx(ImpSpacer, { height: "xl" }), screenStatus === ScreenStatus.PinsDontMatch && (_jsxs(_Fragment, { children: [_jsx(ImpSpacer, { height: "l" }), _jsx(ImpText, __assign({ color: colors.content.onSurfaceError, typography: "body2" }, { children: language.makeSurePinsMatch })), _jsx(ImpSpacer, { height: "l" })] })), screenStatus === ScreenStatus.InvalidPIN && (_jsxs(_Fragment, { children: [_jsx(ImpSpacer, { height: "l" }), _jsx(ImpText, __assign({ color: colors.content.onSurfaceError, typography: "body2" }, { children: language.invalidPin })), _jsx(ImpSpacer, { height: "l" })] })), step === Step.CreatePIN && (_jsx(_Fragment, { children: _jsx(ImpText, __assign({ color: screenStatus === ScreenStatus.InvalidPIN
                                                ? colors.content.onSurfaceError
                                                : colors.content.onSurfaceVariant, typography: "body3" }, { children: language.pinCreationNote })) })), step === Step.ConfirmPIN && (_jsx(_Fragment, { children: _jsx(ImpButton, __assign({ variant: "secondary", onClick: function () {
                                                setCreateInput('');
                                                setStep(Step.CreatePIN);
                                            } }, { children: language.startOver })) }))] })) })) })) })) })] }));
    function handleFullOTP(pin) {
        if (step === Step.CreatePIN) {
            if (isInvalidPin(pin)) {
                setScreenStatus(ScreenStatus.InvalidPIN);
                return;
            }
            setCreateInput(pin);
            setStep(Step.ConfirmPIN);
            return;
        }
        if (pin !== createInput) {
            setScreenStatus(ScreenStatus.PinsDontMatch);
            return;
        }
        createPINMutation.mutate({ consumerUUID: consumerInfo.consumerUUID, newPin: pin, oldPin: createInput }, { onSuccess: handleCreatePINSuccess, onError: handlePINFail });
    }
    function handleCreatePINSuccess() {
        dispatch(setScreen(SCREEN.COMPLETE_ON_PHONE));
        navigate(PAGE.COMPLETE_ON_PHONE);
    }
    function handlePINFail() {
        ANALYTICS.logEvent(EVENT.createPinFailed);
        setCreateInput('');
        setStep(Step.CreatePIN);
        setClear(true);
        displayTemporarySnackbar({ text: language.somethingWentWrong });
    }
    function getHeaderText(step) {
        if (step === Step.CreatePIN) {
            return _jsx("div", { children: language.createAPIN });
        }
        return _jsx("div", { children: language.reEnterYourPIN });
    }
}
export function usePostAcceptPage() {
    var _a = useImpState(), navigate = _a.navigate, consumerInfo = _a.consumerInfo;
    useEffect(function () {
        var _a;
        if (!((_a = consumerInfo === null || consumerInfo === void 0 ? void 0 : consumerInfo.auth) === null || _a === void 0 ? void 0 : _a.token)) {
            navigate(PAGE.HOME);
        }
    }, [consumerInfo, navigate]);
}
function isInvalidPin(pin) {
    // if same digit repeated 4 times
    if (pin.match(/(.)\1{3}/)) {
        return true;
    }
    // if pin is in sequence
    var seq = '01234567890';
    if (seq.includes(pin)) {
        return true;
    }
}
export function useCreatePin() {
    var consumerInfo = useImpState().consumerInfo;
    return useMutation({
        mutationFn: function (params) {
            return createPINAccount({
                consumerUUID: consumerInfo.consumerUUID,
                newPin: params.newPin,
                oldPin: '',
            });
        },
    });
}
export function createPINAccount(_a) {
    var consumerUUID = _a.consumerUUID, newPin = _a.newPin, oldPin = _a.oldPin;
    return __awaiter(this, void 0, void 0, function () {
        var req;
        return __generator(this, function (_b) {
            req = {
                path: '/v1/account/pin',
                method: Method.POST,
                body: {
                    consumerUUID: consumerUUID,
                    newPin: newPin,
                    oldPin: oldPin,
                },
            };
            return [2 /*return*/, httpRequestV2(req)];
        });
    });
}
