import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useAppSelector } from '../../app/hooks';
import { getUtmSource } from '../../lib/utmManager';
import { sanitize, validateUrlOrReturnEmpty } from '../../utils/functionUtils';
import { merchantInfos } from '../../utils/productUUIDConstants';
export default function DynamicQRCode(_a) {
    var height = _a.height, width = _a.width, image = _a.image;
    var productUUID = useAppSelector(function (state) { return state.merchantDataSelector.productUUID; });
    var qrInfo = merchantInfos[productUUID].qrInfo;
    var utmSource = getUtmSource(productUUID);
    var offerConfigUUIDs = sessionStorage.getItem('offerConfigUUIDs');
    var prm = sessionStorage.getItem('prm');
    useEffect(function () {
        var divWrapper = document.getElementById('qr-code-1');
        var sanitizedProductUUID = sanitize(productUUID || '');
        var sanitizedLpName = sanitize((qrInfo === null || qrInfo === void 0 ? void 0 : qrInfo.lpName) || '');
        var sanitizedIosLink = validateUrlOrReturnEmpty((qrInfo === null || qrInfo === void 0 ? void 0 : qrInfo.iosLink) || '');
        var sanitizedAndroidLink = validateUrlOrReturnEmpty((qrInfo === null || qrInfo === void 0 ? void 0 : qrInfo.androidLink) || '');
        var sanitizedUtmSource = sanitize(utmSource || '');
        var sanitizedOfferConfigUUIDs = sanitize(offerConfigUUIDs || '');
        var sanitizedPrm = sanitize(prm || '');
        if (image != '' && productUUID != null && divWrapper) {
            // add script link
            var scriptHeader_1 = document.createElement('script');
            scriptHeader_1.setAttribute('type', 'text/javascript');
            scriptHeader_1.src = 'https://app.stg.imprint.co/qr-code/dynamic-qrcode.js';
            document.head.append(scriptHeader_1);
            // add script call on load
            scriptHeader_1.onload = function () {
                var scriptExec = document.createElement('script');
                scriptExec.setAttribute('type', 'text/javascript');
                scriptExec.innerHTML = "window.dynamicQrcode.generateDeeplink(\"".concat(sanitizedProductUUID, "\", \"#qr-code-1\", \"\",\"").concat(sanitizedLpName, "\",\"").concat(sanitizedIosLink, "\",\"").concat(sanitizedAndroidLink, "\", \"").concat(sanitizedUtmSource, "\", ").concat(height, ", ").concat(width, ", \"\", \"").concat(sanitizedOfferConfigUUIDs, "\", \"").concat(sanitizedPrm, "\");");
                divWrapper.appendChild(scriptExec);
            };
            // add default image only once
            var img = document.getElementById('qr-code-img');
            if (img) {
                img.setAttribute('src', image);
                img.setAttribute('height', height);
                img.setAttribute('width', width);
                img.setAttribute('id', 'qr-code-img');
            }
            else {
                var img_1 = document.createElement('img');
                img_1.setAttribute('src', image);
                img_1.setAttribute('height', height);
                img_1.setAttribute('width', width);
                img_1.setAttribute('id', 'qr-code-img');
                divWrapper === null || divWrapper === void 0 ? void 0 : divWrapper.appendChild(img_1);
            }
            return function () {
                scriptHeader_1.remove();
            };
        }
    }, [image, productUUID, qrInfo]);
    return _jsx("div", { id: "qr-code-1", tabIndex: 0 });
}
