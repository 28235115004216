var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    loginFlow: {
        loginError: false,
        dobError: false,
        pinError: false,
    },
    lockOutMessage: '',
    firstTimeUser: false,
    multiCardUser: false,
    showSignUp: false,
    eligiblePhoneNumber: '',
};
var loginSlice = createSlice({
    name: 'login',
    initialState: initialState,
    reducers: {
        setLoginFlow: function (state, action) {
            state.loginFlow = action.payload;
        },
        setPinError: function (state, action) {
            state.loginFlow.pinError = action.payload;
        },
        setLockOutMessage: function (state, action) {
            state.lockOutMessage = action.payload;
        },
        setFirstTimeUser: function (state, action) {
            state.firstTimeUser = action.payload;
        },
        setMultiCardUser: function (state, action) {
            state.multiCardUser = action.payload;
        },
        setShowSignUp: function (state, action) {
            state.showSignUp = action.payload;
        },
        setEligiblePhoneNumber: function (state, action) {
            state.eligiblePhoneNumber = action.payload;
        },
    },
});
export var setLoginFlow = (_a = loginSlice.actions, _a.setLoginFlow), setLockOutMessage = _a.setLockOutMessage, setFirstTimeUser = _a.setFirstTimeUser, setMultiCardUser = _a.setMultiCardUser, setShowSignUp = _a.setShowSignUp, setEligiblePhoneNumber = _a.setEligiblePhoneNumber, setPinError = _a.setPinError;
export default loginSlice.reducer;
