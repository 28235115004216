var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getValidationString, noError, validateLastFourSSN, validatePhoneNumber, } from '../../utils/validateUtils';
import { PHONE_NUMBER_MAX_LENGTH } from '../../assets/consts/const';
import { formatPhoneNumber } from '../../utils/formatUtils';
import { setPhoneNumberError, setSsnLast4Error } from '../../app/validatorSlice';
import { colors, ImpButton, ImpFlex, ImpInput, ImpPad, ImpSpacer, ImpText, spacing, } from '@imprint-payments/imprint-ui';
import { useAppSelector, useImpState } from '../../app/hooks';
import { useEffect, useState } from 'react';
import useCheckoutAndLogin from '../../api/login/useCheckoutAndLogin';
import useLogin from '../../api/login/useLogin';
import { setConsumerProfile } from '../../app/consumerInfoSlice';
import { showError } from '../../api/api';
export default function LetsGetStarted(_a) {
    var nextStep = _a.nextStep;
    var _b = useImpState(), language = _b.language, dispatch = _b.dispatch, consumerProfile = _b.consumerProfile;
    var validator = useAppSelector(function (state) { return state.validatorSelector; });
    var _c = useState(''), phone = _c[0], setPhone = _c[1];
    var _d = useState(''), ssn4 = _d[0], setSsn4 = _d[1];
    var loginMutation = useLogin();
    var continueClick = function () {
        loginMutation.mutate({ phone: phone }, {
            onSuccess: function (res, req) {
                dispatch(setConsumerProfile(__assign(__assign({}, consumerProfile), { phone: req.phone, ssn4: ssn4 })));
                nextStep();
            },
            onError: function (err) {
                console.error(err);
                showError(dispatch);
            },
        });
    };
    var buttonState = ssn4.length === 4 && phone.length === PHONE_NUMBER_MAX_LENGTH ? 'enabled' : 'disabled';
    return (_jsxs(ImpFlex, __assign({ direction: 'column' }, { children: [_jsxs(ImpPad, __assign({ padding: spacing.xl }, { children: [_jsxs(ImpFlex, __assign({ direction: 'column', gap: spacing.xs, align: 'center' }, { children: [_jsx(ImpText, __assign({ typography: 'headline3' }, { children: "Let's get started" })), _jsx(ImpText, __assign({ typography: 'body2', color: colors.content.onBackgroundVariant }, { children: "With some basic information, we may be able to autofill your application. You can edit before you submit." }))] })), _jsx(ImpSpacer, { height: spacing.xl }), _jsxs(ImpFlex, __assign({ direction: 'column', gap: spacing.m, dataTestId: 'input-container' }, { children: [_jsx(PhoneInput, { phone: phone, setPhone: setPhone }), _jsx(ImpInput, { label: language.lastFour, value: ssn4, maxLength: 4, onChange: function (e) { return setSsn4(e.target.value); }, showError: !noError.includes(validator.lastFourError), errorMessage: getValidationString(validator.lastFourError, language), onBlur: function () {
                                    dispatch(setSsnLast4Error(validateLastFourSSN(ssn4)));
                                }, caption: language.ssnDisclosure, numeric: true, maxWidth: "100%" })] }))] })), _jsx(ImpPad, __assign({ padding: spacing.l, fluid: true }, { children: _jsxs(ImpFlex, __assign({ direction: 'column', align: 'center' }, { children: [_jsx(ImpText, __assign({ typography: 'body3', color: colors.content.onSurfaceVariant }, { children: language.personalInfoNotice })), _jsx(ImpSpacer, { height: spacing.l }), _jsx(ImpButton, __assign({ onClick: continueClick, buttonStyle: 'color', state: buttonState }, { children: language.continueBtn })), _jsx(ImpSpacer, { height: spacing.s }), _jsx(ImpButton, __assign({ variant: 'text', buttonStyle: 'branded', state: buttonState, onClick: continueClick }, { children: "Enter info manually" }))] })) }))] })));
}
function PhoneInput(_a) {
    var phone = _a.phone, setPhone = _a.setPhone;
    var _b = useImpState(), language = _b.language, dispatch = _b.dispatch;
    var validator = useAppSelector(function (state) { return state.validatorSelector; });
    var checkoutMutation = useCheckoutAndLogin();
    useEffect(function () {
        if (phone.length === PHONE_NUMBER_MAX_LENGTH) {
            checkoutMutation.mutate({ phone: phone });
        }
    }, [phone]);
    return (_jsx(ImpInput, { label: language.phoneNumber, value: phone, showError: !noError.includes(validator.phoneNumberError), errorMessage: getValidationString(validator.phoneNumberError, language), maxLength: PHONE_NUMBER_MAX_LENGTH, onChange: function (e) { return setPhone(formatPhoneNumber(e.target.value)); }, onBlur: function () {
            dispatch(setPhoneNumberError(validatePhoneNumber(phone)));
        }, caption: language.personalInfoNumberInstruction, numeric: true, maxWidth: "100%" }));
}
