var _a;
import { createSlice } from '@reduxjs/toolkit';
import { emptyConsumerFactors, emptyConsumerProfile, emptyDob, } from '../types';
import { getEmptyAmount } from './merchantDataSlice';
var initialState = {
    consumerProfile: emptyConsumerProfile,
    consumerFactor: emptyConsumerFactors,
    auth: {
        applicationUUID: '',
        token: '',
        requestID: '',
        lastActive: '',
    },
    cardOffer: {
        limit: getEmptyAmount(),
        apr: getEmptyAmount(),
        fees: '',
    },
    last4: '',
    emailVerificationFailed: false,
    rewardsInfo: {
        email: '',
    },
    consumerUUID: '',
};
var consumerInfoSlice = createSlice({
    name: 'consumerInfo',
    initialState: initialState,
    reducers: {
        prefillConsumerProfileFromHome: function (state, action) {
            state.consumerProfile.address = action.payload.address;
            state.consumerProfile.name = {
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
            };
            state.consumerProfile.phone = action.payload.phone;
            state.consumerProfile.email = action.payload.email;
            // DOB will be empty if reapplication
            state.consumerProfile.dob = action.payload.dob || emptyDob;
        },
        setConsumerProfile: function (state, action) {
            state.consumerProfile = action.payload;
        },
        setConsumerFactor: function (state, action) {
            state.consumerFactor = action.payload;
        },
        setAuth: function (state, action) {
            state.auth = action.payload;
        },
        setLastActive: function (state, action) {
            state.auth.lastActive = action.payload;
        },
        setCardOffer: function (state, action) {
            state.cardOffer = action.payload;
        },
        setLast4: function (state, action) {
            state.last4 = action.payload;
        },
        resetLast4: function (state) {
            state.last4 = initialState.last4;
        },
        resetConsumerInfoSlice: function (state) { return initialState; },
        resetAuth: function (state) {
            state.auth = initialState.auth;
        },
        setHHEmailVerification: function (state, action) {
            state.emailVerificationFailed = action.payload;
        },
        setRewardsEmail: function (state, action) {
            state.rewardsInfo.email = action.payload;
        },
        setConsumerUUID: function (state, action) {
            state.consumerUUID = action.payload;
        },
    },
});
export var setConsumerProfile = (_a = consumerInfoSlice.actions, _a.setConsumerProfile), prefillConsumerProfileFromHome = _a.prefillConsumerProfileFromHome, setConsumerFactor = _a.setConsumerFactor, setAuth = _a.setAuth, setLastActive = _a.setLastActive, setCardOffer = _a.setCardOffer, setLast4 = _a.setLast4, resetLast4 = _a.resetLast4, setHHEmailVerification = _a.setHHEmailVerification, setRewardsEmail = _a.setRewardsEmail, resetConsumerInfoSlice = _a.resetConsumerInfoSlice, resetAuth = _a.resetAuth, setConsumerUUID = _a.setConsumerUUID;
export default consumerInfoSlice.reducer;
