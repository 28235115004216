var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { checkConsumerDobOver21 } from '@imprint-payments/imprint-lib';
import { ImpButton, ImpContainer, ImpFlex, ImpIcon, ImpInput, ImpSelect, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { submitAPP, submitApplication } from '../../api/api';
import useLogin from '../../api/login/useLogin';
import { setLoading, setShowLinkHHScreen } from '../../app/applicationScreenSlice';
import { setConsumerFactor, setConsumerProfile, setLast4 } from '../../app/consumerInfoSlice';
import { useAppSelector, useImpState } from '../../app/hooks';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { setFullSsnError, setSsnLast4Error } from '../../app/validatorSlice';
import lock from '../../assets/images/lock.png';
import { ANALYTICS } from '../../firebase/firebase';
import { formatFullSSN, formatSSN } from '../../utils/formatUtils';
import { clearHomePageState, getOfferUUIDs } from '../../utils/functionUtils';
import { PRODUCTUUID } from '../../utils/productUUIDConstants';
import { PAGE } from '../../utils/routeConstant';
import { getValidationString, hideWhileTyping, noError, validateAnnualIncome, validateFullSSN, validateHousing, validateLastFourSSN, ValidationResponse, } from '../../utils/validateUtils';
import { HousingDisclosure, IncomeDisclosure, SsnDisclosure } from './Disclosures';
import styles from './IncomeHousingID.module.css';
import { Terms } from './Terms';
import { formatUSD, toUSDKeyDown, useFormInput } from './useFormInput';
var IncomeType;
(function (IncomeType) {
    IncomeType["Employment"] = "Employment";
    IncomeType["SelfEmployment"] = "SelfEmployment";
    IncomeType["Retirement"] = "Retirement";
    IncomeType["Unemployment"] = "Unemployment";
    IncomeType["GovtAssistance"] = "GovtAssistance";
    IncomeType["OtherIncome"] = "OtherIncome";
})(IncomeType || (IncomeType = {}));
var incomeTypes = [
    IncomeType.Employment,
    IncomeType.SelfEmployment,
    IncomeType.Retirement,
    IncomeType.Unemployment,
    IncomeType.GovtAssistance,
    IncomeType.OtherIncome,
];
var HousingType;
(function (HousingType) {
    HousingType["Rent"] = "Rent";
    HousingType["Own"] = "Own";
    HousingType["Other"] = "Other";
})(HousingType || (HousingType = {}));
var housingTypes = [HousingType.Rent, HousingType.Own, HousingType.Other];
var getHousingTypeLabel = function (val, language) {
    switch (val) {
        case HousingType.Rent:
            return language.housingTypeOption[0];
        case HousingType.Own:
            return language.housingTypeOption[1];
        case HousingType.Other:
            return language.housingTypeOption[2];
        default:
            return '';
    }
};
var getIncomeTypeLabel = function (val, language) {
    switch (val) {
        case IncomeType.Employment:
            return language.incomeSourceOptionV2[0];
        case IncomeType.SelfEmployment:
            return language.incomeSourceOptionV2[1];
        case IncomeType.Retirement:
            return language.incomeSourceOptionV2[2];
        case IncomeType.Unemployment:
            return language.incomeSourceOptionV2[3];
        case IncomeType.GovtAssistance:
            return language.incomeSourceOptionV2[4];
        case IncomeType.OtherIncome:
            return language.incomeSourceOptionV2[5];
        default:
            return '';
    }
};
export default function IncomeHousingV2() {
    var _this = this;
    var _a = useImpState(), language = _a.language, merchantData = _a.merchantData, consumerInfo = _a.consumerInfo, dispatch = _a.dispatch, navigate = _a.navigate, loginState = _a.loginState, appStatus = _a.appStatus, appScreenState = _a.appScreenState;
    var validator = useAppSelector(function (state) { return state.validatorSelector; });
    var _b = useState('disabled'), submitButtonState = _b[0], setSubmitButtonState = _b[1];
    var _c = useState(''), lastFour = _c[0], setLastFour = _c[1];
    var storedSsn4 = useAppSelector(function (state) { return state.consumerInfoSelector.consumerProfile.ssn4; });
    var _d = useState(''), displayFullSsn = _d[0], setDisplayFullSsn = _d[1];
    var _e = useState(''), fullSsn = _e[0], setFullSsn = _e[1];
    var _f = useState(appScreenState.requireFullSSN ||
        !appScreenState.fullNumberEntered ||
        !loginState.loginFlow.loginError), disabledInputField = _f[0], setDisabledInputField = _f[1];
    var _g = useState(false), isMultiCardApplicationFlow = _g[0], setIsMultiCardApplicationFlow = _g[1];
    var _h = useState(false), directToOtpVerify = _h[0], setDirectToOtpVerify = _h[1];
    var consumerFactors = useAppSelector(function (state) { return state.consumerInfoSelector.consumerFactor; });
    var _j = useState(false), canContinue = _j[0], setCanContinue = _j[1];
    var _k = useState(consumerFactors.incomeType), incomeSource = _k[0], setIncomeSource = _k[1];
    var _l = useState(consumerFactors.housingType), housingType = _l[0], setHousingType = _l[1];
    var _m = useFormInput({
        validator: validateHousing,
        formatter: formatUSD,
        cleanFormattedValue: function (val) { return val.replace(/[^0-9]/g, ''); },
        onKeyDown: toUSDKeyDown,
        defaultValue: consumerFactors.housingCost,
        maxLength: 7,
        // accounts for formatting
        maxLengthBuffer: 3,
    }), housingCost = _m[0], isHousingCostValid = _m[1], housingCostInputProps = _m[2];
    var _o = useFormInput({
        validator: validateAnnualIncome,
        formatter: formatUSD,
        cleanFormattedValue: function (val) { return val.replace(/[^0-9]/g, ''); },
        onKeyDown: toUSDKeyDown,
        defaultValue: consumerFactors.income,
        maxLength: 7,
        maxLengthBuffer: 3,
    }), income = _o[0], isIncomeValid = _o[1], incomeInputProps = _o[2];
    useEffect(function () {
        // everything else is irrelevant in the full ssn4 case
        if (appScreenState.requireFullSSN) {
            setCanContinue(validator.fullSSNError === ValidationResponse.valid);
            return;
        }
        setCanContinue((isMultiCardApplicationFlow ||
            appScreenState.requireFullSSN ||
            validator.lastFourError === ValidationResponse.valid) &&
            (isMultiCardApplicationFlow ||
                !appScreenState.requireFullSSN ||
                validator.fullSSNError === ValidationResponse.valid) &&
            incomeSource !== '' &&
            isIncomeValid &&
            housingType !== '' &&
            isHousingCostValid &&
            (isMultiCardApplicationFlow || appScreenState.requireFullSSN || lastFour.length === 4) &&
            validator.fnError === ValidationResponse.valid &&
            validator.lnError === ValidationResponse.valid &&
            (validator.emailError === ValidationResponse.valid ||
                validator.emailError === ValidationResponse.emailPotentialTypo) &&
            (isMultiCardApplicationFlow ||
                appScreenState.requireFullSSN ||
                validator.birthDateError === ValidationResponse.valid) &&
            validator.phoneNumberError === ValidationResponse.valid &&
            validator.address1Error === ValidationResponse.valid &&
            (validator.address2Error === ValidationResponse.valid ||
                validator.address2Error === ValidationResponse.empty) &&
            validator.cityError === ValidationResponse.valid &&
            validator.zipError === ValidationResponse.valid);
    }, [
        validator,
        appScreenState,
        isMultiCardApplicationFlow,
        incomeSource,
        income,
        isIncomeValid,
        housingType,
        housingCost,
        isHousingCostValid,
        lastFour,
        appScreenState.requireFullSSN,
    ]);
    function backClick() {
        clearHomePageState(dispatch);
    }
    useEffect(function () {
        if (appScreenState.showWelcomeBackCard) {
            ANALYTICS.logEvent(EVENT.RETURNING_USER_INCOME_HOUSING_ID_VIEWED, {
                product_uuid: merchantData.productUUID,
            });
            setIsMultiCardApplicationFlow(loginState.multiCardUser);
        }
    }, [appScreenState.showWelcomeBackCard, loginState.multiCardUser]);
    useEffect(function () {
        var validationResponse = validateLastFourSSN(lastFour);
        if (!hideWhileTyping.includes(validationResponse)) {
            dispatch(setSsnLast4Error(validationResponse));
        }
    }, [lastFour]);
    useEffect(function () {
        var validationResponse = validateFullSSN(fullSsn, storedSsn4);
        if (!hideWhileTyping.includes(validationResponse)) {
            dispatch(setFullSsnError(validationResponse));
        }
        else {
            if (fullSsn !== '') {
                dispatch(setFullSsnError(ValidationResponse.empty));
            }
            else {
                dispatch(setFullSsnError(ValidationResponse.invalidSSN));
            }
        }
    }, [fullSsn, lastFour]);
    useEffect(function () {
        window.history.pushState('fake-route', document.title, window.location.href);
        addEventListener('popstate', backClick);
        return function () {
            removeEventListener('popstate', backClick);
            if (window.history.state === 'fake-route') {
                window.history.back();
            }
        };
    }, []);
    useEffect(function () {
        if (appScreenState.requireFullSSN) {
            ANALYTICS.logEvent(EVENT.INCOME_HOUSING_FULL_SSN_VIEWED);
        }
    }, []);
    useEffect(function () {
        setDisabledInputField(appScreenState.requireFullSSN ||
            !appScreenState.fullNumberEntered ||
            !loginState.loginFlow.loginError);
        if (loginState.showSignUp && !appScreenState.requireFullSSN) {
            setDisabledInputField(false);
        }
    }, [
        appScreenState.fullNumberEntered,
        loginState.loginFlow.loginError,
        appScreenState.requireFullSSN,
        loginState.showSignUp,
    ]);
    useEffect(function () {
        var consumerFactor = consumerInfo.consumerFactor;
        if (loginState.multiCardUser &&
            consumerFactor.income !== '' &&
            consumerFactor.incomeType !== '' &&
            consumerFactor.housingType !== '' &&
            consumerFactor.housingCost !== '') {
            submit();
        }
    }, [consumerInfo.consumerFactor]);
    useEffect(function () {
        if (directToOtpVerify && !appScreenState.loading) {
            dispatch(setScreen(SCREEN.OTPSCREEN));
        }
    }, [directToOtpVerify, appScreenState.loading]);
    useEffect(function () {
        if (appScreenState.loading || appScreenState.isSubmittingApp) {
            setSubmitButtonState('submitting');
        }
        else if (!canContinue) {
            setSubmitButtonState('disabled');
        }
        else {
            setSubmitButtonState('enabled');
        }
    }, [canContinue, appScreenState.loading, appScreenState.isSubmittingApp]);
    var loginMutation = useLogin();
    var handleContinueClick = function () { return __awaiter(_this, void 0, void 0, function () {
        var consumerFactorData;
        return __generator(this, function (_a) {
            dispatch(setLoading(true));
            ANALYTICS.logEvent(EVENT.INCOME_HOUSING_SUBMIT_CLICKED);
            if (appScreenState.showWelcomeBackCard) {
                ANALYTICS.logEvent(EVENT.RETURNING_USER_INCOME_HOUSING_SUBMIT_CLICKED, {
                    product_uuid: merchantData.productUUID,
                });
            }
            consumerFactorData = {
                income: '',
                incomeType: '',
                housingType: '',
                housingCost: '',
            };
            if (!appScreenState.requireFullSSN) {
                dispatch(setLast4(lastFour));
                dispatch(setConsumerProfile(__assign(__assign({}, consumerInfo.consumerProfile), { ssn4: lastFour, ssn: fullSsn.trim().replace(/[^\d]/g, '') })));
                consumerFactorData = {
                    income: income,
                    incomeType: incomeSource,
                    housingType: housingType,
                    housingCost: housingCost,
                };
                dispatch(setConsumerFactor(consumerFactorData));
                if (!loginState.multiCardUser &&
                    !appStatus.reApplicationInApp &&
                    consumerInfo.auth.token === '') {
                    loginMutation.mutate({ phone: consumerInfo.consumerProfile.phone });
                }
            }
            if (!loginState.multiCardUser) {
                submit(consumerFactorData);
            }
            dispatch(setLoading(false));
            return [2 /*return*/];
        });
    }); };
    var submit = function (consumerFactorData) {
        if (appScreenState.requireFullSSN) {
            // for hh before we set otp screen
            if (appScreenState.showLinkHHScreen) {
                dispatch(setShowLinkHHScreen(false));
            }
            dispatch(submitAPP({
                token: consumerInfo.auth.token,
                applicationUUID: consumerInfo.auth.applicationUUID,
                ssn9: formatSSN(fullSsn),
            }));
            return;
        }
        if (loginState.multiCardUser) {
            if (merchantData.productUUID !== PRODUCTUUID.HH) {
                dispatch(submitApplication({
                    rewardsInfo: consumerInfo.rewardsInfo,
                    consumer: consumerInfo.consumerProfile,
                    consumerFactor: consumerInfo.consumerFactor,
                    productUUID: merchantData.productUUID,
                    isMultiCard: true,
                    offerUUIDs: getOfferUUIDs(),
                }));
            }
            else {
                // if HH, we need to verify that email is an RC club account
                dispatch(setShowLinkHHScreen(true));
                dispatch(setScreen(SCREEN.OTPSCREEN));
                navigate(PAGE.OTP);
            }
        }
        if (!loginState.multiCardUser) {
            if (appStatus.reApplicationInApp) {
                if (merchantData.productUUID !== PRODUCTUUID.HH) {
                    // TODO: ssn4 does not propogate to redux store, causing https://imprint.atlassian.net/browse/CTW-1277
                    // will have a better refactoring
                    dispatch(submitApplication({
                        rewardsInfo: consumerInfo.rewardsInfo,
                        consumer: __assign(__assign({}, consumerInfo.consumerProfile), { ssn4: lastFour }),
                        consumerFactor: consumerFactorData,
                        productUUID: merchantData.productUUID,
                        isMultiCard: false,
                        offerUUIDs: getOfferUUIDs(),
                    }));
                }
                else {
                    // if HH, we need to verify that email is an RC club account
                    dispatch(setShowLinkHHScreen(true));
                }
            }
            else {
                if (consumerInfo.auth.token === '') {
                    setDirectToOtpVerify(true);
                }
                else {
                    if (merchantData.productUUID !== PRODUCTUUID.HH) {
                        // TODO: ssn4 does not propogate to redux store, causing https://imprint.atlassian.net/browse/CTW-1277
                        // will have a better refactoring
                        dispatch(submitApplication({
                            rewardsInfo: consumerInfo.rewardsInfo,
                            consumer: __assign(__assign({}, consumerInfo.consumerProfile), { ssn4: lastFour }),
                            consumerFactor: consumerFactorData,
                            productUUID: merchantData.productUUID,
                            offerUUIDs: getOfferUUIDs(),
                        }));
                    }
                    else {
                        // if HH, we need to verify that email is an RC club account
                        dispatch(setShowLinkHHScreen(true));
                        dispatch(setScreen(SCREEN.OTPSCREEN));
                        navigate(PAGE.OTP);
                    }
                }
            }
        }
    };
    var formatMaskedSsn = function (ssn) {
        var filtered = ssn.replace(/[^\d]/g, '');
        if (filtered.length <= 3) {
            return 'X'.repeat(ssn.length);
        }
        if (filtered.length === 4) {
            return 'XXX-X';
        }
        if (filtered.length === 5) {
            return 'XXX-XX';
        }
        return 'XXX-XX-' + filtered.substring(5);
    };
    var handleFullSsnChange = function (e) {
        if (e.nativeEvent.inputType === 'deleteContentBackward') {
            var filteredFullSsn = fullSsn.replace(/[^\d]/g, '');
            var filteredVal = e.target.value.replace(/[^X\d]/g, '');
            var diff = filteredFullSsn.length - filteredVal.length;
            var newSsn_1 = filteredFullSsn.substring(0, filteredFullSsn.length - diff);
            setFullSsn(formatFullSSN(newSsn_1));
            setDisplayFullSsn(formatMaskedSsn(newSsn_1));
            return;
        }
        var inputValue = e.target.value;
        var lastChar = inputValue[inputValue.length - 1];
        var newSsn = lastChar.match(/[0-9]/) ? fullSsn + lastChar : fullSsn;
        setFullSsn(formatFullSSN(newSsn));
        setDisplayFullSsn(formatMaskedSsn(newSsn));
    };
    var handleSsn9KeyDown = function (e) {
        var allowedKeys = ['Tab', 'ArrowLeft', 'ArrowRight', 'Backspace', 'Delete'];
        var isSelect = (e.ctrlKey || e.metaKey) && 'a' === e.key.toLowerCase();
        if (!allowedKeys.includes(e.key) && isNaN(parseInt(e.key)) && !isSelect) {
            e.preventDefault();
        }
    };
    var ssnView = function () {
        if (appScreenState.requireFullSSN) {
            return (_jsxs(ImpContainer, { children: [_jsx(ImpInput, { label: language.fullSSN, value: displayFullSsn, maxLength: 11, id: "fullSSN", onChange: handleFullSsnChange, showError: !noError.includes(validator.fullSSNError), errorMessage: fullSsn.length < 9
                            ? language.fullSSNErrmsg
                            : getValidationString(validator.fullSSNError, language), trailingIcon: _jsx(ImpIcon, { iconSrc: lock, height: "24px", width: "24px" }), 
                        // isDisabled={!appScreenState.requireFullSSN}
                        onBlur: function () {
                            dispatch(setFullSsnError(validateFullSSN(fullSsn, storedSsn4)));
                        }, onKeyDown: function (e) { return handleSsn9KeyDown(e); }, type: "tel", maxWidth: "100%" }), _jsx(SsnDisclosure, {})] }));
        }
        else {
            return (_jsxs(ImpContainer, { children: [_jsx(ImpInput, { label: language.lastFour, value: lastFour, maxLength: 4, id: "ssn", onChange: function (e) { return setLastFour(e.target.value); }, showError: !noError.includes(validator.lastFourError), errorMessage: getValidationString(validator.lastFourError, language), isDisabled: disabledInputField, instrumentation: function () {
                            ANALYTICS.logEvent(EVENT.INCOME_HOUSING_FORM_FILLED_OUT, {
                                field: 'ssn',
                            });
                        }, trailingIcon: _jsx("img", { className: styles.lockIcon, src: lock }), onBlur: function () {
                            dispatch(setSsnLast4Error(validateLastFourSSN(lastFour)));
                        }, numeric: true, maxWidth: "100%" }), _jsx(SsnDisclosure, {})] }));
        }
    };
    var getDefaultVal = function (val, matcher) {
        if (val === '')
            return undefined;
        return {
            value: val,
            label: matcher(val, language),
        };
    };
    return (_jsxs("div", __assign({ className: styles.container }, { children: [_jsxs(ImpFlex, __assign({ direction: "column", align: "normal", gap: "m" }, { children: [_jsx(ImpSelect, { label: language.incomeSource, id: "incomeSource", options: language.incomeSourceOptionV2.map(function (val, index) {
                            return { value: incomeTypes[index], label: val };
                        }), onOptionChange: function (val) {
                            ANALYTICS.logEvent(EVENT.INCOME_HOUSING_FORM_FILLED_OUT, {
                                field: 'primary_income_source',
                            });
                            setIncomeSource(val);
                        }, isDisabled: disabledInputField || (appStatus.reApplicationInApp && appScreenState.requireFullSSN), defaultOption: getDefaultVal(consumerFactors.incomeType, function (val, language) {
                            return getIncomeTypeLabel(val, language);
                        }), maxWidth: "100%" }), _jsx(ImpInput, __assign({}, incomeInputProps, { errorMessage: checkConsumerDobOver21(consumerInfo.consumerProfile.dob)
                            ? language.invalidHouseholdIncome
                            : language.invalidIncome, label: checkConsumerDobOver21(consumerInfo.consumerProfile.dob)
                            ? language.annualHouseholdIncome
                            : language.annualIncome, isDisabled: disabledInputField || (appStatus.reApplicationInApp && appScreenState.requireFullSSN), focusContent: _jsx(IncomeDisclosure, {}), instrumentation: function () {
                            ANALYTICS.logEvent(EVENT.INCOME_HOUSING_FORM_FILLED_OUT, {
                                field: 'annual_income',
                            });
                        }, maxWidth: "100%" })), _jsx(ImpSelect, { label: language.housingType, id: "housingType", options: language.housingTypeOption.map(function (val, index) {
                            return { value: housingTypes[index], label: val };
                        }), onOptionChange: function (val) {
                            ANALYTICS.logEvent(EVENT.INCOME_HOUSING_FORM_FILLED_OUT, {
                                field: 'housing_type',
                            });
                            setHousingType(val);
                        }, isDisabled: disabledInputField || (appStatus.reApplicationInApp && appScreenState.requireFullSSN), defaultOption: getDefaultVal(consumerFactors.housingType, function (val, language) {
                            return getHousingTypeLabel(val, language);
                        }), maxWidth: "100%" }), _jsx(ImpInput, __assign({}, housingCostInputProps, { label: language.monthlyHousingCost, isDisabled: disabledInputField || (appStatus.reApplicationInApp && appScreenState.requireFullSSN), instrumentation: function () {
                            ANALYTICS.logEvent(EVENT.INCOME_HOUSING_FORM_FILLED_OUT, {
                                field: 'housing_cost',
                            });
                        }, focusContent: _jsx(HousingDisclosure, {}), maxWidth: "100%" }))] })), !isMultiCardApplicationFlow && ssnView(), _jsx(Terms, {}), _jsx(ImpButton, __assign({ size: "fill", variant: "primary", buttonStyle: "branded", onClick: handleContinueClick, state: submitButtonState }, { children: language.submitBtn }))] })));
}
