var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpButton } from '@imprint-payments/imprint-ui';
import { useEffect } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { getCounterOffer, getRejectionReasons } from '../../api/api';
import { useImpState } from '../../app/hooks';
import { ANALYTICS } from '../../firebase/firebase';
import ButtonServicing, { ButtonColorScheme, } from '../buttonServicing/ButtonServicing';
import Spinner from '../spinner/Spinner';
import styles from './Modal.module.css';
export default function WaterfallModal(_a) {
    var _b;
    var handleViewDeclineDetailsClick = _a.handleViewDeclineDetailsClick, showApplyAgainButton = _a.showApplyAgainButton, handleApplyAgainClick = _a.handleApplyAgainClick;
    var _c = useImpState(), language = _c.language, languageCode = _c.languageCode, dispatch = _c.dispatch, merchantData = _c.merchantData, appScreenState = _c.appScreenState, appStatus = _c.appStatus;
    var counterOffer = merchantData.counterOffer;
    useEffect(function () {
        if (appStatus.applicationUUID) {
            dispatch(getRejectionReasons({
                applicationUUID: appStatus.applicationUUID,
            }));
        }
    }, [appStatus.applicationUUID]);
    useEffect(function () {
        dispatch(getCounterOffer(merchantData.productUUID, languageCode));
    }, [merchantData.productUUID, language]);
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.COUNTEROFFER_VIEWED);
    }, []);
    function handleLinkClick() {
        ANALYTICS.logEvent(EVENT.COUNTEROFFER_VISIT_CLICKED);
        window.open(counterOffer.url, '_blank');
    }
    if (!counterOffer || counterOffer.image == '' || appScreenState.loading) {
        return _jsx(Spinner, {});
    }
    return (_jsxs("div", __assign({ className: styles.waterfallContainer }, { children: [_jsx("div", __assign({ className: styles.waterfallTitle }, { children: language.waterfallTitle })), _jsxs("div", __assign({ className: styles.waterfallSubtitles }, { children: [_jsx("p", { children: language.waterfallSubtitle1 }), showApplyAgainButton ? (_jsxs(_Fragment, { children: [language.waterfallSubtitle3, _jsx(ImpButton, __assign({ variant: "inline", buttonStyle: "branded", onClick: handleApplyAgainClick }, { children: language.waterfallSubtitle4 })), language.period] })) : (_jsx("p", { children: language.waterfallSubtitle2 }))] })), _jsx(ButtonServicing, { colorScheme: ButtonColorScheme.emptyBranded, value: language.viewDeclineDetailsBtn, overrideColor: counterOffer.lightModeColor, handleClick: handleViewDeclineDetailsClick }), _jsxs("div", __assign({ className: styles.waterfallImageSection, style: { borderColor: counterOffer.lightModeColor } }, { children: [_jsx("p", { className: styles.waterfallImageTitle, dangerouslySetInnerHTML: {
                            __html: (_b = counterOffer.title) === null || _b === void 0 ? void 0 : _b.replace(counterOffer.titleHighlight, '<b>' + counterOffer.titleHighlight + '</b>'),
                        } }), _jsx("p", { children: counterOffer.subtitle }), _jsx("img", { className: styles.waterfallCard, src: counterOffer.image }), _jsx(ButtonServicing, { colorScheme: ButtonColorScheme.emptyBranded, value: counterOffer.hyperlinkButtonName, overrideColor: counterOffer.lightModeColor, handleClick: handleLinkClick })] })), _jsx("div", __assign({ className: styles.waterfallDisclaimers }, { children: _jsx("p", { dangerouslySetInnerHTML: { __html: counterOffer.disclosure } }) }))] })));
}
