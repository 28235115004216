var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { ImpAccordion, ImpFlex, ImpPad, spacing } from "@imprint-payments/imprint-ui";
import { useRewardsInfo } from "../application/RewardsDisplay";
export default function RewardsModal() {
    var rewardsBonus = useRewardsInfo();
    return (_jsx(_Fragment, { children: _jsx(ImpFlex, __assign({ direction: "column", align: "center", justify: "center", fluid: true, dataTestId: "rewards-display-body" }, { children: _jsx(ImpPad, __assign({ padding: spacing.l, fluid: true }, { children: _jsx(ImpFlex, __assign({ direction: "column", align: "flex-start", justify: "center", fluid: true, dataTestId: "rewards-display-list", gap: "s" }, { children: rewardsBonus.map(function (bonus, index) { return (_createElement(ValuePropItem, __assign({}, bonus, { key: index, index: index }))); }) })) })) })) }));
}
function ValuePropItem(_a) {
    var _b = _a.index, index = _b === void 0 ? 0 : _b, amount = _a.amount, title = _a.title, badge = _a.badge, content = _a.content;
    return (_jsx(ImpFlex, __assign({ dataTestId: "value-prop-text-".concat(index), align: "stretch", direction: "column", fluid: true }, { children: _jsx(ImpAccordion, { staticOpen: true, items: [
                {
                    badge: badge && __assign({ color: 'var(--merchant-color)', variant: 'primary' }, badge),
                    title: title,
                    subtitle: amount,
                    content: content && _jsx(ImpFlex, __assign({ direction: "column" }, { children: content })),
                },
            ] }) })));
}
