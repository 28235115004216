var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from './app/store';
import App from './App';
import { StatsigProvider } from '@statsig/react-bindings';
import useAsyncClient from './statsig/client';
import { ApplicationLoader } from './components/ApplicationLoader/ApplicationLoader';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
var AppBrowserRouter = /** @class */ (function (_super) {
    __extends(AppBrowserRouter, _super);
    function AppBrowserRouter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AppBrowserRouter.prototype.render = function () {
        return (_jsx(BrowserRouter, { children: _jsx(AppWrapper, {}) }));
    };
    return AppBrowserRouter;
}(Component));
export default AppBrowserRouter;
var AppWrapper = function () {
    var _a = useAsyncClient(), statsigLoading = _a.isLoading, statsigClient = _a.client;
    var queryClient = new QueryClient();
    if (statsigLoading) {
        return (_jsx("div", __assign({ className: "App" }, { children: _jsx(ApplicationLoader, {}) })));
    }
    return (_jsx(Provider, __assign({ store: store }, { children: _jsx(QueryClientProvider, __assign({ client: queryClient }, { children: _jsx(StatsigProvider, __assign({ client: statsigClient }, { children: _jsx(App, {}) })) })) })));
};
