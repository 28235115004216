var _a;
import { PRODUCTUUID } from '../../utils/productUUIDConstants';
var landingPageUrls = (_a = {},
    _a[PRODUCTUUID.BB] = 'https://www.brooksbrotherscreditcard.com/client',
    _a[PRODUCTUUID.EB] = 'https://eddiebauer.imprint.co/client',
    _a);
export function getLandingPageUrl(productUUID) {
    return landingPageUrls[productUUID];
}
export var PARAM_IN_STORE = 'in_store';
export var PARAM_SHOW_WPP = 'showTapToPay';
// temporarily used while addToWallet is disabled
export var IMPRINT_STORE_HELP_URL = 'https://help.imprint.co/store';
