import { PKG } from '../analytics/analyticsConsts';
import { API_CONFIG } from '../api/api.types';
import { ResponseCode } from '../api/http/httpConsts';
import { ANALYTICS } from '../firebase/firebase';
export function emptyApiResponse() {
    return {
        data: {
            body: {},
        },
        status: ResponseCode.HTTP_ERROR,
        message: '',
    };
}
// TODO: add tests
var constructErrorDetails = function (apiName, obj, reason) {
    var _a;
    return ({
        status: obj && obj.status ? obj.status : 'No status',
        message: obj && obj.message ? obj.message : 'No error message',
        url: ((_a = API_CONFIG[apiName]) === null || _a === void 0 ? void 0 : _a.baseUrl) || 'No url',
        reason: reason || undefined,
    });
};
export function handleApiError(apiName, obj, reason) {
    var errorDetails = constructErrorDetails(apiName, obj, reason);
    ANALYTICS.funcError(PKG, apiName, JSON.stringify(errorDetails));
}
// takes a response code, a mapping of response codes to handlers, and a generic error handler
// handlers must be able to take store, response, and optional params
export function getHandler(responseStatus, handlers, genericError) {
    return function (store, response, params) {
        (handlers[responseStatus] || genericError)(store, response, params);
    };
}
