import { getAnalytics } from 'firebase/analytics';
import AnalyticsUtil from './analyticsUtil';
import { store } from '../app/store';
import { isDev } from '../utils/environments';
var AnalyticsWrapper = /** @class */ (function () {
    function AnalyticsWrapper(firebaseApp) {
        this.analyticsObj = getAnalytics(firebaseApp);
        this.analyticsUtil = new AnalyticsUtil();
    }
    AnalyticsWrapper.prototype.logEvent = function (eventName, parameters, description) {
        if (isDev()) {
            // this is to make testing analytics easier
            console.log('logging event', {
                eventName: eventName,
                parameters: parameters,
                description: description,
            });
        }
        var sessionID = store.getState().apiScreenScreenSelector.sessionID;
        if (!parameters)
            parameters = {};
        if (sessionID !== '') {
            parameters.session_id = sessionID;
        }
        if (description) {
            parameters.description = description;
        }
        this.analyticsUtil.writeEvent(eventName, parameters, this.analyticsObj);
    };
    AnalyticsWrapper.prototype.funcError = function (pkgName, funcName, reason) {
        this.analyticsUtil.funcError(pkgName, funcName, reason);
    };
    return AnalyticsWrapper;
}());
export default AnalyticsWrapper;
