var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import styles from './Collapse.module.css';
import numberOne from '../../assets/icons/number-circle-1.svg';
import numberTwo from '../../assets/icons/number-circle-2.svg';
import numberThree from '../../assets/icons/number-circle-3.svg';
import collapseDown from '../../assets/images/collapse-down.png';
import collapseUP from '../../assets/images/collapse-up.png';
import questionMark from '../../assets/icons/question.svg';
import { PLATFORM } from '../../assets/consts/const';
import { KEYTYPE } from '../../utils/keyConstants';
import { BreakpointsPx, ImpContainer, ImpFlex, ImpHidden, ImpPad, ImpText, colors, } from '@imprint-payments/imprint-ui';
export default function Collapse(_a) {
    var _b = _a.isOffer, isOffer = _b === void 0 ? false : _b, number = _a.number, title = _a.title, notice = _a.notice, close = _a.close, children = _a.children, detailPopup = _a.detailPopup, offerValue = _a.offerValue, style = _a.style, dropdownClick = _a.dropdownClick, platform = _a.platform;
    var _c = useState(numberOne), numberIcon = _c[0], setNumberIcon = _c[1];
    var _d = useState(''), closeIcon = _d[0], setCloseIcon = _d[1];
    useEffect(function () {
        switch (number) {
            case 1: {
                setNumberIcon(numberOne);
                break;
            }
            case 2: {
                setNumberIcon(numberTwo);
                break;
            }
            case 3: {
                setNumberIcon(numberThree);
                break;
            }
        }
    }, [number]);
    useEffect(function () {
        if (close) {
            setCloseIcon(collapseDown);
        }
        else {
            setCloseIcon(collapseUP);
        }
    }, [close]);
    var getHeaderStyle = function () {
        return style == 'offer' && platform === PLATFORM.computer
            ? styles.headerLeftOffer
            : (style == 'offer' && platform === PLATFORM.tablet) ||
                (style == 'offer' && platform === PLATFORM.mobile)
                ? styles.headerLeftOfferMobile
                : styles.headerLeft;
    };
    var getHeaderTextWrapperStyle = function () {
        return style == 'offer' && platform === PLATFORM.computer
            ? styles.headerTextWrapperOffer
            : (style == 'offer' && platform === PLATFORM.tablet) ||
                (style == 'offer' && platform === PLATFORM.mobile)
                ? styles.headerTextWrapperOfferMobile
                : styles.headerTextWrapper;
    };
    return (_jsxs(ImpContainer, __assign({ width: "100%" }, { children: [_jsx(ImpPad, __assign({ padding: "24px 0px" }, { children: _jsxs(ImpFlex, __assign({ direction: "row", justify: "space-between", fluid: true }, { children: [_jsx("div", __assign({ className: "".concat(styles.header, " ").concat(close && style !== 'offer' && styles.closeHeader), id: number === 1 ? 'personalInfoHeader' : 'incomeHeader', onClick: dropdownClick }, { children: _jsx("div", __assign({ className: getHeaderStyle() }, { children: _jsx("div", __assign({ className: getHeaderTextWrapperStyle() }, { children: _jsxs(ImpFlex, __assign({ direction: "row", align: "center", gap: "s" }, { children: [number && (_jsx("img", { alt: number.toString(), src: numberIcon, className: styles.circleNumber, tabIndex: 0 })), _jsxs(ImpContainer, { children: [_jsx(ImpFlex, __assign({ direction: "row", align: "center" }, { children: _jsxs(ImpText, __assign({ typography: isOffer ? 'body2' : 'headline3' }, { children: [title, detailPopup && (_jsx("img", { tabIndex: 0, alt: "detail pop up icon", className: styles.questionMarkIcon, src: questionMark, onClick: function (e) {
                                                                        e.stopPropagation();
                                                                        detailPopup();
                                                                    }, onKeyDown: function (e) {
                                                                        if (e.key.toUpperCase() === KEYTYPE.ENTER) {
                                                                            e.stopPropagation();
                                                                            detailPopup();
                                                                        }
                                                                    } }))] })) })), !close && notice && (_jsx(ImpHidden, __assign({ hideBelow: BreakpointsPx.Mobile }, { children: _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpText, __assign({ typography: "label3", color: colors.content.onBackgroundSuccess }, { children: notice })) })) })))] }), isOffer && dropdownClick && (_jsx("div", __assign({ onClick: dropdownClick, style: { cursor: 'pointer', marginLeft: '0px' } }, { children: _jsx("img", { alt: "close icon", className: style === 'offer'
                                                        ? styles.collapseIconOffer
                                                        : styles.collapseIcon, tabIndex: 0, src: closeIcon, onClick: dropdownClick, onKeyDown: function (e) {
                                                        if (e.key.toUpperCase() === KEYTYPE.ENTER) {
                                                            dropdownClick();
                                                        }
                                                    } }) })))] })) })) })) })), _jsxs(ImpFlex, __assign({ direction: "row", align: "center" }, { children: [dropdownClick && !isOffer && (_jsx("div", __assign({ onClick: dropdownClick, style: { cursor: 'pointer', marginLeft: '0px' } }, { children: _jsx("img", { alt: "close icon", className: style === 'offer'
                                            ? styles.collapseIconOffer
                                            : styles.collapseIcon, tabIndex: 0, src: closeIcon, onClick: dropdownClick, onKeyDown: function (e) {
                                            if (e.key.toUpperCase() === KEYTYPE.ENTER) {
                                                dropdownClick();
                                            }
                                        } }) }))), offerValue && (_jsx(ImpPad, __assign({ padding: "0 24px 0 8px" }, { children: _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onBackground }, { children: offerValue })) })))] }))] })) })), _jsx("div", __assign({ className: close
                    ? styles.hide
                    : style == 'offer'
                        ? styles.childrenOffer
                        : styles.children }, { children: children }))] })));
}
