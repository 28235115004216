export var selectScreenState = function (state) {
    return state.screenStateSelector;
};
export var selectApplicationScreenSelector = function (state) {
    return state.applicationScreenSelector;
};
export var selectLoginSelector = function (state) { return state.loginSelector; };
export var selectAppStatus = function (state) {
    return state.applicationStatusSelector;
};
export var selectConsumerProfile = function (state) {
    return state.consumerInfoSelector.consumerProfile;
};
export var selectApiScreenState = function (state) {
    return state.apiScreenScreenSelector;
};
