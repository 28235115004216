// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aT3UG9SqVg5585ThAJvA {
  display: inline;
  width: min(534px, 100%);
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 12px;
  box-sizing: border-box;
  background: var(--white);
  border: 1px solid #d3d1cf;
}

.aT3UG9SqVg5585ThAJvA > div:not(:last-child) {
  border-bottom: 1px solid #f0eeec;
}

.FB5DXJcS00cl48CgXXSY {
  display: flex;
  justify-content: center;
}

.rVxMC9dT_Qfp7sK2V9hw {
  margin-left: 20px;
  width: fit-content;
  height: fit-content;
  display: inline;
}

@media (max-width: 566px) {
  .aT3UG9SqVg5585ThAJvA {
    margin-left: 16px;
    margin-right: 16px;
  }
}

@media (max-width: 480px) {
  .aT3UG9SqVg5585ThAJvA {
    padding: 0px 16px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/application/Application.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;EACnB,sBAAsB;EACtB,wBAAwB;EACxB,yBAAyB;AAC3B;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE;IACE,iBAAiB;IACjB,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".collapseContainer {\n  display: inline;\n  width: min(534px, 100%);\n  padding-left: 24px;\n  padding-right: 24px;\n  border-radius: 12px;\n  box-sizing: border-box;\n  background: var(--white);\n  border: 1px solid #d3d1cf;\n}\n\n.collapseContainer > div:not(:last-child) {\n  border-bottom: 1px solid #f0eeec;\n}\n\n.container {\n  display: flex;\n  justify-content: center;\n}\n\n.creditContainer {\n  margin-left: 20px;\n  width: fit-content;\n  height: fit-content;\n  display: inline;\n}\n\n@media (max-width: 566px) {\n  .collapseContainer {\n    margin-left: 16px;\n    margin-right: 16px;\n  }\n}\n\n@media (max-width: 480px) {\n  .collapseContainer {\n    padding: 0px 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collapseContainer": `aT3UG9SqVg5585ThAJvA`,
	"container": `FB5DXJcS00cl48CgXXSY`,
	"creditContainer": `rVxMC9dT_Qfp7sK2V9hw`
};
module.exports = ___CSS_LOADER_EXPORT___;
