var _a;
import { createSlice } from '@reduxjs/toolkit';
export var OTPFLOW;
(function (OTPFLOW) {
    OTPFLOW[OTPFLOW["APPLICATION"] = 0] = "APPLICATION";
    OTPFLOW[OTPFLOW["STATUS"] = 1] = "STATUS";
})(OTPFLOW || (OTPFLOW = {}));
var initialState = {
    isWrongOTPCode: false,
    flow: OTPFLOW.APPLICATION,
    reachedMaxRetries: false,
    isIncorrectOtpCode: false,
};
var otpScreenSlice = createSlice({
    name: 'otpScreen',
    initialState: initialState,
    reducers: {
        wrongOtpCode: function (state, action) {
            state.isWrongOTPCode = action.payload;
        },
        setOtpFlow: function (state, action) {
            state.flow = action.payload;
        },
        setReachedMaxRetries: function (state, action) {
            state.reachedMaxRetries = action.payload;
        },
        setIncorrectOtpCode: function (state, action) {
            state.isIncorrectOtpCode = action.payload;
        },
    },
});
export var wrongOtpCode = (_a = otpScreenSlice.actions, _a.wrongOtpCode), setOtpFlow = _a.setOtpFlow, setReachedMaxRetries = _a.setReachedMaxRetries, setIncorrectOtpCode = _a.setIncorrectOtpCode;
export default otpScreenSlice.reducer;
