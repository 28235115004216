export var DocumentUrl;
(function (DocumentUrl) {
    DocumentUrl["DEMO_TERMS"] = "https://www.imprint.co/legal/imprint-rewards-terms";
    DocumentUrl["DEMO_CISN"] = "https://imprint.co/legal/imprint-cardholder-information-sharing-notice";
    DocumentUrl["HH_CHA_EN"] = "https://www.imprint.co/en-us/horizon-hobby/credit-cardholder-agreement";
    DocumentUrl["HH_CHA_ES"] = "https://www.imprint.co/es-us/horizon-hobby/credit-cardholder-agreement";
    DocumentUrl["HH_CISN_EN"] = "https://horizonhobby.imprint.co/credit/legal/cardholder-information-sharing-notice/en-us";
    DocumentUrl["HH_CISN_ES"] = "https://horizonhobby.imprint.co/credit/legal/cardholder-information-sharing-notice/es-us";
    DocumentUrl["HH_TERMS_EN"] = "https://www.imprint.co/legal/horizon-hobby-rewards-terms";
    DocumentUrl["HH_TERMS_ES"] = "https://www.imprint.co/es-us/horizon-hobby/rewards-terms";
    DocumentUrl["WG_CHA_EN"] = "https://www.imprint.co/en-us/westgate-resorts/credit-cardholder-agreement";
    DocumentUrl["WG_CHA_ES"] = "https://www.imprint.co/es-us/westgate-resorts/credit-cardholder-agreement";
    DocumentUrl["WG_CISN_EN"] = "https://wowmastercard.com/credit/legal/cardholder-information-sharing-notice/en-us";
    DocumentUrl["WG_CISN_ES"] = "https://wowmastercard.com/credit/legal/cardholder-information-sharing-notice/es-us";
    DocumentUrl["WG_TERMS_EN"] = "https://www.imprint.co/legal/westgate-resorts-rewards-terms";
    DocumentUrl["WG_TERMS_ES"] = "https://www.imprint.co/es-us/westgate-resorts/rewards-terms";
    DocumentUrl["HEB_CHA_EN"] = "https://heb.imprint.co/credit/legal/credit-cardholder-agreement/en-us";
    DocumentUrl["HEB_CHA_ES"] = "https://heb.imprint.co/credit/legal/credit-cardholder-agreement/es-us";
    DocumentUrl["HEB_CISN_EN"] = "https://heb.imprint.co/credit/legal/cardholder-information-sharing-notice/en-us";
    DocumentUrl["HEB_CISN_ES"] = "https://heb.imprint.co/credit/legal/cardholder-information-sharing-notice/es-us";
    DocumentUrl["HEB_TERMS_EN"] = "https://heb.imprint.co/credit/legal/rewards-terms/en-us";
    DocumentUrl["HEB_TERMS_ES"] = "https://heb.imprint.co/credit/legal/rewards-terms/es-us";
    DocumentUrl["CM_CHA_EN"] = "https://centralmarket.imprint.co/credit/legal/credit-cardholder-agreement/en-us";
    DocumentUrl["CM_CHA_ES"] = "https://centralmarket.imprint.co/credit/legal/credit-cardholder-agreement/es-us";
    DocumentUrl["CM_CISN_EN"] = "https://centralmarket.imprint.co/credit/legal/cardholder-information-sharing-notice/en-us";
    DocumentUrl["CM_CISN_ES"] = "https://centralmarket.imprint.co/credit/legal/cardholder-information-sharing-notice/es-us";
    DocumentUrl["CM_TERMS_EN"] = "https://centralmarket.imprint.co/credit/legal/rewards-terms/en-us";
    DocumentUrl["CM_TERMS_ES"] = "https://centralmarket.imprint.co/credit/legal/rewards-terms/es-us";
    DocumentUrl["HICV_CHA_EN"] = "https://holidayinnclub.imprint.co/credit/legal/credit-cardholder-agreement/en-us";
    DocumentUrl["HICV_CHA_ES"] = "https://holidayinnclub.imprint.co/credit/legal/credit-cardholder-agreement/es-us";
    DocumentUrl["HICV_CISN_EN"] = "https://holidayinnclub.imprint.co/credit/legal/cardholder-information-sharing-notice/en-us";
    DocumentUrl["HICV_CISN_ES"] = "https://holidayinnclub.imprint.co/credit/legal/cardholder-information-sharing-notice/es-us";
    DocumentUrl["HICV_TERMS_EN"] = "https://holidayinnclub.imprint.co/credit/legal/rewards-terms/en-us";
    DocumentUrl["HICV_TERMS_ES"] = "https://holidayinnclub.imprint.co/credit/legal/rewards-terms/es-us";
    DocumentUrl["TURKISH_CHA_EN"] = "https://turkishairlines.imprint.co/premier/credit/legal/credit-cardholder-agreement/en-us";
    DocumentUrl["TURKISH_CHA_ES"] = "https://turkishairlines.imprint.co/premier/credit/legal/credit-cardholder-agreement/es-us";
    DocumentUrl["TURKISH_CISN_EN"] = "https://turkishairlines.imprint.co/premier/credit/legal/cardholder-information-sharing-notice/en-us";
    DocumentUrl["TURKISH_CISN_ES"] = "https://turkishairlines.imprint.co/premier/credit/legal/cardholder-information-sharing-notice/es-us";
    DocumentUrl["TURKISH_TERMS_EN"] = "https://turkishairlines.imprint.co/premier/credit/legal/rewards-terms/en-us";
    DocumentUrl["TURKISH_TERMS_ES"] = "https://turkishairlines.imprint.co/premier/credit/legal/rewards-terms/es-us";
    DocumentUrl["EB_CHA_EN"] = "https://eddiebauer.imprint.co/credit/legal/credit-cardholder-agreement/en-us";
    DocumentUrl["EB_CHA_ES"] = "https://eddiebauer.imprint.co/credit/legal/credit-cardholder-agreement/es-us";
    DocumentUrl["EB_CISN_EN"] = "https://eddiebauer.imprint.co/credit/legal/cardholder-information-sharing-notice/en-us";
    DocumentUrl["EB_CISN_ES"] = "https://eddiebauer.imprint.co/credit/legal/cardholder-information-sharing-notice/es-us";
    DocumentUrl["EB_TERMS_EN"] = "https://eddiebauer.imprint.co/credit/legal/rewards-terms/en-us";
    DocumentUrl["EB_TERMS_ES"] = "https://eddiebauer.imprint.co/credit/legal/rewards-terms/es-us";
    DocumentUrl["BB_CHA_EN"] = "https://brooksbrotherscreditcard.com/credit/legal/credit-cardholder-agreement/en-us";
    DocumentUrl["BB_CHA_ES"] = "https://brooksbrotherscreditcard.com/credit/legal/credit-cardholder-agreement/es-us";
    DocumentUrl["BB_CISN_EN"] = "https://brooksbrotherscreditcard.com/credit/legal/cardholder-information-sharing-notice/en-us";
    DocumentUrl["BB_CISN_ES"] = "https://brooksbrotherscreditcard.com/credit/legal/cardholder-information-sharing-notice/es-us";
    DocumentUrl["BB_TERMS_EN"] = "https://brooksbrotherscreditcard.com/credit/legal/rewards-terms/en-us";
    DocumentUrl["BB_TERMS_ES"] = "https://brooksbrotherscreditcard.com/credit/legal/rewards-terms/es-us";
})(DocumentUrl || (DocumentUrl = {}));
export var ZendeskUrl;
(function (ZendeskUrl) {
    ZendeskUrl["DEFAULT"] = "https://imprintpayments.zendesk.com";
    ZendeskUrl["HH_EN"] = "https://horizonhobbysupport.zendesk.com/hc/en-us";
    ZendeskUrl["HH_ES"] = "https://horizonhobbysupport.zendesk.com/hc/es-us";
    ZendeskUrl["WG_EN"] = "https://westgatesupport.zendesk.com/hc/en-us";
    ZendeskUrl["WG_ES"] = "https://westgatesupport.zendesk.com/hc/es";
    ZendeskUrl["HEB_EN"] = "https://heb.imprint.co/credit/help/en-us";
    ZendeskUrl["HEB_ES"] = "https://heb.imprint.co/credit/help/es-us";
    ZendeskUrl["CM_EN"] = "https://centralmarket.imprint.co/credit/help/en-us";
    ZendeskUrl["CM_ES"] = "https://centralmarket.imprint.co/credit/help/es-us";
    ZendeskUrl["HICV_EN"] = "https://holidayinnclub.imprint.co/credit/help/en-us";
    ZendeskUrl["HICV_ES"] = "https://holidayinnclub.imprint.co/credit/help/es-us";
    ZendeskUrl["TURKISH_EN"] = "https://turkishairlinescredit.zendesk.com/hc/en-us";
    ZendeskUrl["TURKISH_ES"] = "https://turkishairlinescredit.zendesk.com/hc/es-us";
    ZendeskUrl["EB_EN"] = "https://eddiebauer.imprint.co/credit/help/en-us";
    ZendeskUrl["EB_ES"] = "https://eddiebauer.imprint.co/credit/help/es-us";
    ZendeskUrl["BB_EN"] = "https://brooksbrothers.imprint.co/credit/help/en-us";
    ZendeskUrl["BB_ES"] = "https://brooksbrothers.imprint.co/credit/help/es-us";
})(ZendeskUrl || (ZendeskUrl = {}));
export var MerchantQRInfo = {
    HH: {
        iosLink: 'https://apps.apple.com/us/app/imprint-app/id1570296831?ppid:b3355b44-2b16-4bf9-aeb1-a3f5d01f7017',
        androidLink: 'https://play.google.com/store/apps/details?id=co.imprint.consumer&listing=horizonhobbycredit',
        lpName: 'horizon_hobby_lp',
    },
    WG: {
        iosLink: 'https://apps.apple.com/us/app/imprint-app/id1570296831?ppid=5b05ddd9-d7e3-4e9d-95cb-746dc1682eb8',
        androidLink: 'https://play.google.com/store/apps/details?id=co.imprint.consumer&listing=westgate',
        lpName: 'westgate_lp',
    },
};
