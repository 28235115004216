var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpOfferPage, ImpSpacer } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { getCardDesignOptions } from '../../api/api';
import { setCardDesignSelection } from '../../app/acceptScreenSlice';
import { useImpState } from '../../app/hooks';
import { ANALYTICS } from '../../firebase/firebase';
import { PRODUCTUUID } from '../../utils/productUUIDConstants';
import InStoreModal from '../application/InStoreModal';
import Confetti from '../Confetti/Confetti';
import { useOfferPageProps } from './OfferHeaderUtils';
export default function OfferHeader() {
    var _a = useImpState(), merchantData = _a.merchantData, consumerInfo = _a.consumerInfo, dispatch = _a.dispatch;
    var _b = useState(false), openInStoreModal = _b[0], setOpenInStoreModal = _b[1];
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.OFFER_VIEWED);
        window.scrollTo(0, 0);
    }, []);
    useEffect(function () {
        if (consumerInfo.cardOffer.cardDesignOptions &&
            consumerInfo.cardOffer.cardDesignOptions.length > 1) {
            for (var _i = 0, _a = consumerInfo.cardOffer.cardDesignOptions; _i < _a.length; _i++) {
                var cardDesignOption = _a[_i];
                var productUUID = merchantData.productUUID;
                dispatch(getCardDesignOptions(cardDesignOption.cardDesignID, productUUID));
            }
            dispatch(setCardDesignSelection(true));
        }
    }, [consumerInfo.cardOffer]);
    function getConfetti() {
        switch (merchantData.productUUID) {
            case PRODUCTUUID.HEB:
            case PRODUCTUUID.CM:
                return _jsx(Confetti, {});
            default:
                return null;
        }
    }
    return (_jsxs(_Fragment, { children: [getConfetti(), _jsx(ImpSpacer, { height: "2xl", mobileHeight: "xl" }), openInStoreModal && _jsx(InStoreModal, { sourcePage: "offer" }), _jsx(ImpOfferPage, __assign({}, useOfferPageProps(function () {
                setOpenInStoreModal(true);
            })))] }));
}
