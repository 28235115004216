import { configureStore } from '@reduxjs/toolkit';
import acceptScreenReducer from './acceptScreenSlice';
import apiScreenSlice from './apiScreenSlice';
import applicationScreenReducer from './applicationScreenSlice';
import applicationStatusReducer from './applicationStatusSlice';
import cardDesignReducer from './cardDesignSlice';
import consumerInfoReducer from './consumerInfoSlice';
import featureFlagSlice from './featureFlagSlice';
import homeReducer from './homeSlice';
import languageReducer from './languageSlice';
import loginReducer from './loginSlice';
import merchantDataReducer from './merchantDataSlice';
import otpScreenReducer from './otpScreenSlice';
import screenStateReducer from './screenStateSlice';
import validatorReducer from './validatorSlice';
export var store = configureStore({
    reducer: {
        languageSelector: languageReducer,
        screenStateSelector: screenStateReducer,
        otpScreenSelector: otpScreenReducer,
        applicationScreenSelector: applicationScreenReducer,
        consumerInfoSelector: consumerInfoReducer,
        merchantDataSelector: merchantDataReducer,
        applicationStatusSelector: applicationStatusReducer,
        acceptScreenSelector: acceptScreenReducer,
        apiScreenScreenSelector: apiScreenSlice,
        validatorSelector: validatorReducer,
        featureFlagSelector: featureFlagSlice,
        cardDesignSelector: cardDesignReducer,
        loginSelector: loginReducer,
        homeSelector: homeReducer,
    },
});
