var _a;
import { createSlice } from '@reduxjs/toolkit';
export var SECTION;
(function (SECTION) {
    SECTION[SECTION["PERSONALINFO"] = 0] = "PERSONALINFO";
    SECTION[SECTION["INCOME"] = 1] = "INCOME";
})(SECTION || (SECTION = {}));
export var MODAL_ENUM;
(function (MODAL_ENUM) {
    MODAL_ENUM[MODAL_ENUM["noModal"] = 0] = "noModal";
    MODAL_ENUM[MODAL_ENUM["showDetailModal"] = 1] = "showDetailModal";
    MODAL_ENUM[MODAL_ENUM["showCreditAPRModal"] = 2] = "showCreditAPRModal";
    MODAL_ENUM[MODAL_ENUM["showHowToUnfreezeModal"] = 3] = "showHowToUnfreezeModal";
    MODAL_ENUM[MODAL_ENUM["showLanguageDisclaimerModal"] = 4] = "showLanguageDisclaimerModal";
    MODAL_ENUM[MODAL_ENUM["showLoginModal"] = 5] = "showLoginModal";
    MODAL_ENUM[MODAL_ENUM["showWelcomeBackInStoreModal"] = 6] = "showWelcomeBackInStoreModal";
})(MODAL_ENUM || (MODAL_ENUM = {}));
var initialState = {
    requireFullSSN: false,
    section: SECTION.PERSONALINFO,
    showModal: MODAL_ENUM.noModal,
    eligibilityVerified: false,
    loading: false,
    isSubmittingApp: false,
    subscribed: false,
    showLinkHHScreen: false,
    fullNumberEntered: false,
    showWelcomeBackCard: false,
    proveSessionId: '',
};
var applicationScreenSlice = createSlice({
    name: 'applicationScreen',
    initialState: initialState,
    reducers: {
        requireFullSSN: function (state, action) {
            state.requireFullSSN = action.payload;
        },
        setSection: function (state, action) {
            state.section = action.payload;
        },
        setShowModal: function (state, action) {
            state.showModal = action.payload;
        },
        setEligibilityVerified: function (state, action) {
            state.eligibilityVerified = action.payload;
        },
        setLoading: function (state, action) {
            state.loading = action.payload;
        },
        setIsSubmittingApp: function (state, action) {
            state.isSubmittingApp = action.payload;
        },
        setSubscribed: function (state, action) {
            state.subscribed = action.payload;
        },
        setShowLinkHHScreen: function (state, action) {
            state.showLinkHHScreen = action.payload;
        },
        setFullNumberEntered: function (state, action) {
            state.fullNumberEntered = action.payload;
        },
        setShowWelcomeBackCard: function (state, action) {
            state.showWelcomeBackCard = action.payload;
        },
        setInStoreDevice: function (state, action) {
            state.inStoreDevice = action.payload;
        },
        setProveSessionId: function (state, action) {
            state.proveSessionId = action.payload;
        },
    },
});
export var requireFullSSN = (_a = applicationScreenSlice.actions, _a.requireFullSSN), setSection = _a.setSection, setShowModal = _a.setShowModal, setEligibilityVerified = _a.setEligibilityVerified, setLoading = _a.setLoading, setIsSubmittingApp = _a.setIsSubmittingApp, setSubscribed = _a.setSubscribed, setShowLinkHHScreen = _a.setShowLinkHHScreen, setFullNumberEntered = _a.setFullNumberEntered, setShowWelcomeBackCard = _a.setShowWelcomeBackCard, setInStoreDevice = _a.setInStoreDevice, setProveSessionId = _a.setProveSessionId;
export default applicationScreenSlice.reducer;
