var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import styles from './Button.module.css';
import { KEYTYPE } from '../../utils/keyConstants';
export default function Button(_a) {
    var value = _a.value, pill = _a.pill, empty = _a.empty, borderless = _a.borderless, payButton = _a.payButton, icon = _a.icon, color = _a.color, disabled = _a.disabled, hideOnMobile = _a.hideOnMobile, loading = _a.loading, handleClick = _a.handleClick;
    useEffect(function () { }, [icon, disabled]);
    var getClasses = function () {
        var classList = [''];
        if (disabled) {
            classList.push(styles.disabled);
        }
        if (pill) {
            classList.push(styles.pill);
        }
        if (empty) {
            classList.push(styles.empty);
        }
        if (borderless) {
            classList.push(styles.borderless);
        }
        if (hideOnMobile) {
            classList.push(styles.hideOnMobile);
        }
        if (payButton) {
            classList.push(styles.payButton);
        }
        return classList.join(' ');
    };
    return (_jsxs("div", { children: [_jsx("button", __assign({ className: getClasses(), onClick: handleClick, "aria-label": disabled ? 'disabled' + value : value, tabIndex: 0, onKeyDown: function (e) {
                    if (e.key.toUpperCase() === KEYTYPE.ENTER) {
                        handleClick();
                    }
                } }, { children: !loading ? value : '' })), getClasses().includes(styles.pill) && !disabled && loading && (_jsxs("div", __assign({ className: styles.spinner }, { children: [_jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {})] })))] }));
}
