var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpInput } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { checkEligibility } from '../../api/api';
import { URL } from '../../api/apiConstants';
import { useAppSelector, useImpState } from '../../app/hooks';
import rcClub from '../../assets/images/rc-club.svg';
import { ANALYTICS } from '../../firebase/firebase';
import { ValidationResponse, noError, validateEmail } from '../../utils/validateUtils';
import Button from '../button/Button';
import styles from './LinkHHAccount.module.css';
export default function LinkHHAccount(_a) {
    var consumer = _a.consumer, productUUID = _a.productUUID, loadingBtn = _a.loadingBtn, callback = _a.callback;
    var _b = useImpState(), language = _b.language, consumerInfo = _b.consumerInfo, appScreenState = _b.appScreenState, dispatch = _b.dispatch;
    var _c = useState(consumer.email), customerEmail = _c[0], setCustomerEmail = _c[1];
    var _d = useState(true), disabledBtn = _d[0], setDisabledBtn = _d[1];
    var _e = useState(false), emailVerificationFailure = _e[0], setEmailVerificationFailure = _e[1];
    var validator = useAppSelector(function (state) { return state.validatorSelector; });
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.RC_CLUB_PAGE_VIEWED);
    }, []);
    useEffect(function () {
        setDisabledBtn(true);
        if (validateEmail(customerEmail) === ValidationResponse.valid && customerEmail !== '') {
            setDisabledBtn(false);
        }
    }, [customerEmail, validator.emailError]);
    useEffect(function () {
        if (consumerInfo.emailVerificationFailed) {
            setEmailVerificationFailure(true);
        }
        else {
            setEmailVerificationFailure(false);
        }
    }, [consumerInfo.emailVerificationFailed]);
    useEffect(function () {
        if (appScreenState.eligibilityVerified) {
            callback();
        }
    }, [appScreenState.eligibilityVerified]);
    if (language === null) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return _jsx(_Fragment, {});
    }
    var handleContinueClick = function () {
        dispatch(checkEligibility({
            productUUID: productUUID,
            email: customerEmail,
        }));
    };
    var rcErrorResponse = function (ineligible) {
        return ineligible ? ValidationResponse.invalidHhEmail : ValidationResponse.valid;
    };
    var handleRCClubClick = function () {
        window.open(URL.HH_WEBSITE, '_blank');
    };
    var renderInputField = function (nonHHEmail) {
        return (_jsx(ImpInput, { label: language.email, value: customerEmail, id: "HHInputField", onChange: function (e) { return setCustomerEmail(e.target.value); }, showError: !noError.includes(rcErrorResponse(nonHHEmail)), errorMessage: "" }));
    };
    return (_jsx("div", { children: _jsx("div", { children: !loadingBtn && (_jsxs("div", __assign({ className: styles.container }, { children: [_jsx("p", __assign({ className: styles.header }, { children: language.linkHHAccountHeader })), _jsx("p", __assign({ className: styles.body }, { children: language.linkHHAccountBody1 })), _jsx("p", __assign({ className: styles.body }, { children: language.linkHHAccountBody2 })), renderInputField(emailVerificationFailure), _jsx("div", __assign({ className: styles.continueBtnContainer }, { children: _jsx(Button, { value: language.continueBtn, pill: true, disabled: disabledBtn, handleClick: function () {
                                ANALYTICS.logEvent(EVENT.RC_CLUB_PAGE_CONTINUE_CLICKED);
                                handleContinueClick();
                            }, loading: loadingBtn }) })), _jsx("img", { alt: "logo", className: styles.rcClub, src: rcClub, onClick: function () {
                            ANALYTICS.logEvent(EVENT.RC_CLUB_PAGE_LINK_CLICKED, {
                                link: 'join_the_rc_club',
                            });
                            handleRCClubClick();
                        } })] }))) }) }));
}
