import { KEY } from './keyConstants';
export var formatPhoneNumberInput = function (inputText, keyCode, cursor, cursorEnd) {
    var finalStr = inputText;
    if ((keyCode === KEY.DEL || keyCode === KEY.BACKSPACE) &&
        cursor === cursorEnd) {
        if (cursor === 5 || cursor === 6) {
            finalStr = inputText.replace(/[^\d]/g, '');
            finalStr = finalStr.slice(0, 2) + finalStr.slice(3);
        }
        else if (cursor === 10 || cursor === 11 || cursor === 12) {
            finalStr = inputText.replace(/[^\d]/g, '');
            finalStr = finalStr.slice(0, 5) + finalStr.slice(6);
        }
    }
    return finalStr;
};
export var formatPhoneNumber = function (input) {
    if (!input)
        return input;
    var phoneNumber = input.replace(/[^\d]/g, '');
    var phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4)
        return phoneNumber;
    if (phoneNumberLength < 7) {
        return "(".concat(phoneNumber.slice(0, 3), ") ").concat(phoneNumber.slice(3));
    }
    return "(".concat(phoneNumber.slice(0, 3), ") ").concat(phoneNumber.slice(3, 6), " - ").concat(phoneNumber.slice(6, 10));
};
export var formatDob = function (value) {
    var currentDob = value.replace(/[^\d]/g, '');
    if (currentDob.length > 1 &&
        Math.max.apply(Math, currentDob.split('').map(function (val) { return +val; })) == 0) {
        return '';
    }
    var currentDobLength = currentDob.length;
    if (currentDobLength < 3)
        return currentDob;
    if (currentDobLength < 5)
        return "".concat(currentDob.slice(0, 2), "/").concat(currentDob.slice(2, 4));
    return "".concat(currentDob.slice(0, 2), "/").concat(currentDob.slice(2, 4), "/").concat(currentDob.slice(4, 8));
};
export var formatDateValue = function (date) {
    return String(date).padStart(2, '0');
};
export var formatNumberInput = function (input, maxLength) {
    var result = input.match(/\d/g);
    if (result) {
        return result.join('').substring(0, maxLength);
    }
    return '';
};
export var formatFullSSN = function (value) {
    var currentSSN = value.trim().replace(/[^\d]/g, '');
    var currentSSNLength = currentSSN.length;
    if (currentSSNLength < 4)
        return currentSSN;
    if (currentSSNLength < 6)
        return "".concat(currentSSN.slice(0, 3), "-").concat(currentSSN.slice(3, 5));
    return "".concat(currentSSN.slice(0, 3), "-").concat(currentSSN.slice(3, 5), "-").concat(currentSSN.slice(5, 9));
};
export var formatSSN = function (value) {
    return value.trim().replace(/[^\d]/g, '');
};
export var formatZipCode = function (input) {
    var result = input.match(/\d/g);
    if (result) {
        return result.join('').substring(0, 5);
    }
    return '';
};
export var formatState = function (input) {
    // Only accept letter characters
    var result = input.match(/^[A-Za-z]+$/g);
    if (result) {
        return result.join('').substring(0, 5);
    }
    return '';
};
export var inputNotEmpty = function (input) {
    for (var i = 0; i <= input.length; i++) {
        if (input[i] === '') {
            return false;
        }
    }
    return true;
};
export function displayNumberFromBE(val) {
    if (val === undefined) {
        return '--';
    }
    // remove trailing zeros if '.00'
    var toDisplay = val.displayValue;
    if (toDisplay.endsWith('.00')) {
        toDisplay = "".concat(toDisplay.substring(0, toDisplay.length - 3));
    }
    if (val.displaySymbol !== undefined && val.displaySymbol !== '') {
        return "".concat(val.displaySymbol).concat(toDisplay);
    }
    if (val.displayUnit !== undefined && val.displayUnit !== '') {
        return "".concat(toDisplay).concat(val.displayUnit);
    }
    return "".concat(toDisplay);
}
export function displayNumberNone(val, language) {
    if (val === undefined) {
        return '--';
    }
    if (val.valueInMinUnit === 0) {
        return language.none;
    }
    // remove trailing zeros if '.00'
    return displayNumberFromBE(val);
}
export var formatCurrency = function (value) {
    var val = value
        .replace('$', '')
        .trim()
        .replace(/[^\d.]/g, '');
    var dollar = val !== '' ? '$' : '';
    var amount = val.slice(0, 0) + dollar + val.slice(0, val.length);
    return amount;
};
export var currencyToNumber = function (value) {
    return Number(value.replace('$', '').replace(/,/g, ''));
};
export var inputToUSD = function (amount) {
    var toReturn = amount.trim().replace(/[^\d]/g, '');
    // adds a decimal two digits from the end
    // prettier-ignore
    switch (toReturn.length) {
        case 0:
            toReturn = '0.00';
            break;
        case 1:
            toReturn = "0.0".concat(toReturn);
            break;
        case 2:
            toReturn = "0.".concat(toReturn);
            break;
        default:
            toReturn = "".concat(toReturn.slice(0, toReturn.length - 2), ".").concat(toReturn.slice(toReturn.length - 2, toReturn.length));
            // removes leading zeros if the number is not a decimal
            if (toReturn[0] === '0' && toReturn[1] !== '.') {
                toReturn = toReturn.slice(1, toReturn.length);
            }
            break;
    }
    var parts = toReturn.split('.');
    var whole = parts[0];
    var decimal = parts[1];
    // places a comma for every 3 digits from the right
    if (whole.length > 3) {
        whole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    toReturn = "$".concat(whole, ".").concat(decimal);
    return toReturn;
};
export var getTodaysDateInNumbers = function () {
    var today = new Date();
    var year = today.getFullYear().toString();
    var month = (today.getMonth() + 1).toString().padStart(2, '0');
    var day = today.getDate().toString().padStart(2, '0');
    return year + month + day;
};
export var formatRewardsBonus = function (rewardsBonus) {
    var amount = rewardsBonus.amount, amountSubtext = rewardsBonus.amountSubtext;
    return "".concat(amount).concat(amountSubtext ? " ".concat(amountSubtext) : '');
};
