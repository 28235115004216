var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch } from 'react-redux';
import { EVENT } from '../../analytics/analyticsConsts';
import { URL } from '../../api/apiConstants';
import { MODAL_ENUM, setShowModal } from '../../app/applicationScreenSlice';
import { useAppSelector, useImpState } from '../../app/hooks';
import cirleOne from '../../assets/images/circle-one.png';
import cirleThree from '../../assets/images/circle-three.png';
import cirleTwo from '../../assets/images/circle-two.png';
import { ANALYTICS } from '../../firebase/firebase';
import styles from './OtpVerify.module.css';
export default function UnfreezeCreditModal() {
    var dispatch = useDispatch();
    var language = useImpState().language;
    var expiresAt = useAppSelector(function (state) { return state.applicationStatusSelector.expiresAt; });
    if (language === null) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return _jsx(_Fragment, {});
    }
    var handleLoginClick = function () {
        ANALYTICS.logEvent(EVENT.FROZEN_CREDIT_LINK_CLICKED, { link: 'log_in' });
        window.open(URL.TRANSUNION, '_blank');
    };
    var handleArticleClick = function () {
        ANALYTICS.logEvent(EVENT.FROZEN_CREDIT_LINK_CLICKED, {
            link: 'this_article',
        });
        window.open(URL.ARTICLE_LINK, '_blank');
    };
    return (_jsx("div", __assign({ className: styles.overlay }, { children: _jsxs("div", __assign({ className: styles.modal }, { children: [_jsx("a", { className: styles.close, onClick: function () { return dispatch(setShowModal(MODAL_ENUM.noModal)); } }), _jsx("p", __assign({ className: styles.header }, { children: language.unfreezeCreditHeader })), _jsxs("div", __assign({ className: styles.divPoint }, { children: [_jsx("img", { alt: "number1", className: styles.circleNum, src: cirleOne }), _jsx("p", __assign({ onClick: handleLoginClick, className: styles.link }, { children: language.logIn })), _jsx("p", __assign({ className: styles.bulletInlineText }, { children: language.unfreezeCreditBullet1 }))] })), _jsxs("div", __assign({ className: styles.divPoint }, { children: [_jsx("img", { alt: "number2", className: styles.circleNum, src: cirleTwo }), _jsx("p", __assign({ className: styles.bulletText }, { children: language.unfreezeCreditBullet2 }))] })), _jsxs("div", __assign({ className: styles.divPoint }, { children: [_jsx("img", { alt: "number3", className: styles.circleNum, src: cirleThree }), _jsx("p", __assign({ className: styles.bulletText }, { children: language.unfreezeCreditBullet3 }))] })), _jsxs("div", __assign({ className: styles.divPoint }, { children: [_jsxs("p", __assign({ className: styles.bulletInlineText }, { children: [language.unfreezeCreditBody1, expiresAt] })), _jsx("p", __assign({ className: styles.bulletInlineText }, { children: language.unfreezeCreditBody2 }))] })), _jsxs("div", __assign({ className: styles.divPoint }, { children: [_jsx("p", __assign({ className: styles.bulletInlineText }, { children: language.unfreezeCreditBody3 })), _jsx("p", __assign({ onClick: handleArticleClick, className: styles.linkInline }, { children: language.unfreezeCreditBody4 })), _jsx("p", __assign({ className: styles.bulletInlineText }, { children: language.unfreezeCreditBody5 }))] }))] })) })));
}
