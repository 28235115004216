var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import * as Sentry from '@sentry/browser';
import { logEvent } from 'firebase/analytics';
import { recordAnalyticsToSnowflake } from '../api/api';
import { useAppDispatch } from '../app/hooks';
import { store } from '../app/store';
import { getUtmSource } from '../lib/utmManager';
import { getProductUUIDFromDomain } from '../utils/environments';
import { getPreapprovalCodeFromSession } from '../utils/functionUtils';
import { BATCH_SIZE, COUNT1, DEFAULT_SAMPLE_RATE, ERROR, tagKeyFuncName, tagKeyPkgName, tagKeyReason, } from './analyticsConsts';
var AnalyticsEvent = /** @class */ (function () {
    function AnalyticsEvent(eventIn, parametersIn) {
        this.timestamp = Math.floor(Date.now() / 1000);
        this.event = eventIn;
        this.parameters = parametersIn;
        this.userAgent = (navigator === null || navigator === void 0 ? void 0 : navigator.userAgent) || '';
        this.consumerUUID = '';
        this.deviceUUID = '';
        this.appVersion = '';
        this.language = '';
        this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.platform = 'web';
    }
    // eslint-disable-next-line class-methods-use-this
    AnalyticsEvent.prototype.setLanguage = function (languageIn) {
        this.language = languageIn;
    };
    AnalyticsEvent.prototype.setConsumerUUID = function (consumerIn) {
        this.consumerUUID = consumerIn;
    };
    AnalyticsEvent.prototype.setDeviceUUID = function (deviceIn) {
        this.deviceUUID = deviceIn;
    };
    return AnalyticsEvent;
}());
export { AnalyticsEvent };
var CountMetric = /** @class */ (function () {
    function CountMetric(pkg, where, count, tags, sampleRate) {
        this.package = pkg;
        this.where = where;
        this.count = count;
        this.tags = tags;
        this.sampleRate = sampleRate;
    }
    return CountMetric;
}());
export { CountMetric };
var AnalyticsUtil = /** @class */ (function () {
    function AnalyticsUtil() {
        this.dispatch = useAppDispatch;
        this.eventList = [];
        this.deviceUUID = '';
        this.consumerUUID = '';
        this.countMetrics = [];
    }
    AnalyticsUtil.prototype.writeEvent = function (eventIn, parameters, analyticsObj) {
        var puuid = store.getState().merchantDataSelector.productUUID || '';
        var utmSource = getUtmSource(puuid);
        var preapprovalCode = getPreapprovalCodeFromSession();
        if (utmSource) {
            parameters.utm_source = utmSource;
        }
        if (preapprovalCode) {
            parameters.preapproval_code = preapprovalCode;
        }
        if (puuid !== '') {
            parameters.product_uuid = puuid;
        }
        else {
            getProductUUIDFromDomain(window.location.hostname.toLowerCase());
        }
        var parametersIsEmpty = Object.keys(parameters).length === 0;
        logEvent(analyticsObj, eventIn, parametersIsEmpty ? undefined : parameters);
        this.eventList.push(new AnalyticsEvent(eventIn, parameters));
        if (this.eventList.length === BATCH_SIZE) {
            this.writeStoredEvents();
            this.eventList = [];
        }
    };
    AnalyticsUtil.prototype.writeStoredEvents = function () {
        if (!this.eventList.length && !this.countMetrics.length) {
            return; // Exit if there are no events or metrics to process
        }
        var enhancedEvents = this.eventList.map(function (event) {
            var consumerUUID = store.getState().consumerInfoSelector.consumerUUID || '';
            var deviceUUID = store.getState().apiScreenScreenSelector.deviceID || '';
            var languageCode = store.getState().languageSelector.code || '';
            return __assign(__assign({}, event), { consumerUUID: consumerUUID, deviceUUID: deviceUUID, languageCode: languageCode });
        });
        var analyticsRequest = {
            events: enhancedEvents,
            metrics: this.countMetrics.length ? { countMetrics: this.countMetrics } : undefined,
        };
        var analyticsRequestData = JSON.stringify(analyticsRequest);
        if (enhancedEvents.length || this.countMetrics.length) {
            recordAnalyticsToSnowflake({ analyticsRequestData: analyticsRequestData });
        }
        this.eventList = [];
        this.countMetrics = [];
    };
    AnalyticsUtil.prototype.funcError = function (pkgName, funcName, reason) {
        var _a, _b;
        var tags = [tagKeyPkgName + pkgName, tagKeyFuncName + funcName, tagKeyReason + reason];
        Sentry.captureException(new Error("".concat(pkgName, " ").concat(funcName)), {
            tags: {
                pkgName: pkgName,
                funcName: funcName,
                reason: reason,
                consumerUUID: (_a = store.getState().consumerInfoSelector.consumerUUID) !== null && _a !== void 0 ? _a : '',
                deviceUUID: (_b = store.getState().apiScreenScreenSelector.deviceID) !== null && _b !== void 0 ? _b : '',
            },
        });
        var countMetric = new CountMetric(ERROR, funcName, COUNT1, tags, DEFAULT_SAMPLE_RATE);
        this.countMetrics.push(countMetric);
        this.writeStoredEvents();
    };
    return AnalyticsUtil;
}());
export default AnalyticsUtil;
