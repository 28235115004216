var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ImpContainer } from '@imprint-payments/imprint-ui';
import { getDomainFromMerchant } from '../../utils/environments';
import { useImpState } from '../../app/hooks';
export function LockupImage() {
    var merchantData = useImpState().merchantData;
    return (_jsx(ImpContainer, __assign({ cursor: "pointer", onClick: function () {
            window.location.href = getDomainFromMerchant(merchantData.productUUID);
        } }, { children: _jsx("img", { src: merchantData.lockupImage, alt: "lockup-img" }) })));
}
