var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Lottie from "@novemberfiveco/lottie-react-light";
import styles from './Confetti.module.css';
import confettiTop from '../../assets/animations/confetti-small-top.json';
import confettiLeft from '../../assets/animations/confetti-small-left.json';
import confettiRight from '../../assets/animations/confetti-small-right.json';
import confettiSm from '../../assets/animations/confetti.json';
export default function Confetti() {
    return (_jsxs("div", __assign({ className: styles.container }, { children: [_jsx("div", __assign({ className: styles.confettiSm }, { children: _jsx(Lottie, { animationData: confettiSm, loop: false }) })), _jsxs("div", __assign({ className: styles.confettiNormal }, { children: [_jsx(Lottie, { animationData: confettiTop, loop: false }), _jsxs("div", __assign({ className: styles.sides }, { children: [_jsx(Lottie, { animationData: confettiLeft, loop: false }), _jsx(Lottie, { animationData: confettiRight, loop: false })] }))] }))] })));
}
