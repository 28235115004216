export var URL;
(function (URL) {
    URL["CHECKOUT"] = "/v2/consumer/checkout";
    URL["LOGIN"] = "/v1/consumer/login";
    URL["APPLICATIONS"] = "/v1/applications";
    URL["APPS"] = "/v2/applications";
    URL["HOME"] = "/home";
    URL["VERIFY"] = "/v2/consumer/login/otp";
    URL["VERIFY_DOB"] = "/v1/consumer/mfa/dob";
    URL["VERIFY_PIN"] = "/v1/consumer/mfa/pin";
    URL["V3_APPLICATION_SC"] = "/v3/application/sc";
    URL["V2_APPLICATION_SC"] = "/v2/application/sc";
    URL["APPLICATION_EVALUATION"] = "/v2/application";
    URL["PROMPT"] = "/v2/application/prompt";
    URL["CHECK_APPLICATION"] = "/checkApplication";
    URL["HH_WEBSITE"] = "https://horizonhobby.com";
    URL["HEB_WEBSITE"] = "https://heb.com";
    URL["CM_WEBSITE"] = "https://www.centralmarket.com/";
    URL["TRANSUNION"] = "https://www.transunion.com/";
    URL["IMPRINT_PRIVACY"] = "https://www.imprint.co/en-us/legal/privacy-notice";
    URL["IMPRINT_PRIVACY_ES"] = "https://www.imprint.co/es-us/legal/privacy-notice";
    URL["IMPRINT_ESIGN"] = "https://www.imprint.co/en-us/legal/esign-policy";
    URL["IMPRINT_ESIGN_ES"] = "https://www.imprint.co/es-us/legal/esign-policy";
    URL["IMPRINT_TOS"] = "https://www.imprint.co/terms-of-service.pdf";
    URL["ARTICLE_LINK"] = "https://www.transunion.com/blog/credit-advice/what-should-i-do-after-i-freeze-my-credit";
    URL["OFFER"] = "/offer";
    URL["ACCEPT"] = "/accept";
    URL["REJECTION_PDF"] = "/v2/applications";
    URL["SUBSCRIBE"] = "/v1/subscribe";
    URL["CARD_ENDPOINT"] = "/v1/card";
    URL["ANALYTICS"] = "/v1/analytics";
    URL["LANGUAGE"] = "/v1/consumer/language";
    URL["PAYMENT_CONSENT"] = "https://imprint.co/legal/payment-consent";
    URL["CONSUMER_LOGOUT"] = "/v1/consumer/logout";
    URL["CREATE_PIN"] = "/v1/consumer/pin";
    URL["RESET_PIN"] = "/v1/account/pin";
    URL["HOME_API"] = "/v2/home";
})(URL || (URL = {}));
export var RESPONSE_CODE;
(function (RESPONSE_CODE) {
    RESPONSE_CODE[RESPONSE_CODE["NO_CONTENT"] = 204] = "NO_CONTENT";
})(RESPONSE_CODE || (RESPONSE_CODE = {}));
export var SIGN_UP_ELIGIBILITY_STATUS;
(function (SIGN_UP_ELIGIBILITY_STATUS) {
    SIGN_UP_ELIGIBILITY_STATUS["ELIGIBLE"] = "eligible";
    SIGN_UP_ELIGIBILITY_STATUS["REJECTED_WITHIN_COOLDOWN_WINDOW"] = "rejectedWithinCooldownWindow";
    SIGN_UP_ELIGIBILITY_STATUS["REJECTED_AFTER_COOLDOWN_WINDOW"] = "rejectedAfterCooldownWindow";
    SIGN_UP_ELIGIBILITY_STATUS["REAPPLY"] = "reapply";
    SIGN_UP_ELIGIBILITY_STATUS["FROZEN"] = "frozen";
})(SIGN_UP_ELIGIBILITY_STATUS || (SIGN_UP_ELIGIBILITY_STATUS = {}));
export var CHECKOUT_STATUS;
(function (CHECKOUT_STATUS) {
    CHECKOUT_STATUS["NEW_USER"] = "NEW_USER";
    CHECKOUT_STATUS["PRESIGNUP"] = "PRESIGNUP";
})(CHECKOUT_STATUS || (CHECKOUT_STATUS = {}));
export var CHECKOUT_APPLICATION_STATE;
(function (CHECKOUT_APPLICATION_STATE) {
    CHECKOUT_APPLICATION_STATE["INITIALIZED"] = "INITIALIZED";
    CHECKOUT_APPLICATION_STATE["PENDING"] = "PENDING";
    CHECKOUT_APPLICATION_STATE["PREAPPROVED"] = "PREAPPROVED";
    CHECKOUT_APPLICATION_STATE["FROZEN"] = "FROZEN";
    CHECKOUT_APPLICATION_STATE["REJECTED"] = "REJECTED";
    CHECKOUT_APPLICATION_STATE["EXPIRED"] = "EXPIRED";
    CHECKOUT_APPLICATION_STATE["APPROVED"] = "APPROVED";
})(CHECKOUT_APPLICATION_STATE || (CHECKOUT_APPLICATION_STATE = {}));
