export var BATCH_SIZE = 1;
export var COUNT1 = 1;
export var ERROR = 'errors';
export var DEFAULT_SAMPLE_RATE = 1.0;
export var tagKeyWhere = 'where:';
export var tagKeyPkgName = 'pkg:';
export var tagKeyFuncName = 'func:';
export var tagKeyReason = 'reason:';
export var PKG = 'WEB_APPLICATION';
/*
Event Logging
PM Spec: https://imprint.atlassian.net/wiki/spaces/PW/pages/1375961105/Web-based+sign-up+-+launch+blocking+P0s
*/
export var EVENT;
(function (EVENT) {
    // Personal Info
    EVENT["PERSONAL_INFO_VIEWED"] = "personal_info_viewed";
    EVENT["PERSONAL_INFO_FORM_FILLED_OUT"] = "personal_info_form_filled_out";
    EVENT["PERSONAL_INFO_CONTINUE_CLICKED"] = "personal_info_continue_clicked";
    EVENT["PERSONAL_INFO_CONTINUE_CLICKED_EMAIL_LOG"] = "personal_info_continue_clicked_email_log";
    // Income Housing
    EVENT["INCOME_HOUSING_VIEWED"] = "income_housing_viewed";
    EVENT["INCOME_HOUSING_FORM_FILLED_OUT"] = "income_housing_form_filled_out";
    EVENT["INCOME_HOUSING_SUBMIT_CLICKED"] = "income_housing_submit_clicked";
    EVENT["INCOME_HOUSING_LINK_CLICKED"] = "income_housing_link_clicked";
    EVENT["INCOME_HOUSING_INFO_EDU_VIEWED"] = "income_housing_info_edu_viewed";
    EVENT["INCOME_HOUSING_SMARTPHONE_PROMPT"] = "income_housing_smartphone_prompt";
    EVENT["INCOME_HOUSING_SMARTPHONE_CONFIRMED"] = "income_housing_smartphone_confirmed";
    EVENT["INCOME_HOUSING_SMARTPHONE_REJECTED"] = "income_housing_smartphone_rejected";
    EVENT["INCOME_HOUSING_SMARTPHONE_REJECTED_PROMPT"] = "income_housing_smartphone_rejected_prompt";
    EVENT["INCOME_HOUSING_EMAIL_SUBMITTED"] = "income_housing_email_submitted";
    EVENT["INCOME_HOUSING_CLOSED_SMARTPHONE_REJECT"] = "income_housing_closed_smartphone_reject";
    EVENT["INCOME_HOUSING_FULL_SSN_VIEWED"] = "income_housing_full_ssn_viewed";
    EVENT["INCOME_HOUSING_FULL_SSN_SUBMIT_CLICKED"] = "income_housing_full_ssn_submit_clicked";
    // OTP
    EVENT["OTP_VIEWED"] = "OTP_viewed";
    EVENT["OTP_RESEND_CODE_CLICKED"] = "OTP_resend_code_clicked";
    EVENT["OTP_TRY_AGAIN_VIEWED"] = "OTP_try_again_viewed";
    EVENT["OTP_TRY_AGAIN_RESEND_CODE_CLICKED"] = "OTP_try_again_resend_code_clicked";
    EVENT["OTP_TRY_AGAIN_SUBMITTED"] = "OTP_try_again_submitted";
    EVENT["OTP_SUBMITTED"] = "OTP_submitted";
    // Offer Page
    EVENT["OFFER_VIEWED"] = "offer_viewed";
    EVENT["OFFER_CONTINUE_CLICKED"] = "offer_continue_clicked";
    EVENT["OFFER_NO_THANKS_CLICKED"] = "offer_no_thanks_clicked";
    EVENT["OFFER_LINK_CLICKED"] = "offer_link_clicked";
    EVENT["OFFER_INFO_EDU_VIEWED"] = "offer_info_edu_viewed";
    // Offer App
    EVENT["OFFER_APP_DOWNLOAD_VIEWED"] = "offer_app_download_viewed";
    EVENT["OFFER_APP_DOWNLOAD_CONTACT_CLICKED"] = "offer_app_download_contact_clicked";
    // RC Page
    EVENT["RC_CLUB_PAGE_VIEWED"] = "rc_club_page_viewed";
    EVENT["RC_CLUB_PAGE_CONTINUE_CLICKED"] = "rc_club_page_continue_clicked";
    EVENT["RC_CLUB_PAGE_LINK_CLICKED"] = "rc_club_page_link_clicked";
    // App states
    EVENT["APP_DECLINE_VIEWED"] = "app_decline_viewed";
    EVENT["APP_DECLINE_TRY_AGAIN_CLICKED"] = "app_decline_try_again_clicked";
    EVENT["APP_DECLINE_LINK_CLICKED"] = "app_decline_link_clicked";
    EVENT["APP_ASYNC_APPLICATION_PROCESSING_VIEWED"] = "async_application_processing_viewed";
    EVENT["MANUAL_REVIEW_VIEWED"] = "manual_review_viewed";
    EVENT["FROZEN_CREDIT_VIEWED"] = "frozen_credit_viewed";
    EVENT["FROZEN_CREDIT_TRY_AGAIN_CLICKED"] = "frozen_credit_try_again_clicked";
    EVENT["FROZEN_CREDIT_INFO_EDU_VIEWED"] = "frozen_credit_info_edu_viewed";
    EVENT["FROZEN_CREDIT_LINK_CLICKED"] = "frozen_credit_link_clicked";
    // Error
    EVENT["ERROR_VIEWED"] = "error_viewed";
    // Invalid State
    EVENT["INVALID_STATE_VIEWED"] = "invalid_state_viewed";
    EVENT["INVALID_STATE_EMAIL_SUBMITTED"] = "invalid_state_email_submitted";
    // Card Design Selection
    EVENT["CARD_ART_SELECTION_PAGE_VIEWED"] = "card_art_selection_page_viewed";
    EVENT["CARD_ART_SELECTION_CHANGED"] = "card_art_selection_changed";
    EVENT["CARD_ART_SELECTION_CONFIRMED"] = "card_art_selection_confirmed";
    // Accepting Offer
    EVENT["OFFER_PAGE_ACCEPT_OFFER_CLICKED"] = "offer_page_accept_offer_clicked";
    EVENT["OFFER_PAGE_CONTINUE_CLICKED"] = "offer_page_continue_clicked";
    EVENT["CONFIRM_ADDRESS_VIEWED"] = "confirm_address_viewed";
    EVENT["CONFIRM_ADDRESS_LATER_VIEWED"] = "confirm_address_later_viewed";
    EVENT["CONFIRM_ADDRESS_CONFIRM_CLICKED"] = "confirm_address_confirm_clicked";
    EVENT["CONFIRM_ADDRESS_NOT_NOW_CLICKED"] = "confirm_address_not_now_clicked";
    EVENT["CONFIRM_ADDRESS_LATER_SOUNDS_GOOD_CLICKED"] = "confirm_address_later_sounds_good_clicked";
    // Multi Card Login
    EVENT["DOB_FAILED"] = "dob_failed";
    EVENT["CONFIRM_PIN_TRY_AGAIN_VIEWED"] = "confirm_pin_try_again_viewed";
    EVENT["ENTER_PIN_TRY_AGAIN_VIEWED"] = "enter_pin_try_again_viewed";
    EVENT["CONFIRM_PIN_VIEWED"] = "confirm_pin_viewed";
    EVENT["ENTER_PIN_SUBMITTED"] = "enter_pin_submitted";
    EVENT["CONFIRM_PIN_SUBMITTED"] = "confirm_pin_submitted";
    EVENT["CREATE_PIN_SUBMITTED"] = "create_pin_submitted";
    EVENT["CREATE_PIN_INVALID_PIN_VIEWED"] = "create_pin_invalid_pin_viewed";
    EVENT["CONFIRM_PIN_TRY_AGAIN_RESET_PIN_CLICKED"] = "confirm_pin_try_again_reset_pin_clicked";
    EVENT["CONFIRM_PIN_RESET_PIN_CLICKED"] = "confirm_pin_reset_pin_clicked";
    EVENT["ENTER_PHONE_NUMBER_VIEWED"] = "enter_phone_number_viewed";
    EVENT["VERIFY_DOB_VIEWED"] = "verify_dob_viewed";
    EVENT["CREATE_PIN_VIEWED"] = "create_pin_viewed";
    EVENT["ENTER_PIN_VIEWED"] = "enter_pin_viewed";
    EVENT["LOGIN_OTP_VIEWED"] = "login_otp_viewed";
    EVENT["LOGIN_FAILED_VIEWED"] = "login_failed_viewed";
    EVENT["ISSUE_WITH_APPLICATION_VIEWED"] = "issue_with_application_viewed";
    EVENT["VERIFY_DOB_TRY_AGAIN_VIEWED"] = "verify_dob_try_again_viewed";
    EVENT["ENTER_PHONE_NUMBER_NOT_RECOGNIZED_VIEWED"] = "enter_phone_number_not_recognized_viewed";
    EVENT["VERIFY_DOB_TRY_AGAIN_CONTINUE_CLICKED"] = "verify_dob_try_again_continue_clicked";
    EVENT["ENTER_PHONE_NUMBER_LOG_IN_CLICKED"] = "enter_phone_number_log_in_clicked";
    EVENT["VERIFY_DOB_CONTINUE_CLICKED"] = "verify_dob_continue_clicked";
    EVENT["ENTER_PHONE_NUMBER_FILLED_OUT"] = "enter_phone_number_filled_out";
    EVENT["VERIFY_DOB_FILLED_OUT"] = "verify_dob_filled_out";
    EVENT["ISSUE_WITH_APPLICATION_GO_BACK_CLICKED"] = "issue_with_application_go_back_clicked";
    EVENT["ISSUE_WITH_APPLICATION_DOWNLOAD_CLICKED"] = "issue_with_application_download_clicked";
    EVENT["LOGIN_OTP_TRY_AGAIN_VIEWED"] = "login_otp_try_again_viewed";
    EVENT["MOBILE_PHONE_NUMBER_FILLED_OUT"] = "mobile_phone_number_filled_out";
    EVENT["PHONE_NUMBER_RECOGNIZED_OTP_PAGE_VIEWED"] = "phone_number_recognized_otp_page_viewed";
    EVENT["RECOGNIZED_OTP_ENTERED"] = "recognized_otp_entered";
    EVENT["RECOGNIZED_OTP_TRY_AGAIN_ENTERED"] = "recognized_otp_try_again_entered";
    EVENT["RECOGNIZED_CONTACT_SUPPORT_CLICKED"] = "recognized_contact_support_clicked";
    EVENT["ISSUE_WITH_APPLICATION_PG_VIEWED"] = "issue_with_application_pg_viewed";
    EVENT["MULTI_CREDIT_APP_INCOME_HOUSING_PAGE_VIEWED"] = "multi_credit_app_income_housing_page_viewed";
    EVENT["RECOGNIZED_NUMBER_SHOW_SIGN_UP"] = "recognized_number_show_sign_up";
    EVENT["ACCOUNT_PENDING_VIEWED"] = "account_pending_viewed";
    // LOCALIZATION
    EVENT["LANGUAGE_MENU_TAB_CLICKED"] = "language_menu_tab_clicked";
    EVENT["LANGUAGE_SELECTION_CLICKED"] = "language_selection_clicked";
    EVENT["LANGUAGE_CONFIRMATION_MODAL_VIEWED"] = "language_confirmation_modal_viewed";
    EVENT["LANGUAGE_CONTINUE_CLICKED"] = "language_continue_clicked";
    EVENT["LANGUAGE_CANCEL_CLICKED"] = "language_cancel_clicked";
    EVENT["LANGUAGE_SELECTION_PAGE_VIEWED"] = "language_selection_page_viewed";
    // Counteroffer
    EVENT["COUNTEROFFER_VIEWED"] = "counteroffer_viewed";
    EVENT["COUNTEROFFER_VISIT_CLICKED"] = "counteroffer_visit_clicked";
    // Returning User
    EVENT["APPLICATION_DECISION_DENIED_VIEWED"] = "application_decision_denied_viewed";
    EVENT["APPLICATION_DECISION_EXPIRED_VIEWED"] = "application_decision_expired_viewed";
    EVENT["APPLICATION_DECISION_IN_REVIEW_VIEWED"] = "application_decision_in_review_viewed";
    EVENT["APPLICATION_DECISION_FROZEN_VIEWED"] = "application_decision_frozen_viewed";
    EVENT["APPLICATION_DECISION_RETURNING_ACTIVE_USER_VIEWED"] = "application_decision_returning_active_user_viewed";
    EVENT["RETURNING_ACTIVE_USER_REDIRECT_TO_ACCOUNT"] = "returning_active_user_redirect_to_account";
    EVENT["APPLICATION_DECISION_FROZEN_ACTION_CLICKED"] = "application_decision_frozen_action_clicked";
    EVENT["RETURNING_USER_PERSONAL_INFO_VIEWED"] = "returning_user_personal_info_viewed";
    EVENT["RETURNING_USER_INCOME_HOUSING_ID_VIEWED"] = "returning_user_income_housing_id_viewed";
    EVENT["RETURNING_USER_INCOME_HOUSING_SUBMIT_CLICKED"] = "returning_user_income_housing_submit_clicked";
    EVENT["APPLICATION_DECISION_REJECTED_AFTER_COOLDOWN_WINDOW_APPLICATION_PG_VIEWED"] = "application_decision_rejected_after_cooldown_window_application_pg_viewed";
    EVENT["APPLICATION_DECISION_ELIGIBLE_APPLICATION_PG_VIEWED"] = "application_decision_eligible_application_pg_viewed";
    EVENT["APPLICATION_DECISION_REAPPLY_APPLICATION_PG_VIEWED"] = "application_decision_reapply_application_pg_viewed";
    EVENT["APPLICATION_EVALUATION_DECISION_REJECTED_ACTION_CLICKED"] = "application_evaluation_decision_rejected_action_cliced";
    // Personal Info Validation
    EVENT["PERSONAL_INFO_EMAIL_WARNING"] = "personal_info_email_warning";
    EVENT["PERSONAL_INFO_EMAIL_INVALID"] = "personal_info_email_invalid";
    EVENT["PERSONAL_INFO_ADDRESS_INVALID"] = "personal_info_address_invalid";
    EVENT["APPLICATION_ALREADY_APPLIED_CLICKED"] = "application_already_applied_clicked";
    EVENT["APPLICATION_ALREADY_APPLIED_SECONDARY_CLICKED"] = "application_already_applied_secondary_clicked";
    // Referral Code (Refer a friend)
    EVENT["SUBMIT_APPLICATION_WITH_REFERRAL_CODE"] = "submit_application_with_referral_code";
    // Dynamic LTO
    EVENT["APPLICATION_REWARDS_CLICKED"] = "application_rewards_clicked";
    // in store applications
    EVENT["welcomeBackModalViewed"] = "instore_welcomeBackModalViewed";
    EVENT["signupModalViewed"] = "instore_signupModalViewed";
    EVENT["signupModalClosed"] = "instore_signupModalClosed";
    EVENT["offerModalViewed"] = "instore_offerModalViewed";
    EVENT["createPinFailed"] = "instore_createPinFailed";
    EVENT["createPinViewed"] = "instore_createPinViewed";
})(EVENT || (EVENT = {}));
