var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { store } from '../app/store';
export var SdkEvent;
(function (SdkEvent) {
    SdkEvent["approved"] = "APPROVED";
    SdkEvent["rejected"] = "REJECTED";
    SdkEvent["actionRequired"] = "ACTION_REQUIRED";
    SdkEvent["acceptedOffer"] = "ACCEPTED_OFFER";
})(SdkEvent || (SdkEvent = {}));
export var sendMessageToHost = function (eventName) {
    if (store.getState().featureFlagSelector.featureFlags.webApplicationSDKEnabled !== 'true') {
        return;
    }
    var consumerInfo = store.getState().consumerInfoSelector;
    var applicantId = consumerInfo.consumerUUID;
    var applicationId = consumerInfo.auth.applicationUUID;
    var externalReferenceId = sessionStorage.getItem('externalReferenceId');
    var token = consumerInfo.auth.token;
    var metadata = __assign(__assign({ applicantId: applicantId, applicationId: applicationId }, (externalReferenceId && { externalReferenceId: externalReferenceId })), (token && { token: token }));
    var messageBody = { eventName: eventName, metadata: metadata };
    // Web: iframe
    if (window.parent !== window && sessionStorage.getItem('platform') === 'web-iframe') {
        window.parent.postMessage(messageBody, '*');
    }
    // iOS: WKWebView
    if (isiOSWebkit()) {
        window.webkit.messageHandlers.imprintWebCallback.postMessage(messageBody);
    }
    // Android: TODO
};
export function isiOSWebkit() {
    return (window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.imprintWebCallback);
}
export function isiOSWebkitImprint() {
    return sessionStorage.getItem('platform') === 'ios-imprint' && isiOSWebkit();
}
