import { createSlice } from '@reduxjs/toolkit';
import { initialLoadHomeResponse } from '../types';
var initialState = initialLoadHomeResponse;
var homeSlice = createSlice({
    name: 'home',
    initialState: initialState,
    reducers: {
        storeHomeResponse: function (state, action) {
            return action.payload;
        },
    },
});
export var storeHomeResponse = homeSlice.actions.storeHomeResponse;
export default homeSlice.reducer;
