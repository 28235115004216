// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fonts/UniversalSans-v1-2-0-100-0-221221212111-11-670.woff");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./fonts/UniversalSans-v1-2-0-100-0-221221212111-11-500.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./fonts/UniversalSans-v1-2-0-100-0-221221212111-11-825.woff");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Universal Sans Medium";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff");
  font-weight: 670;
  font-style: normal;
}

@font-face {
  font-family: "Universal Sans Regular";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Universal Sans Bold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff");
  font-weight: 825;
  font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/font.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,2DAAsF;EACtF,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,qCAAqC;EACrC,2DAAsF;EACtF,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,kCAAkC;EAClC,2DAAsF;EACtF,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":["@font-face {\n  font-family: \"Universal Sans Medium\";\n  src: url(\"./fonts/UniversalSans-v1-2-0-100-0-221221212111-11-670.woff\") format(\"woff\");\n  font-weight: 670;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: \"Universal Sans Regular\";\n  src: url(\"./fonts/UniversalSans-v1-2-0-100-0-221221212111-11-500.woff\") format(\"woff\");\n  font-weight: 500;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: \"Universal Sans Bold\";\n  src: url(\"./fonts/UniversalSans-v1-2-0-100-0-221221212111-11-825.woff\") format(\"woff\");\n  font-weight: 825;\n  font-style: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
