var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EVENT, PKG } from '../analytics/analyticsConsts';
import { setConfirm, setRedirectToLogin } from '../app/acceptScreenSlice';
import { setDeviceID } from '../app/apiScreenSlice';
import { setEligibilityVerified, setIsSubmittingApp, setLoading, setSubscribed, } from '../app/applicationScreenSlice';
import { setApplicationUUID, setExpiresAt, setFrozenExpiresAt, setProductAccountUUID, setRejectionReasonsURL, setShowCounterOffer, } from '../app/applicationStatusSlice';
import { setAuth, setCardOffer, setHHEmailVerification, setRewardsEmail, } from '../app/consumerInfoSlice';
import { setCardDesignOptionImages, setCardImg, setCardOfferImg, setCheckApplicationQR, setColor, setConfirmLaterQR, setCounterOffer, setDownloadAppQR, setErrorQR, setLogoIcon, setManualReviewQR, setProductUUID, setRewardDetails, setSuccessQR, } from '../app/merchantDataSlice';
import { SCREEN, setScreen } from '../app/screenStateSlice';
import { store } from '../app/store';
import { subscribeType } from '../assets/consts/const';
import { ANALYTICS } from '../firebase/firebase';
import { getEmployeeId, getStoreNumber, getUtmSource } from '../lib/utmManager';
import { reApplicationSetUpAfterPrompt } from '../utils/functionUtils';
import { ApplicationEligibilityRequest, METHOD, RejectionPDFRequest, Request, requestAssests, } from '../utils/http';
import { LanguageCode } from '../utils/languageConstants';
import { SIGN_UP_ELIGIBILITY_STATUS, URL } from './apiConstants';
import { handleDisplayState } from './handleDisplayState';
export var generateNewUUID = function () {
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
};
var newUUID = Date.now().toString(36) + Math.random().toString(36).substring(2);
export var showError = function (dispatch) {
    dispatch(setLoading(false));
    dispatch(setScreen(SCREEN.ERROR));
};
export var recordAnalyticsToSnowflake = function (_a) {
    var analyticsRequestData = _a.analyticsRequestData;
    return __awaiter(void 0, void 0, void 0, function () {
        var data, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, Request({
                            url: URL.ANALYTICS,
                            method: METHOD.POST,
                            body: JSON.parse(analyticsRequestData),
                            keepAlive: true,
                        })];
                case 1:
                    data = _b.sent();
                    return [2 /*return*/, [null, null]];
                case 2:
                    error_1 = _b.sent();
                    return [2 /*return*/, [null, error_1]];
                case 3: return [2 /*return*/];
            }
        });
    });
};
export var prompt = function (_a) {
    var productUUID = _a.productUUID, deviceID = _a.deviceID;
    return __awaiter(void 0, void 0, void 0, function () {
        var token, data, error_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    token = store.getState().consumerInfoSelector.auth.token;
                    return [4 /*yield*/, Request({
                            url: "".concat(URL.PROMPT, "?productUUID=").concat(productUUID),
                            method: METHOD.GET,
                            token: token,
                            deviceID: deviceID,
                        })];
                case 1:
                    data = _b.sent();
                    return [2 /*return*/, [data, null]];
                case 2:
                    error_2 = _b.sent();
                    return [2 /*return*/, error_2];
                case 3: return [2 /*return*/];
            }
        });
    });
};
export var callPrompt = function () {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var productUUID, deviceID, _a, promptData, promptError, eventName;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    dispatch(setLoading(true));
                    productUUID = getState().merchantDataSelector.productUUID;
                    if (getState().apiScreenScreenSelector.deviceID === '') {
                        dispatch(setDeviceID(newUUID));
                    }
                    deviceID = getState().apiScreenScreenSelector.deviceID;
                    return [4 /*yield*/, prompt({
                            productUUID: productUUID,
                            deviceID: deviceID,
                        })];
                case 1:
                    _a = _b.sent(), promptData = _a[0], promptError = _a[1];
                    dispatch(setLoading(false));
                    if (promptError) {
                        showError(dispatch);
                        ANALYTICS.funcError(PKG, 'prompt', JSON.stringify(promptError));
                        return [2 /*return*/, promptError];
                    }
                    if (!promptData) {
                        showError(dispatch);
                        ANALYTICS.funcError(PKG, 'prompt', 'missing prompt data');
                        return [2 /*return*/, null];
                    }
                    if (promptData.showCounterOffer) {
                        dispatch(setShowCounterOffer(promptData.showCounterOffer));
                    }
                    switch (promptData.signUpEligibilityStatus) {
                        case SIGN_UP_ELIGIBILITY_STATUS.REJECTED_WITHIN_COOLDOWN_WINDOW: {
                            if (promptData.mostRecentApplicationUUID) {
                                dispatch(setApplicationUUID(promptData.mostRecentApplicationUUID));
                            }
                            ANALYTICS.logEvent(EVENT.APPLICATION_DECISION_DENIED_VIEWED, {
                                product_uuid: productUUID,
                            });
                            dispatch(setScreen(SCREEN.APPLICATION_NOT_APPROVED));
                            break;
                        }
                        case SIGN_UP_ELIGIBILITY_STATUS.REJECTED_AFTER_COOLDOWN_WINDOW: {
                            if (promptData.mostRecentApplicationUUID) {
                                dispatch(setApplicationUUID(promptData.mostRecentApplicationUUID));
                            }
                            ANALYTICS.logEvent(EVENT.APPLICATION_DECISION_REJECTED_AFTER_COOLDOWN_WINDOW_APPLICATION_PG_VIEWED, {
                                product_uuid: productUUID,
                            });
                            reApplicationSetUpAfterPrompt(promptData, dispatch, getState);
                            dispatch(setScreen(SCREEN.APPLICATION));
                            break;
                        }
                        case SIGN_UP_ELIGIBILITY_STATUS.ELIGIBLE:
                        case SIGN_UP_ELIGIBILITY_STATUS.REAPPLY: {
                            eventName = promptData.signUpEligibilityStatus === SIGN_UP_ELIGIBILITY_STATUS.REAPPLY
                                ? EVENT.APPLICATION_DECISION_REAPPLY_APPLICATION_PG_VIEWED
                                : EVENT.APPLICATION_DECISION_ELIGIBLE_APPLICATION_PG_VIEWED;
                            ANALYTICS.logEvent(eventName, {
                                product_uuid: productUUID,
                            });
                            reApplicationSetUpAfterPrompt(promptData, dispatch, getState);
                            dispatch(setScreen(SCREEN.APPLICATION));
                            break;
                        }
                        case SIGN_UP_ELIGIBILITY_STATUS.FROZEN: {
                            reApplicationSetUpAfterPrompt(promptData, dispatch, getState);
                            ANALYTICS.logEvent(EVENT.APPLICATION_DECISION_FROZEN_VIEWED, {
                                product_uuid: productUUID,
                            });
                            dispatch(setFrozenExpiresAt(promptData.frozenExpiresAt));
                            dispatch(setScreen(SCREEN.CREDIT_FROZEN));
                            break;
                        }
                        default: {
                            dispatch(setScreen(SCREEN.ACCOUNT_PENDING));
                            break;
                        }
                    }
                    return [2 /*return*/];
            }
        });
    }); };
};
export var consumerLogOut = function () { return __awaiter(void 0, void 0, void 0, function () {
    var token, data, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                token = store.getState().consumerInfoSelector.auth.token;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, Request({
                        url: "".concat(URL.CONSUMER_LOGOUT),
                        method: METHOD.POST,
                        token: token,
                    })];
            case 2:
                data = _a.sent();
                return [3 /*break*/, 4];
            case 3:
                error_3 = _a.sent();
                return [2 /*return*/, [null, error_3]];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var getOfferDetails = function (_a) {
    var applicationUUID = _a.applicationUUID;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var auth, token, deviceID, _a, offerDetailsResponse, offerDetailsError;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    dispatch(setLoading(true));
                    auth = getState().consumerInfoSelector.auth;
                    token = auth.token;
                    if (getState().apiScreenScreenSelector.deviceID === '') {
                        dispatch(setDeviceID(newUUID));
                    }
                    deviceID = getState().apiScreenScreenSelector.deviceID;
                    return [4 /*yield*/, offer(applicationUUID, token, deviceID)];
                case 1:
                    _a = _d.sent(), offerDetailsResponse = _a[0], offerDetailsError = _a[1];
                    if (offerDetailsResponse) {
                        dispatch(setCardOffer((_b = offerDetailsResponse.application) === null || _b === void 0 ? void 0 : _b.cardOffer));
                        dispatch(setExpiresAt(((_c = offerDetailsResponse.application) === null || _c === void 0 ? void 0 : _c.decision.expiresAt) || ''));
                    }
                    if (offerDetailsError || !offerDetailsResponse) {
                        if (offerDetailsError) {
                            ANALYTICS.funcError(PKG, 'offerDetailsResponse', JSON.stringify(offerDetailsError));
                        }
                        dispatch(setLoading(false));
                        return [2 /*return*/];
                    }
                    dispatch(setLoading(false));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var offer = function (applicationUUID, token, deviceID) { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Request({
                        url: "".concat(URL.APPS, "/").concat(applicationUUID).concat(URL.OFFER),
                        token: token,
                        deviceID: deviceID,
                    })];
            case 1:
                data = _a.sent();
                return [2 /*return*/, [data, null]];
            case 2:
                error_4 = _a.sent();
                ANALYTICS.funcError(PKG, 'offer', JSON.stringify(error_4));
                return [2 /*return*/, [null, error_4]];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var setOfferPageForCard = function (productUUID, applicationUUID) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var auth;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    auth = getState().consumerInfoSelector.auth;
                    if (getState().apiScreenScreenSelector.deviceID === '') {
                        dispatch(setDeviceID(newUUID));
                    }
                    dispatch(setProductUUID(productUUID));
                    dispatch(setAuth(__assign(__assign({}, auth), { applicationUUID: applicationUUID })));
                    return [4 /*yield*/, dispatch(getOfferDetails({ applicationUUID: applicationUUID }))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
};
export var PRODUCT_TYPE;
(function (PRODUCT_TYPE) {
    PRODUCT_TYPE["CREDITCARD"] = "CREDITCARD";
    PRODUCT_TYPE["SPENDCARD"] = "SPENDCARD";
})(PRODUCT_TYPE || (PRODUCT_TYPE = {}));
export var REAPPLICATION_ACCOUNT_STATUS;
(function (REAPPLICATION_ACCOUNT_STATUS) {
    REAPPLICATION_ACCOUNT_STATUS["FROZEN"] = "FROZEN";
    REAPPLICATION_ACCOUNT_STATUS["EXPIRED_OFFER"] = "EXPIRED_OFFER";
})(REAPPLICATION_ACCOUNT_STATUS || (REAPPLICATION_ACCOUNT_STATUS = {}));
export var initialSC = function (requestParams) { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Request({
                        url: requestParams.applicationUUID
                            ? "".concat(URL.V2_APPLICATION_SC, "/").concat(requestParams.applicationUUID, "/submit")
                            : URL.V3_APPLICATION_SC,
                        method: METHOD.POST,
                        body: requestParams.ssn9 ? { ssn9: requestParams.ssn9 } : requestParams.initialScParams,
                        token: requestParams.token,
                        deviceID: requestParams.deviceID,
                    })];
            case 1:
                data = _a.sent();
                return [2 /*return*/, [data, null]];
            case 2:
                error_5 = _a.sent();
                return [2 /*return*/, [null, error_5]];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var submitAPP = function (submit) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, initialSCData, initialSCError;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    dispatch(setLoading(true));
                    dispatch(setIsSubmittingApp(true));
                    return [4 /*yield*/, initialSC({
                            token: submit.token,
                            ssn9: submit.ssn9,
                            applicationUUID: submit.applicationUUID,
                        })];
                case 1:
                    _a = _b.sent(), initialSCData = _a[0], initialSCError = _a[1];
                    if (initialSCError || !(initialSCData === null || initialSCData === void 0 ? void 0 : initialSCData.displayState) || !(initialSCData === null || initialSCData === void 0 ? void 0 : initialSCData.application)) {
                        ANALYTICS.logEvent(EVENT.ERROR_VIEWED, {
                            error_type: JSON.stringify(initialSCError),
                        });
                        ANALYTICS.funcError(PKG, 'submitAPP > initialSC', JSON.stringify(initialSCError));
                        showError(dispatch);
                        return [2 /*return*/, initialSCError];
                    }
                    handleDisplayState({
                        displayState: initialSCData === null || initialSCData === void 0 ? void 0 : initialSCData.displayState,
                        application: initialSCData === null || initialSCData === void 0 ? void 0 : initialSCData.application,
                        showCounterOffer: initialSCData.showCounterOffer,
                        token: submit.token,
                    });
                    dispatch(setIsSubmittingApp(false));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var submitApplication = function (_a) {
    var rewardsInfo = _a.rewardsInfo, consumer = _a.consumer, consumerFactor = _a.consumerFactor, productUUID = _a.productUUID, isMultiCard = _a.isMultiCard, offerUUIDs = _a.offerUUIDs;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var auth, token, utmSource, utmMedium, utmCampaign, utmContent, utmTerm, promoCode, storeNumber, employeeId, deviceID, initialSCData, initialSCError;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    dispatch(setLoading(true));
                    dispatch(setIsSubmittingApp(true));
                    auth = getState().consumerInfoSelector.auth;
                    token = auth.token;
                    utmSource = getUtmSource(productUUID);
                    utmMedium = sessionStorage.getItem('utm_medium');
                    utmCampaign = sessionStorage.getItem('utm_campaign');
                    utmContent = sessionStorage.getItem('utm_content');
                    utmTerm = sessionStorage.getItem('utm_term');
                    promoCode = sessionStorage.getItem('prm');
                    storeNumber = getStoreNumber(productUUID) || undefined;
                    employeeId = getEmployeeId(productUUID) || undefined;
                    if (getState().apiScreenScreenSelector.deviceID === '') {
                        dispatch(setDeviceID(newUUID));
                    }
                    deviceID = getState().apiScreenScreenSelector.deviceID;
                    if (!isMultiCard) return [3 /*break*/, 5];
                    if (!((offerUUIDs === null || offerUUIDs === void 0 ? void 0 : offerUUIDs.length) === 0)) return [3 /*break*/, 2];
                    return [4 /*yield*/, initialSC({
                            token: token,
                            deviceID: deviceID,
                            initialScParams: {
                                rewardsInfo: rewardsInfo,
                                consumer: consumer,
                                consumerFactors: consumerFactor,
                                productUUID: productUUID,
                                utmSource: utmSource,
                                utmMedium: utmMedium,
                                utmCampaign: utmCampaign,
                                utmContent: utmContent,
                                utmTerm: utmTerm,
                                storeNumber: storeNumber,
                                employeeId: employeeId,
                                crossSignupType: 'CC_APPLY_CC',
                            },
                        })];
                case 1:
                    _a = _e.sent(), initialSCData = _a[0], initialSCError = _a[1];
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, initialSC({
                        token: token,
                        deviceID: deviceID,
                        initialScParams: {
                            rewardsInfo: rewardsInfo,
                            consumer: consumer,
                            consumerFactors: consumerFactor,
                            productUUID: productUUID,
                            utmSource: utmSource,
                            utmMedium: utmMedium,
                            utmCampaign: utmCampaign,
                            utmContent: utmContent,
                            utmTerm: utmTerm,
                            storeNumber: storeNumber,
                            employeeId: employeeId,
                            crossSignupType: 'CC_APPLY_CC',
                            offerConfigUUIDs: offerUUIDs,
                        },
                    })];
                case 3:
                    _b = _e.sent(), initialSCData = _b[0], initialSCError = _b[1];
                    _e.label = 4;
                case 4: return [3 /*break*/, 9];
                case 5:
                    if (!((offerUUIDs === null || offerUUIDs === void 0 ? void 0 : offerUUIDs.length) == 0)) return [3 /*break*/, 7];
                    return [4 /*yield*/, initialSC({
                            token: token,
                            initialScParams: {
                                rewardsInfo: rewardsInfo,
                                consumer: consumer,
                                consumerFactors: consumerFactor,
                                productUUID: productUUID,
                                utmSource: utmSource,
                                utmMedium: utmMedium,
                                utmCampaign: utmCampaign,
                                utmContent: utmContent,
                                utmTerm: utmTerm,
                                storeNumber: storeNumber,
                                employeeId: employeeId,
                            },
                        })];
                case 6:
                    _c = _e.sent(), initialSCData = _c[0], initialSCError = _c[1];
                    return [3 /*break*/, 9];
                case 7: return [4 /*yield*/, initialSC({
                        token: token,
                        initialScParams: {
                            rewardsInfo: rewardsInfo,
                            consumer: consumer,
                            consumerFactors: consumerFactor,
                            productUUID: productUUID,
                            utmSource: utmSource,
                            utmMedium: utmMedium,
                            utmCampaign: utmCampaign,
                            utmContent: utmContent,
                            utmTerm: utmTerm,
                            storeNumber: storeNumber,
                            employeeId: employeeId,
                            offerConfigUUIDs: offerUUIDs,
                        },
                    })];
                case 8:
                    _d = _e.sent(), initialSCData = _d[0], initialSCError = _d[1];
                    _e.label = 9;
                case 9:
                    if (initialSCError) {
                        ANALYTICS.logEvent(EVENT.ERROR_VIEWED, {
                            error_type: initialSCError === null || initialSCError === void 0 ? void 0 : initialSCError.message,
                        });
                        ANALYTICS.funcError(PKG, 'submitApplication > initialSC', JSON.stringify(initialSCError));
                        showError(dispatch);
                        return [2 /*return*/, initialSCError];
                    }
                    dispatch(setAuth(__assign(__assign({}, auth), { token: token, applicationUUID: initialSCData.application.applicationUUID })));
                    if (promoCode) {
                        ANALYTICS.logEvent(EVENT.SUBMIT_APPLICATION_WITH_REFERRAL_CODE, {
                            applicationUUID: initialSCData.application.applicationUUID,
                            promo_code: promoCode,
                        });
                    }
                    dispatch(setLoading(false));
                    if (!initialSCData || !initialSCData.application || !initialSCData.displayState) {
                        ANALYTICS.funcError(PKG, 'SubmitApplication', 'missing response data for initSC');
                        return [2 /*return*/];
                    }
                    handleDisplayState({
                        displayState: initialSCData === null || initialSCData === void 0 ? void 0 : initialSCData.displayState,
                        application: initialSCData === null || initialSCData === void 0 ? void 0 : initialSCData.application,
                        showCounterOffer: initialSCData.showCounterOffer,
                        token: token,
                        isMultiCard: isMultiCard,
                        deviceID: deviceID,
                    });
                    dispatch(setIsSubmittingApp(false));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var getVirtualCard = function (productUUID) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/mobile-assets/light/virtualCard.png"),
                            image: true,
                        })];
                case 1:
                    data = (_a.sent());
                    dispatch(setCardImg(data));
                    return [3 /*break*/, 3];
                case 2:
                    error_6 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var getCardOfferImg = function (productUUID) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_7;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/web-assets/offerImage.png"),
                            image: true,
                        })];
                case 1:
                    data = (_a.sent());
                    dispatch(setCardOfferImg(data));
                    return [3 /*break*/, 3];
                case 2:
                    error_7 = _a.sent();
                    ANALYTICS.funcError(PKG, 'getCardOfferImg', JSON.stringify(error_7));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var getRewardDetails = function (productUUID, language) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_8;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestAssests({
                            url: language === LanguageCode.English
                                ? "/content/".concat(productUUID, "/program-details/rewards.json")
                                : "/content/".concat(productUUID, "/program-details/es/rewards.json"),
                        })];
                case 1:
                    data = (_a.sent());
                    dispatch(setRewardDetails(data));
                    return [3 /*break*/, 3];
                case 2:
                    error_8 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var getColor = function (productUUID) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_9;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/mobile-assets/colors.json"),
                        })];
                case 1:
                    data = (_a.sent());
                    dispatch(setColor(data));
                    return [3 /*break*/, 3];
                case 2:
                    error_9 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var getLogoIcon = function (productUUID) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_10;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/web-assets/logoIcon.svg"),
                            image: true,
                        })];
                case 1:
                    data = (_a.sent());
                    dispatch(setLogoIcon(data));
                    return [3 /*break*/, 3];
                case 2:
                    error_10 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var confirmApi = function (_a) {
    var token = _a.token, applicationUUID = _a.applicationUUID, cardDesignID = _a.cardDesignID, deviceID = _a.deviceID, sendSms = _a.sendSms;
    return __awaiter(void 0, void 0, void 0, function () {
        var data, error_11;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, Request({
                            url: "/v2/applications/".concat(applicationUUID, "/confirm"),
                            method: METHOD.POST,
                            body: {
                                cardDesignID: cardDesignID,
                                sendSms: sendSms,
                            },
                            token: token,
                            deviceID: deviceID,
                        })];
                case 1:
                    data = _b.sent();
                    return [2 /*return*/, [data, null]];
                case 2:
                    error_11 = _b.sent();
                    return [2 /*return*/, [null, error_11]];
                case 3: return [2 /*return*/];
            }
        });
    });
};
export var confirm = function (_a) {
    var token = _a.token, applicationUUID = _a.applicationUUID, cardDesignID = _a.cardDesignID, sendSms = _a.sendSms, onSuccess = _a.onSuccess, onFail = _a.onFail;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var deviceID, confirmResponse, confirmError;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    dispatch(setLoading(true));
                    if (getState().apiScreenScreenSelector.deviceID === '') {
                        dispatch(setDeviceID(newUUID));
                    }
                    deviceID = getState().apiScreenScreenSelector.deviceID;
                    return [4 /*yield*/, confirmApi({
                            token: token,
                            applicationUUID: applicationUUID,
                            cardDesignID: cardDesignID,
                            deviceID: deviceID,
                            sendSms: sendSms,
                        })];
                case 1:
                    _a = _b.sent(), confirmResponse = _a[0], confirmError = _a[1];
                    if (confirmError || !confirmResponse) {
                        ANALYTICS.logEvent(EVENT.ERROR_VIEWED, {
                            error_type: confirmError === null || confirmError === void 0 ? void 0 : confirmError.message,
                        });
                        ANALYTICS.funcError(PKG, 'confirm > confirmError', JSON.stringify(confirmError));
                        onFail && onFail();
                        showError(dispatch);
                        return [2 /*return*/];
                    }
                    dispatch(setProductAccountUUID(confirmResponse.productAccountUUID));
                    dispatch(setLoading(false));
                    dispatch(setConfirm(true));
                    onSuccess && onSuccess();
                    return [2 /*return*/];
            }
        });
    }); };
};
export var checkEligibility = function (_a) {
    var productUUID = _a.productUUID, email = _a.email;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var auth, token, _a, eligibilityResponse, eligibilityError;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    dispatch(setLoading(true));
                    auth = getState().consumerInfoSelector.auth;
                    token = auth.token;
                    return [4 /*yield*/, eligibility(productUUID, email, token)];
                case 1:
                    _a = _b.sent(), eligibilityResponse = _a[0], eligibilityError = _a[1];
                    if (eligibilityError) {
                        ANALYTICS.logEvent(EVENT.ERROR_VIEWED, {
                            error_type: eligibilityError === null || eligibilityError === void 0 ? void 0 : eligibilityError.message,
                        });
                        ANALYTICS.funcError(PKG, 'checkEligibility > eligibilityError', JSON.stringify(eligibilityError));
                        showError(dispatch);
                        return [2 /*return*/, eligibilityError];
                    }
                    if (eligibilityResponse === null || eligibilityResponse === void 0 ? void 0 : eligibilityResponse.eligible) {
                        dispatch(setRewardsEmail(email));
                        dispatch(setEligibilityVerified(true));
                        dispatch(setLoading(false));
                        dispatch(setHHEmailVerification(false));
                        return [2 /*return*/];
                    }
                    dispatch(setLoading(false));
                    dispatch(setHHEmailVerification(true));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var eligibility = function (productUUID, email, token) { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_12;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, ApplicationEligibilityRequest({
                        url: URL.APPLICATIONS,
                        token: token,
                        productUUID: productUUID,
                        email: email,
                    })];
            case 1:
                data = _a.sent();
                return [2 /*return*/, [data, null]];
            case 2:
                error_12 = _a.sent();
                return [2 /*return*/, [null, error_12]];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getRejectionReasons = function (_a) {
    var applicationUUID = _a.applicationUUID;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var auth, token, _a, rejectionPDFResponse, rejectionPDFError;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    dispatch(setLoading(true));
                    auth = getState().consumerInfoSelector.auth;
                    token = auth.token;
                    return [4 /*yield*/, rejectionPDF(applicationUUID, token)];
                case 1:
                    _a = _b.sent(), rejectionPDFResponse = _a[0], rejectionPDFError = _a[1];
                    if (rejectionPDFError) {
                        ANALYTICS.logEvent(EVENT.ERROR_VIEWED, {
                            error_type: rejectionPDFError === null || rejectionPDFError === void 0 ? void 0 : rejectionPDFError.message,
                        });
                        ANALYTICS.funcError(PKG, 'getRejectionReasons > rejectionPDFError', JSON.stringify(rejectionPDFError));
                        showError(dispatch);
                        return [2 /*return*/, rejectionPDFError];
                    }
                    dispatch(setRejectionReasonsURL((rejectionPDFResponse === null || rejectionPDFResponse === void 0 ? void 0 : rejectionPDFResponse.fileURL) || ''));
                    dispatch(setLoading(false));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var rejectionPDF = function (applicationUUID, token) { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_13;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, RejectionPDFRequest({
                        url: URL.REJECTION_PDF,
                        applicationUUID: applicationUUID,
                        token: token,
                    })];
            case 1:
                data = _a.sent();
                return [2 /*return*/, [data, null]];
            case 2:
                error_13 = _a.sent();
                return [2 /*return*/, [null, error_13]];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var subscribe = function (details, type) { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_14;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Request({
                        url: URL.SUBSCRIBE,
                        method: METHOD.POST,
                        body: { type: type, details: details },
                    })];
            case 1:
                data = _a.sent();
                return [2 /*return*/, [null, null]];
            case 2:
                error_14 = _a.sent();
                return [2 /*return*/, [null, error_14]];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var subscribeWithEmail = function (_a) {
    var details = _a.details, type = _a.type;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, subscribeError;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    dispatch(setLoading(true));
                    return [4 /*yield*/, subscribe(details, type)];
                case 1:
                    _a = _b.sent(), subscribeError = _a[1];
                    if (subscribeError) {
                        ANALYTICS.logEvent(EVENT.ERROR_VIEWED, {
                            error_type: subscribeError === null || subscribeError === void 0 ? void 0 : subscribeError.message,
                        });
                        ANALYTICS.funcError(PKG, 'subscribeWithEmail > subscribeError', JSON.stringify(subscribeError));
                        showError(dispatch);
                        return [2 /*return*/, subscribeError];
                    }
                    dispatch(setLoading(false));
                    if (type === subscribeType.UNSUPPORTED_STATE) {
                        dispatch(setSubscribed(true));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
};
export var getSuccessQR = function (productUUID) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_15;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/web-assets/successQR.svg"),
                            image: true,
                        })];
                case 1:
                    data = (_a.sent());
                    dispatch(setSuccessQR(data));
                    return [3 /*break*/, 3];
                case 2:
                    error_15 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var getCheckApplicationQR = function (productUUID) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_16;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/web-assets/checkApplicationQR.svg"),
                            image: true,
                        })];
                case 1:
                    data = (_a.sent());
                    dispatch(setCheckApplicationQR(data));
                    return [3 /*break*/, 3];
                case 2:
                    error_16 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var getErrorQR = function (productUUID) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_17;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/web-assets/errorQR.svg"),
                            image: true,
                        })];
                case 1:
                    data = (_a.sent());
                    dispatch(setErrorQR(data));
                    return [3 /*break*/, 3];
                case 2:
                    error_17 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var getConfirmLaterQR = function (productUUID) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_18;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/web-assets/confirmLaterQR.svg"),
                            image: true,
                        })];
                case 1:
                    data = (_a.sent());
                    dispatch(setConfirmLaterQR(data));
                    return [3 /*break*/, 3];
                case 2:
                    error_18 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var getManualReviewQR = function (productUUID) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_19;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/web-assets/manualReviewQR.svg"),
                            image: true,
                        })];
                case 1:
                    data = (_a.sent());
                    dispatch(setManualReviewQR(data));
                    return [3 /*break*/, 3];
                case 2:
                    error_19 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var getDownloadAppQRCode = function (productUUID) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_20;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/web-assets/downloadAppQRCode.svg"),
                            image: true,
                        })];
                case 1:
                    data = (_a.sent());
                    dispatch(setDownloadAppQR(data));
                    return [3 /*break*/, 3];
                case 2:
                    error_20 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var getCardDesignOptions = function (cardDesignID, productUUID) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var physicalData, renderData, data, error_21;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/web-assets/card-designs/physical/").concat(cardDesignID, ".svg"),
                            image: true,
                        })];
                case 1:
                    physicalData = (_a.sent());
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/web-assets/card-designs/render/").concat(cardDesignID, ".svg"),
                            image: true,
                        })];
                case 2:
                    renderData = (_a.sent());
                    data = {
                        cardDesignID: cardDesignID,
                        physical: physicalData,
                        render: renderData,
                    };
                    dispatch(setCardDesignOptionImages(data));
                    return [3 /*break*/, 4];
                case 3:
                    error_21 = _a.sent();
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
export var requestPhysicalCard = function (token, productAccountUUID) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, requestPhysicalCardResponse, requestPhysicalCardError;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    dispatch(setLoading(true));
                    dispatch(setRedirectToLogin(true));
                    return [4 /*yield*/, requestPhysicalCardHelper(productAccountUUID, CardType.PHYSICAL, token)];
                case 1:
                    _a = _b.sent(), requestPhysicalCardResponse = _a[0], requestPhysicalCardError = _a[1];
                    if (requestPhysicalCardError || !requestPhysicalCardResponse) {
                        if (requestPhysicalCardError) {
                            ANALYTICS.funcError(PKG, 'requestPhysicalCard', JSON.stringify(requestPhysicalCardError));
                        }
                        return [2 /*return*/];
                    }
                    dispatch(setLoading(false));
                    return [2 /*return*/];
            }
        });
    }); };
};
var CardType;
(function (CardType) {
    CardType["PHYSICAL"] = "PHYSICAL";
    CardType["VIRTUAL"] = "VIRTUAL";
})(CardType || (CardType = {}));
export var requestPhysicalCardHelper = function (productAccountUUID, cardType, token) { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_22;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Request({
                        url: "".concat(URL.CARD_ENDPOINT),
                        method: METHOD.POST,
                        body: {
                            productAccountUUID: productAccountUUID,
                            cardType: cardType,
                        },
                        token: token,
                    })];
            case 1:
                data = _a.sent();
                return [2 /*return*/, [data, null]];
            case 2:
                error_22 = _a.sent();
                return [2 /*return*/, [null, error_22]];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getCounterOffer = function (productUUID, language) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var counterOfferImg, counterOfferJSON, counterOffer, error_23;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/web-assets/counterOffer.png"),
                            image: true,
                        })];
                case 1:
                    counterOfferImg = (_a.sent());
                    return [4 /*yield*/, requestAssests({
                            url: language === LanguageCode.English
                                ? "/content/".concat(productUUID, "/program-details/counterOffer.json")
                                : "/content/".concat(productUUID, "/program-details/es/counterOffer.json"),
                        })];
                case 2:
                    counterOfferJSON = (_a.sent());
                    counterOffer = {
                        title: counterOfferJSON.title,
                        titleHighlight: counterOfferJSON.titleHighlight,
                        subtitle: counterOfferJSON.subtitle,
                        image: counterOfferImg,
                        hyperlinkButtonName: counterOfferJSON.hyperlinkButtonName,
                        url: counterOfferJSON.url,
                        disclosure: counterOfferJSON.disclosure,
                        lightModeColor: counterOfferJSON.lightModeColor,
                        darkModeColor: counterOfferJSON.darkModeColor,
                    };
                    dispatch(setCounterOffer(counterOffer));
                    return [3 /*break*/, 4];
                case 3:
                    error_23 = _a.sent();
                    ANALYTICS.funcError(PKG, 'getCounterOffer', JSON.stringify(error_23));
                    dispatch(setShowCounterOffer(false));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
