import { emptyAddress } from './Address';
import { emptyConsumerFactors } from './ConsumerFactors';
import { emptyDob } from './Dob';
export var emptyUserInformation = {
    firstName: '',
    lastName: '',
    memberSince: '',
    phone: '',
    firstTimeUser: false,
    address: emptyAddress,
    email: '',
    dob: emptyDob,
    consumerFactors: emptyConsumerFactors,
};
