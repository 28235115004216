import { createSlice } from '@reduxjs/toolkit';
import { LanguageCode } from '../utils/languageConstants';
import { codeToLocalization } from '../utils/languagelocale';
var initialState = {
    localization: null,
    code: LanguageCode.English,
};
export var languageSlice = createSlice({
    name: 'languageSelector',
    initialState: initialState,
    reducers: {
        setLanguage: function (state, action) {
            state.localization = codeToLocalization(action.payload);
            state.code = action.payload;
        },
    },
});
// Action creators are generated for each case reducer function
export var setLanguage = languageSlice.actions.setLanguage;
export default languageSlice.reducer;
