// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pNIazou9J_h2KYDwU9nk {
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  pointer-events: none;
}

.CkQqkPsJEHWfrUwngtS0 {
  display: none;
  height: 100vh;
  width: auto;
}

.pNIazou9J_h2KYDwU9nk > * {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
}

.jA1NBjcYPQwYaAWCCPSb {
  width: 100vw;
  display: flex;
  position: fixed;
  bottom: 0;
  flex-direction: row;
  justify-content: space-between;
}

.jA1NBjcYPQwYaAWCCPSb > * {
  position: fixed;
  bottom: 0;
}

.jA1NBjcYPQwYaAWCCPSb > *:first-child {
  left: 0;
}

.jA1NBjcYPQwYaAWCCPSb > *:last-child {
  right: 0;
}

@media (max-width: 600px) {
  .i4qBnYWiG9LxyBtmaRzI {
    display: none;
  }

  .pNIazou9J_h2KYDwU9nk {
    display: flex;
  }

  .CkQqkPsJEHWfrUwngtS0 {
    position: fixed;
    top: 0;
    display: flex !important;
    height: 100vh;
    width: auto;
  }

  .CkQqkPsJEHWfrUwngtS0 > * > svg {
    width: auto !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Confetti/Confetti.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,eAAe;EACf,MAAM;EACN,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,WAAW;AACb;;AAEA;EACE,eAAe;EACf,MAAM;EACN,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,eAAe;EACf,SAAS;EACT,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,eAAe;EACf,SAAS;AACX;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,eAAe;IACf,MAAM;IACN,wBAAwB;IACxB,aAAa;IACb,WAAW;EACb;;EAEA;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".container {\n  justify-content: center;\n  position: fixed;\n  top: 0;\n  width: 100vw;\n  height: 100vh;\n  z-index: 999;\n  pointer-events: none;\n}\n\n.confettiSm {\n  display: none;\n  height: 100vh;\n  width: auto;\n}\n\n.container > * {\n  position: fixed;\n  top: 0;\n  width: 100vw;\n  height: 100vh;\n}\n\n.sides {\n  width: 100vw;\n  display: flex;\n  position: fixed;\n  bottom: 0;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.sides > * {\n  position: fixed;\n  bottom: 0;\n}\n\n.sides > *:first-child {\n  left: 0;\n}\n\n.sides > *:last-child {\n  right: 0;\n}\n\n@media (max-width: 600px) {\n  .confettiNormal {\n    display: none;\n  }\n\n  .container {\n    display: flex;\n  }\n\n  .confettiSm {\n    position: fixed;\n    top: 0;\n    display: flex !important;\n    height: 100vh;\n    width: auto;\n  }\n\n  .confettiSm > * > svg {\n    width: auto !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `pNIazou9J_h2KYDwU9nk`,
	"confettiSm": `CkQqkPsJEHWfrUwngtS0`,
	"sides": `jA1NBjcYPQwYaAWCCPSb`,
	"confettiNormal": `i4qBnYWiG9LxyBtmaRzI`
};
module.exports = ___CSS_LOADER_EXPORT___;
