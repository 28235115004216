var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { colors, ImpFlex, ImpPad, ImpSpacer, ImpText, spacing } from '@imprint-payments/imprint-ui';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY_PREFILL } from './PrefillFlow';
import { useEffect } from 'react';
var screenDurationSeconds = 3;
export default function IdentityResult(_a) {
    var nextStep = _a.nextStep;
    var queryClient = useQueryClient();
    var data = queryClient.getQueryData([QUERY_KEY_PREFILL]);
    // this screen only lasts x seconds, we should move onto the next step afterward
    useEffect(function () {
        var timeoutId = setTimeout(function () {
            nextStep(Boolean(data));
        }, screenDurationSeconds * 1000);
        return function () {
            clearTimeout(timeoutId);
        };
    }, []);
    var headline = data ? 'Identity verified' : 'Identity not found';
    var body = data
        ? 'We’re pulling up the information we gathered from your phone number and last 4 digits of SSN now.'
        : "We're redirecting you to fill out your personal information.";
    return (_jsx(ImpPad, __assign({ padding: spacing.xl }, { children: _jsxs(ImpFlex, __assign({ fluid: true, direction: "column", align: 'center' }, { children: [_jsx(ImpText, __assign({ typography: 'headline3' }, { children: headline })), _jsx(ImpSpacer, { height: spacing.xs }), _jsx(ImpText, __assign({ typography: 'body2', color: colors.content.onSurfaceVariant }, { children: body }))] })) })));
}
