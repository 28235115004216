var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useImpState } from '../../app/hooks';
import { clearHomePageState, } from '../../utils/functionUtils';
import { PAGE } from '../../utils/routeConstant';
import styles from './ErrorModal.module.css';
export default function ErrorModal(_a) {
    var title = _a.title, subline = _a.subline;
    var _b = useImpState(), language = _b.language, dispatch = _b.dispatch, navigate = _b.navigate;
    if (language == null) {
        return _jsx(_Fragment, {});
    }
    useEffect(function () {
        window.onpopstate = function () {
            clearHomePageState(dispatch);
            navigate(PAGE.HOME);
        };
    });
    return (_jsxs("div", __assign({ className: styles.container }, { children: [_jsx("h1", __assign({ tabIndex: 0 }, { children: title })), _jsx("p", __assign({ tabIndex: 0 }, { children: subline })), _jsxs("div", __assign({ className: styles.errorContact, tabIndex: 0 }, { children: [language.errorContact, _jsx("a", __assign({ href: 'mailto:support@imprint.co', tabIndex: 0 }, { children: "support@imprint.co" })), "."] }))] })));
}
