import { jsx as _jsx } from "react/jsx-runtime";
import { formatRewardsBonus } from '@imprint-payments/imprint-lib';
import { Icons, ImpRewardsDisplay, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { useImpState } from '../../app/hooks';
import { getOfferUUIDs } from '../../utils/functionUtils';
import { merchantInfos } from '../../utils/productUUIDConstants';
export var useRewardsInfo = function () {
    var _a = useImpState(), merchantData = _a.merchantData, language = _a.language;
    var rewardDetails = merchantData.rewardDetails;
    var _b = useState([]), offerData = _b[0], setOfferData = _b[1];
    useEffect(function () {
        var offerData = [];
        if (rewardDetails.rewardsBonus) {
            var rewardsBonus = rewardDetails.rewardsBonus;
            var offerUUIDsSet = new Set(getOfferUUIDs());
            for (var _i = 0, rewardsBonus_1 = rewardsBonus; _i < rewardsBonus_1.length; _i++) {
                var currBonus = rewardsBonus_1[_i];
                var currBonusOfferConfigUUIDs = currBonus.offerConfigUUIDs;
                if (currBonusOfferConfigUUIDs) {
                    for (var _a = 0, currBonusOfferConfigUUIDs_1 = currBonusOfferConfigUUIDs; _a < currBonusOfferConfigUUIDs_1.length; _a++) {
                        var offerUUID = currBonusOfferConfigUUIDs_1[_a];
                        if ((offerUUIDsSet.size === 0 && offerUUID === 'default') ||
                            offerUUIDsSet.has(offerUUID)) {
                            offerData.push({
                                amount: formatRewardsBonus(currBonus),
                                title: currBonus.title,
                                badge: {
                                    children: language.limitedTimeOfferBadge,
                                    leadingIcon: Icons.timer,
                                },
                                content: (_jsx(ImpText, { children: _jsx("div", { dangerouslySetInnerHTML: { __html: currBonus.items.text }, style: {
                                            color: colors.content.onSurfaceVariant,
                                        } }) })),
                            });
                            break;
                        }
                    }
                }
                else {
                    offerData.push({
                        amount: formatRewardsBonus(currBonus),
                        title: currBonus.title,
                    });
                }
            }
        }
        var annualFee = merchantInfos[merchantData.productUUID].annualFee;
        offerData.push({
            amount: annualFee,
            title: language.annualFeeSuperscript,
        });
        setOfferData(offerData);
    }, [rewardDetails]);
    return offerData;
};
export function RewardsDisplay() {
    var merchantData = useImpState().merchantData;
    var offerData = useRewardsInfo();
    return (_jsx(ImpRewardsDisplay, { cardImageSrc: merchantData.cardImg, cardName: merchantData.rewardDetails.cardName, isLoading: offerData.length === 1, rewardsBonus: offerData }));
}
