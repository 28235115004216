export var applicationStatus = {
    noApplication: 'No application found',
    inReview: 'Application still in review',
    notApproved: 'Application not approved',
    haveOffer: 'Offer received',
    alreadySignedUp: 'Already Signed Up',
    creditFrozen: 'Your credit is frozen',
    asyncApplication: 'Evaluating your application',
};
export var applicationEvaluationSignUpStatus = {
    rejectedAfterCooldownWindow: 'rejectedAfterCooldownWindow',
    rejectedWithinCooldownWindow: 'rejectedWithinCooldownWindow',
};
// TODO: replace with constants in productUUIDConstants.ts
export var productID = {
    horizonHobby: 'PRDT-v1-785c001c-a9b1-4fc2-87d7-2d0b1060e3ed',
};
export var PLATFORM;
(function (PLATFORM) {
    PLATFORM["mobile"] = "mobile";
    PLATFORM["tablet"] = "tablet";
    PLATFORM["computer"] = "computer";
})(PLATFORM || (PLATFORM = {}));
export var subscribeType = {
    UNSUPPORTED_STATE: 'unsupportedState',
    NO_SMARTPHONE: 'noSmartphone',
};
export var defaultBulletColor = '#1E191A';
export var webServicingDisplayURL = 'account.imprint.co';
export var webServicingURL = 'https://account.imprint.co';
export var appDownloadDisplayURL = 'app.imprint.co/download';
export var appDownloadURL = 'https://app.imprint.co/download';
export var transUnionLoginURL = 'https://service.transunion.com/dss/login.page';
export var transUnionArticleURL = 'https://www.transunion.com/blog/credit-advice/what-should-i-do-after-i-freeze-my-credit';
export var supportEmail = 'support@imprint.co';
export var PHONE_NUMBER_MAX_LENGTH = 16;
export var PARAM_PHONE = 'phone';
