var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpContainer, ImpFlex, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import { useImpState } from '../../app/hooks';
import imprintLogoSmall from '../../assets/icons/imprint-logo-small.svg';
export default function WelcomeBackCard(_a) {
    var firstName = _a.firstName;
    var language = useImpState().language;
    return (_jsx(ImpContainer, __assign({ width: '100%', height: '132px' }, { children: _jsxs(ImpFlex, __assign({ direction: "row", align: "center", justify: "center", fluid: true }, { children: [_jsx(ImpContainer, __assign({ width: '32px', height: '100%' }, { children: _jsx(ImpFlex, __assign({ direction: "column", align: "center", justify: "center", fluid: true }, { children: _jsx("img", { draggable: "false", alt: "imprint logo", src: imprintLogoSmall }) })) })), _jsx(ImpSpacer, { width: "l" }), _jsx(ImpContainer, __assign({ width: '100%', height: '100%' }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center", justify: "center", fluid: true }, { children: [_jsx(ImpContainer, __assign({ width: '100%', height: '32px' }, { children: _jsx(ImpText, __assign({ typography: "headline3" }, { children: language.reApplicationTitle.replace('$firstName$', firstName) })) })), _jsx(ImpContainer, __assign({ width: '100%', height: '48px' }, { children: _jsx(ImpText, __assign({ typography: "body3" }, { children: language.reApplicationBody })) }))] })) }))] })) })));
}
