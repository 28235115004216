var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { PKG } from '../analytics/analyticsConsts';
import { setCardDesignSelection } from '../app/acceptScreenSlice';
import { MODAL_ENUM, requireFullSSN, setLoading, setShowModal, } from '../app/applicationScreenSlice';
import { setAppStatus, setEvaluationRejectReapply, setExpiresAt, setReApplicationInApp, setShowCounterOffer, } from '../app/applicationStatusSlice';
import { setCardOffer } from '../app/consumerInfoSlice';
import { setMultiCardUser } from '../app/loginSlice';
import { SCREEN, setScreen } from '../app/screenStateSlice';
import { store } from '../app/store';
import { applicationEvaluationSignUpStatus, applicationStatus } from '../assets/consts/const';
import { ANALYTICS } from '../firebase/firebase';
import { isiOSWebkitImprint, SdkEvent, sendMessageToHost } from '../utils/sdkPostMessageHandler';
import { getCardDesignOptions, getRejectionReasons } from './api';
import { GetApplicationEvaluation } from './getApplicationEvaluation';
import { SetBackendLanguage } from './setLanguage';
var DEFAULT_TIMEOUT = 2000;
var sendSdkEventToHost = function (decisionStatus) {
    switch (decisionStatus) {
        case 'PREAPPROVED':
        case 'APPROVED': {
            sendMessageToHost(SdkEvent.approved);
            break;
        }
        case 'REJECTED': {
            sendMessageToHost(SdkEvent.rejected);
            break;
        }
        case 'PENDING':
        case 'FROZEN': {
            sendMessageToHost(SdkEvent.actionRequired);
            break;
        }
        default:
            break;
    }
};
export var handleDisplayState = function (_a) {
    var displayState = _a.displayState, application = _a.application, showCounterOffer = _a.showCounterOffer, token = _a.token, isMultiCard = _a.isMultiCard, deviceID = _a.deviceID, delay = _a.delay, signUpStatus = _a.signUpStatus;
    return __awaiter(void 0, void 0, void 0, function () {
        var _b, response, err, _c, _d, resp, err, _i, _e, cardDesignOption, productUUID, _f, resp, err;
        var _g, _h;
        var _j, _k, _l, _m;
        return __generator(this, function (_o) {
            switch (_o.label) {
                case 0:
                    _b = displayState;
                    switch (_b) {
                        case 'NewApplicationWithFullSSN': return [3 /*break*/, 1];
                        case 'ExistingApplication': return [3 /*break*/, 2];
                        case 'GetApplicationEvaluation': return [3 /*break*/, 3];
                    }
                    return [3 /*break*/, 9];
                case 1:
                    {
                        store.dispatch(setLoading(false));
                        store.dispatch(requireFullSSN(true));
                        store.dispatch(setScreen(SCREEN.APPLICATION));
                        store.dispatch(setMultiCardUser(false));
                        return [2 /*return*/];
                    }
                    _o.label = 2;
                case 2:
                    {
                        sendSdkEventToHost(application.decision.status);
                        store.dispatch(setLoading(false));
                        store.dispatch(setScreen(SCREEN.STATUS));
                        return [2 /*return*/];
                    }
                    _o.label = 3;
                case 3:
                    store.dispatch(setLoading(true));
                    if (store.getState().applicationStatusSelector.status !== applicationStatus.asyncApplication) {
                        store.dispatch(setAppStatus(applicationStatus.asyncApplication));
                    }
                    response = void 0;
                    err = void 0;
                    if (!isMultiCard) return [3 /*break*/, 5];
                    return [4 /*yield*/, GetApplicationEvaluation({
                            token: token,
                            applicationUUID: application.applicationUUID,
                            deviceID: deviceID,
                        })];
                case 4:
                    _g = _o.sent(), response = _g[0], err = _g[1];
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, GetApplicationEvaluation({
                        token: token,
                        applicationUUID: application.applicationUUID,
                    })];
                case 6:
                    _h = _o.sent(), response = _h[0], err = _h[1];
                    _o.label = 7;
                case 7: return [4 /*yield*/, new Promise(function (f) { return setTimeout(f, delay || DEFAULT_TIMEOUT); })];
                case 8:
                    _o.sent();
                    // need to handle 404 and 500 responses as a try again
                    if (err !== null && err.code !== 404 && err.code !== 500) {
                        ANALYTICS.funcError(PKG, 'handleDisplayState', JSON.stringify(err));
                        return [2 /*return*/];
                    }
                    if (!((_j = response === null || response === void 0 ? void 0 : response.body) === null || _j === void 0 ? void 0 : _j.displayState)) {
                        ANALYTICS.funcError(PKG, 'handleDisplayState', 'missing display state from getApplicationEvaluation');
                    }
                    else if (!((_k = response === null || response === void 0 ? void 0 : response.body) === null || _k === void 0 ? void 0 : _k.application)) {
                        ANALYTICS.funcError(PKG, 'handleDisplayState', 'missing application info from getApplicationEvaluation');
                    }
                    // use old display state if not returned (i.e 404 / 500 error to try again)
                    handleDisplayState({
                        displayState: response.body.displayState || displayState,
                        application: response.body.application || application,
                        showCounterOffer: response.body.showCounterOffer,
                        token: token,
                        isMultiCard: isMultiCard,
                        deviceID: deviceID,
                        delay: parseInt(((_l = response.headers) === null || _l === void 0 ? void 0 : _l['x-imprint-delay-in-ms']) || '500'),
                        signUpStatus: response.body.signUpStatus,
                    });
                    return [2 /*return*/];
                case 9:
                    sendSdkEventToHost(application.decision.status);
                    store.dispatch(setLoading(false));
                    _c = application.decision.status;
                    switch (_c) {
                        case 'PREAPPROVED': return [3 /*break*/, 10];
                        case 'APPROVED': return [3 /*break*/, 10];
                        case 'REJECTED': return [3 /*break*/, 12];
                        case 'FROZEN': return [3 /*break*/, 13];
                        case 'PENDING': return [3 /*break*/, 14];
                    }
                    return [3 /*break*/, 16];
                case 10:
                    if (isiOSWebkitImprint()) {
                        // In Imprint mobile app, the following flow will be token over by the app
                        return [2 /*return*/];
                    }
                    store.dispatch(setCardOffer(application.cardOffer));
                    store.dispatch(setExpiresAt(application.decision.expiresAt));
                    store.dispatch(setShowModal(MODAL_ENUM.noModal));
                    store.dispatch(setScreen(SCREEN.OFFER));
                    return [4 /*yield*/, SetBackendLanguage()];
                case 11:
                    _d = _o.sent(), resp = _d[0], err = _d[1];
                    if ((_m = application.cardOffer) === null || _m === void 0 ? void 0 : _m.cardDesignOptions) {
                        for (_i = 0, _e = application.cardOffer.cardDesignOptions; _i < _e.length; _i++) {
                            cardDesignOption = _e[_i];
                            productUUID = store.getState().merchantDataSelector.productUUID;
                            store.dispatch(getCardDesignOptions(cardDesignOption.cardDesignID, productUUID));
                        }
                        store.dispatch(setCardDesignSelection(true));
                    }
                    if (err) {
                        ANALYTICS.funcError(PKG, 'handleDisplayState', JSON.stringify(err));
                    }
                    return [2 /*return*/];
                case 12:
                    {
                        store.dispatch(getRejectionReasons({
                            applicationUUID: application.applicationUUID,
                        }));
                        if (showCounterOffer &&
                            store.getState().featureFlagSelector.featureFlags.counterOfferEnabled === 'true') {
                            store.dispatch(setShowCounterOffer(showCounterOffer));
                        }
                        // to decide if we allow re-apply or not
                        switch (signUpStatus) {
                            case applicationEvaluationSignUpStatus.rejectedAfterCooldownWindow: {
                                store.dispatch(setEvaluationRejectReapply(true));
                                store.dispatch(setReApplicationInApp(true));
                                break;
                            }
                            case applicationEvaluationSignUpStatus.rejectedWithinCooldownWindow: {
                                store.dispatch(setEvaluationRejectReapply(false));
                                break;
                            }
                        }
                        store.dispatch(setAppStatus(applicationStatus.notApproved));
                        store.dispatch(setShowModal(MODAL_ENUM.noModal));
                        store.dispatch(setScreen(SCREEN.OTPSCREEN));
                        return [2 /*return*/];
                    }
                    _o.label = 13;
                case 13:
                    {
                        store.dispatch(setExpiresAt(application.decision.expiresAt));
                        store.dispatch(setReApplicationInApp(true));
                        store.dispatch(setScreen(SCREEN.CREDIT_FROZEN));
                        return [2 /*return*/];
                    }
                    _o.label = 14;
                case 14:
                    store.dispatch(setAppStatus(applicationStatus.inReview));
                    store.dispatch(setScreen(SCREEN.APPLICATION_IN_REVIEW));
                    return [4 /*yield*/, SetBackendLanguage()];
                case 15:
                    _f = _o.sent(), resp = _f[0], err = _f[1];
                    if (err) {
                        ANALYTICS.funcError(PKG, 'handleDisplayState', JSON.stringify(err));
                    }
                    return [2 /*return*/];
                case 16:
                    {
                        store.dispatch(setScreen(SCREEN.ERROR));
                        return [2 /*return*/];
                    }
                    _o.label = 17;
                case 17: return [2 /*return*/];
            }
        });
    });
};
