var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpContainer, ImpFlex, ImpHeader, ImpHidden, ImpPad, ImpRewardsDisplay, ImpSpacer, BreakpointsPx, } from '@imprint-payments/imprint-ui';
export function ApplicationLoader() {
    return (_jsxs(ImpFlex, __assign({ direction: "column", fluid: true }, { children: [_jsx(ImpHeader, { isLoading: true }), _jsx(ImpSpacer, { height: "3xl", mobileHeight: "xl" }), _jsxs(ImpFlex, __assign({ fluid: true, direction: "row", justify: "center", gap: "l" }, { children: [_jsx(ImpContainer, __assign({ backgroundColor: 'white', width: "min(534px, 100% - 32px)", height: "260px", borderRadius: "10px" }, { children: _jsxs(ImpPad, __assign({ padding: "16px" }, { children: [_jsx(ImpContainer, { isLoading: true, width: "160px", height: "12px" }), _jsx(ImpSpacer, { height: "xs" }), _jsx(ImpContainer, { isLoading: true, width: "100px", height: "12px" }), _jsx(ImpSpacer, { height: "l" }), _jsx(ImpContainer, { isLoading: true, width: "160px", height: "12px" }), _jsx(ImpSpacer, { height: "xs" }), _jsx(ImpContainer, { isLoading: true, width: "100px", height: "12px" })] })) })), _jsx(ImpHidden, __assign({ hideBelow: BreakpointsPx.Application }, { children: _jsx(ImpRewardsDisplay, { isLoading: true, cardImageSrc: '', cardName: '', rewardsBonus: [] }) }))] }))] })));
}
