var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ImpAccountStatus, ImpButton, ImpContainer, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import { EVENT } from '../../analytics/analyticsConsts';
import { setRedirectToLogin } from '../../app/acceptScreenSlice';
import { requireFullSSN } from '../../app/applicationScreenSlice';
import { resetLast4, setConsumerProfile } from '../../app/consumerInfoSlice';
import { useAppSelector, useImpState } from '../../app/hooks';
import { setMultiCardUser, setShowSignUp } from '../../app/loginSlice';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { appDownloadDisplayURL, appDownloadURL, supportEmail, transUnionArticleURL, transUnionLoginURL, webServicingDisplayURL, webServicingURL, } from '../../assets/consts/const';
import { HeaderHandler } from '../../components/HeaderHandler/HeaderHandler';
import WaterfallModal from '../../components/modal/WaterfallModal';
import { ANALYTICS } from '../../firebase/firebase';
import { redirectToAccountWithCookie } from '../../utils/functionUtils';
import { PAGE } from '../../utils/routeConstant';
export default function AccountStatusPage() {
    var _a = useImpState(), language = _a.language, featureFlags = _a.featureFlags, merchantData = _a.merchantData, screenState = _a.screenState, consumerInfo = _a.consumerInfo, appStatus = _a.appStatus, apiScreenState = _a.apiScreenState, dispatch = _a.dispatch, navigate = _a.navigate;
    var loading = useAppSelector(function (state) { return state.applicationScreenSelector.loading; });
    var paragraphStyle = {
        whiteSpace: 'pre-line',
    };
    var renderLogIntoAccountBody = function () {
        return (_jsxs(ImpContainer, __assign({ textAlign: "center" }, { children: [_jsx(ImpSpacer, { height: "xl" }), _jsxs(ImpText, __assign({ typography: "body2", mobileTypography: "body3" }, { children: [language.duplicateAppBody.replace('$cardName$', merchantData.rewardDetails.cardName), _jsx(ImpButton, __assign({ variant: "inline", onClick: function () {
                                window.open(webServicingURL, '_blank');
                            } }, { children: webServicingDisplayURL })), language.duplicateAppBody2] }))] })));
    };
    var handleLogIntoAccount = function () {
        // TODO: this is not necessarily true. Should use different naming
        var isMultiCard = 'true';
        ANALYTICS.logEvent(EVENT.RETURNING_ACTIVE_USER_REDIRECT_TO_ACCOUNT, {
            product_uuid: merchantData.productUUID,
        });
        dispatch(setRedirectToLogin(true));
        redirectToAccountWithCookie(consumerInfo.auth.token, apiScreenState.deviceID, consumerInfo.consumerUUID, merchantData.productUUID, isMultiCard);
    };
    var getButtonState = function (loading) {
        if (loading) {
            return 'loading';
        }
        else {
            return 'enabled';
        }
    };
    var renderLogIntoAccountButton = function () {
        return (_jsxs(ImpContainer, __assign({ textAlign: "center" }, { children: [_jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpButton, __assign({ variant: "primary", buttonStyle: "branded", size: "m", state: getButtonState(loading), onClick: function () { return handleLogIntoAccount(); } }, { children: language.logIn }))] })));
    };
    var downloadAppBodyContent = function () {
        return (_jsxs(_Fragment, { children: [language.applicationNotApprovedBody1, _jsx(ImpButton, __assign({ variant: "inline", onClick: function () {
                        window.open(appDownloadURL, '_blank');
                    } }, { children: appDownloadDisplayURL })), language.applicationNotApprovedBody2] }));
    };
    var renderNotApprovedBody = function () {
        return (_jsxs(ImpContainer, __assign({ textAlign: "center" }, { children: [_jsx(ImpSpacer, { height: "xl" }), _jsx(ImpText, __assign({ typography: "body2", mobileTypography: "body3" }, { children: language.applicationNotApprovedBody.replace('$cardName$', merchantData.rewardDetails.cardName) }))] })));
    };
    var frozenCreditHeader = function () {
        return language.afterOtpAppStatusFrozenCreditHeader;
    };
    var renderFrozenCreditBody2 = function () {
        return (_jsxs(ImpContainer, { children: [_jsx(ImpSpacer, { height: "xl" }), _jsx(ImpText, __assign({ typography: "body2", mobileTypography: "body3" }, { children: _jsxs("div", __assign({ style: paragraphStyle }, { children: [language.enumerateOne, _jsx(ImpButton, __assign({ variant: "inline", onClick: function () {
                                    window.open(transUnionLoginURL, '_blank');
                                } }, { children: language.logIn })), language.creditFrozenBody2] })) }))] }));
    };
    var renderFrozenCreditBody3 = function () {
        return (_jsxs(ImpContainer, { children: [_jsx(ImpSpacer, { height: "xl" }), _jsx(ImpText, __assign({ typography: "body2", mobileTypography: "body3" }, { children: _jsxs("div", __assign({ style: paragraphStyle }, { children: [language.creditFrozenBody3ReApplication.replace('$expireDate$', appStatus.frozenExpiresAt), _jsx(ImpButton, __assign({ variant: "inline", onClick: function () {
                                    window.open(transUnionArticleURL, '_blank');
                                } }, { children: language.thisArticle })), language.creditFrozenBody5] })) }))] }));
    };
    var handleUnfreezeCredit = function () {
        dispatch(setScreen(SCREEN.APPLICATION));
        navigate(PAGE.HOME);
        if (appStatus.reApplicationInApp) {
            dispatch(requireFullSSN(false));
            dispatch(setMultiCardUser(false));
            dispatch(setShowSignUp(true));
            dispatch(resetLast4);
            dispatch(setConsumerProfile(__assign(__assign({}, consumerInfo.consumerProfile), { ssn4: '' })));
        }
        ANALYTICS.logEvent(EVENT.APPLICATION_DECISION_FROZEN_ACTION_CLICKED, {
            product_uuid: merchantData.productUUID,
        });
    };
    var renderUnfreezeCreditButton = function () {
        return (_jsxs(ImpContainer, __assign({ textAlign: "center" }, { children: [_jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpButton, __assign({ variant: "primary", buttonStyle: "branded", size: "m", state: getButtonState(loading), onClick: function () { return handleUnfreezeCredit(); } }, { children: language.unfrozeMyCredit }))] })));
    };
    var renderManualReviewBody = function () {
        return (_jsxs(ImpContainer, __assign({ textAlign: "center" }, { children: [_jsx(ImpSpacer, { height: "xl" }), _jsxs(ImpText, __assign({ typography: "body2", mobileTypography: "body3" }, { children: [language.applicationInReviewBody, _jsx(ImpButton, __assign({ variant: "inline", onClick: function () {
                                window.location.href = 'mailto:support@imprint.co';
                            } }, { children: supportEmail })), language.period] }))] })));
    };
    var handleViewDeclineDetailsClick = function () {
        ANALYTICS.logEvent(EVENT.APP_DECLINE_LINK_CLICKED, {
            link: 'view_decline_details',
        });
        if (appStatus.rejectionReasonsURL !== '')
            window.open(appStatus.rejectionReasonsURL);
    };
    var renderAccountStatus = function () {
        switch (screenState.screen) {
            case SCREEN.APPLICATION_IN_REVIEW: {
                return (_jsx(ImpAccountStatus, { header: language.afterOtpAppStatusinReviewHeader, body1: renderManualReviewBody(), body1CenterAlign: true }));
            }
            case SCREEN.APPLICATION_NOT_APPROVED: {
                if (appStatus.showCounterOffer) {
                    return _jsx(WaterfallModal, { handleViewDeclineDetailsClick: handleViewDeclineDetailsClick });
                }
                else {
                    return (_jsx(ImpAccountStatus, { header: language.applicationNotApprovedTitle, body1: renderNotApprovedBody(), body1CenterAlign: true }));
                }
            }
            case SCREEN.CREDIT_FROZEN: {
                return (_jsx(ImpAccountStatus, { header: frozenCreditHeader(), body1: language.creditFrozenBody, body2: renderFrozenCreditBody2(), body3: renderFrozenCreditBody3(), buttons: renderUnfreezeCreditButton() }));
            }
            case SCREEN.APPLICATION_EXPIRED: {
                return _jsx(ImpAccountStatus, { header: language.offerHasExpiredTitle });
            }
            case SCREEN.APPLICATION_ACTIVE_USER: {
                return (_jsx(ImpAccountStatus, { header: language.duplicateAppTitle.replace('$firstName$', consumerInfo.consumerProfile.name.firstName), body1: renderLogIntoAccountBody(), buttons: renderLogIntoAccountButton() }));
            }
            case SCREEN.ACCOUNT_LOCKED: {
                return (_jsx(ImpAccountStatus, { header: language.loginOtpFailedHeader, body1: language.accountLockedBody, body1CenterAlign: true }));
            }
            case SCREEN.STATUS_OTP_VERIFICATION_FAILED: {
                return (_jsx(ImpAccountStatus, { header: language.phoneCantBeVerified, body1: language.appStatusFailedBody, body1CenterAlign: true }));
            }
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(HeaderHandler, {}), renderAccountStatus()] }));
}
