var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import './App.css';
import './font.css';
import { colors, ImpContainer, ImpSnackbarProvider } from '@imprint-payments/imprint-ui';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { getCardOfferImg, getCheckApplicationQR, getColor, getConfirmLaterQR, getDownloadAppQRCode, getErrorQR, getLogoIcon, getManualReviewQR, getRewardDetails, getSuccessQR, getVirtualCard, } from './api/api';
import { getLockupImage } from './api/getLockupImage/getLockupImage';
import { AppHistory } from './app/History';
import { resetSessionID, setSessionID } from './app/apiScreenSlice';
import { setLoading } from './app/applicationScreenSlice';
import { useAppSelector, useImpState } from './app/hooks';
import { setLanguage } from './app/languageSlice';
import { store } from './app/store';
import { ApplicationLoader } from './components/ApplicationLoader/ApplicationLoader';
import Login from './components/sso/login';
import fetchFirebaseApiKey, { ANALYTICS, config, initFeatureFlags } from './firebase/firebase';
import { CompleteOnPhonePage } from './pages/CompleteOnPhonePage';
import { CreatePINPage } from './pages/CreatePINPage';
import Accept from './pages/accept/AcceptApplicaton';
import AccountStatusPage from './pages/accountStatusPage/AccountStatusPage';
import CheckApplication from './pages/checkApplication/CheckApplication';
import Error from './pages/error/Error';
import ApplicationHome from './pages/home/ApplicationHome';
import ApplicationWrapper from './pages/home/ApplicationWrapper';
import OfferPage from './pages/home/OfferPage';
import OtpVerify from './pages/otp/OtpVerify';
import useStatsigUserUpdaters from './statsig/user';
import { COOKIEKEY } from './utils/cookieConstants';
import { fetchEnvFromDomain } from './utils/environments';
import { getCookieValueFor, getLanguageFromCookie, setSessionUrlParam, } from './utils/functionUtils';
import { LanguageCode } from './utils/languageConstants';
import { PAGE } from './utils/routeConstant';
import { useExternalScript } from './utils/useExternalScript';
var AppWrapper = function () {
    var queryClient = new QueryClient();
    return (_jsx(Provider, __assign({ store: store }, { children: _jsx(QueryClientProvider, __assign({ client: queryClient }, { children: _jsx(App, {}) })) })));
};
export default AppWrapper;
var NavigateSetter = function () {
    AppHistory.navigate = useNavigate();
    return null;
};
function App() {
    var _a = useState(false), assetsLoaded = _a[0], setAssetsLoaded = _a[1];
    var _b = React.useState(new URLSearchParams()), urlSearchParams = _b[0], setUrlSearchParams = _b[1];
    var OFFER_CONFIG_UUIDS = 'offerConfigUUIDs';
    var _c = useImpState(), language = _c.language, languageCode = _c.languageCode, merchantData = _c.merchantData, screenState = _c.screenState, featureFlags = _c.featureFlags, apiScreenState = _c.apiScreenState, acceptScreenState = _c.acceptScreenState, dispatch = _c.dispatch;
    useStatsigUserUpdaters();
    useEffect(function () {
        setUrlSearchParams(new URLSearchParams(window.location.search));
        var handleBeforeUnload = function (event) {
            event.preventDefault();
            event.returnValue = '';
        };
        return function () {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [acceptScreenState.redirectToLogin, screenState.screen]);
    useEffect(function () {
        dispatch(setLoading(true));
        initFeatureFlags();
        fetchEnvFromDomain(window.location.hostname.toLowerCase(), window.location.pathname.toLowerCase());
        fetchFirebaseApiKey();
        dispatch(setLoading(false));
    }, []);
    useEffect(function () {
        // store session_id in store when it's in imprint.co domain
        // reset when it's not
        if (getCookieValueFor(COOKIEKEY.SESSIONID) !== undefined) {
            dispatch(setSessionID(getCookieValueFor(COOKIEKEY.SESSIONID)));
        }
        else if (apiScreenState.sessionID !== '') {
            dispatch(resetSessionID());
        }
    }, [apiScreenState.sessionID]);
    // get language from session
    useEffect(function () {
        dispatch(setLoading(true));
        var language = LanguageCode.English;
        language = getLanguageFromCookie();
        dispatch(setLanguage(language));
        dispatch(setLoading(false));
    }, [featureFlags.spanish]);
    var internalAuth = useAppSelector(function (state) { return state.apiScreenScreenSelector.internalAuthRequired; });
    useEffect(function () {
        var root = document.documentElement;
        root.style.setProperty('--lightColor', merchantData.color.light);
        root.style.setProperty('--merchant-color', merchantData.color.light);
        root.style.setProperty('--merchant-color-dark', merchantData.color.dark);
    }, [merchantData.color]);
    // placesLoadedCallback is required for the Google Places API (added in index.html)
    useExternalScript('https://maps.googleapis.com/maps/api/js?key=' +
        config.mapKey +
        '&libraries=places&loading=async&callback=placesLoadedCallback');
    useEffect(function () {
        if (merchantData.productUUID) {
            dispatch(getVirtualCard(merchantData.productUUID));
            dispatch(getCardOfferImg(merchantData.productUUID));
            dispatch(getLockupImage(merchantData.productUUID));
            dispatch(getRewardDetails(merchantData.productUUID, languageCode));
            dispatch(getLogoIcon(merchantData.productUUID));
            dispatch(getSuccessQR(merchantData.productUUID));
            dispatch(getErrorQR(merchantData.productUUID));
            dispatch(getCheckApplicationQR(merchantData.productUUID));
            dispatch(getManualReviewQR(merchantData.productUUID));
            dispatch(getConfirmLaterQR(merchantData.productUUID));
            dispatch(getDownloadAppQRCode(merchantData.productUUID));
            dispatch(getColor(merchantData.productUUID));
        }
    }, [merchantData.productUUID, language]);
    useEffect(function () {
        setSessionUrlParam(window.location.search, dispatch);
        window.onbeforeunload = function (e) {
            ANALYTICS.analyticsUtil.writeStoredEvents();
        };
    }, []);
    useEffect(function () {
        var assetsToLoad = [
            merchantData.cardImg,
            merchantData.logoIcon,
            merchantData.color.light,
            merchantData.rewardDetails.rewards,
            merchantData.rewardDetails.rewardsBonus,
            featureFlags,
        ];
        var areLoaded = true;
        assetsToLoad.forEach(function (asset) {
            if (!asset && asset === '') {
                areLoaded = false;
            }
        });
        setAssetsLoaded(areLoaded);
    }, [
        merchantData.cardImg,
        merchantData.logoIcon,
        merchantData.color.light,
        merchantData.rewardDetails.rewards,
        merchantData.rewardDetails.rewardsBonus,
        featureFlags,
    ]);
    return (_jsx("div", __assign({ className: "App" }, { children: _jsxs(ImpSnackbarProvider, { children: [_jsx(NavigateSetter, {}), _jsx(ImpContainer, __assign({ backgroundColor: colors.surface.surfaceMid }, { children: internalAuth ? (_jsx(Login, {})) : (_jsxs(Routes, { children: [_jsx(Route, { path: PAGE.START, element: _jsx(ApplicationWrapper, {}) }), assetsLoaded ? (_jsxs(_Fragment, { children: [_jsx(Route, { path: "*", element: _jsx(Navigate, { to: PAGE.HOME }) }), _jsx(Route, { path: PAGE.HOME, element: _jsx(ApplicationHome, {}) }), _jsx(Route, { path: PAGE.OTP, element: _jsx(OtpVerify, {}) }), _jsx(Route, { path: PAGE.CHECK_APPLICATION, element: _jsx(CheckApplication, {}) }), _jsx(Route, { path: PAGE.OFFER, element: _jsx(OfferPage, {}) }), _jsx(Route, { path: PAGE.ERROR, element: _jsx(Error, {}) }), _jsx(Route, { path: PAGE.ACCEPT, element: _jsx(Accept, {}) }), _jsx(Route, { path: PAGE.ACCOUNT_STATUS, element: _jsx(AccountStatusPage, {}) }), _jsx(Route, { path: PAGE.HEB, element: _jsx(ApplicationHome, {}) }), _jsx(Route, { path: PAGE.CM, element: _jsx(ApplicationHome, {}) }), _jsx(Route, { path: PAGE.HICV, element: _jsx(ApplicationHome, {}) }), _jsx(Route, { path: PAGE.HH_CREDIT, element: _jsx(ApplicationHome, {}) }), _jsx(Route, { path: PAGE.WESTGATE, element: _jsx(ApplicationHome, {}) }), _jsx(Route, { path: PAGE.TURKISH, element: _jsx(ApplicationHome, {}) }), _jsx(Route, { path: PAGE.EB, element: _jsx(ApplicationHome, {}) }), _jsx(Route, { path: PAGE.BB, element: _jsx(ApplicationHome, {}) }), _jsx(Route, { path: PAGE.BOOKING, element: _jsx(ApplicationHome, {}) }), _jsx(Route, { path: PAGE.CRATE_BARREL, element: _jsx(ApplicationHome, {}) }), _jsx(Route, { path: PAGE.CREATE_PIN, element: _jsx(CreatePINPage, {}) }), _jsx(Route, { path: PAGE.COMPLETE_ON_PHONE, element: _jsx(CompleteOnPhonePage, {}) })] })) : (_jsx(Route, { path: "*", element: _jsx(ApplicationLoader, {}) }))] })) }))] }) })));
}
