var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ImpBulletList, ImpContainer, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
function DisclosureText(_a) {
    var children = _a.children;
    return (_jsx(ImpText, __assign({ typography: "body3", color: colors.content.onBackgroundVariant }, { children: children })));
}
export function IncomeDisclosure() {
    var language = useAppSelector(selectLanguage);
    return (_jsxs(ImpContainer, { children: [_jsx(DisclosureText, { children: language.incomeDisclosureTitle }), _jsx(ImpBulletList, { items: language.incomeDisclosureBullets, bulletStyle: {
                    typography: 'body3',
                    color: colors.content.onBackgroundVariant,
                }, noGap: true })] }));
}
export function HousingDisclosure() {
    var language = useAppSelector(selectLanguage);
    return (_jsx(ImpContainer, { children: _jsx(DisclosureText, { children: language.monthlyHousingCostDisclosureTitle }) }));
}
export function SsnDisclosure() {
    var language = useAppSelector(selectLanguage);
    return (_jsxs(_Fragment, { children: [_jsx(ImpSpacer, { height: "2px" }), _jsx(DisclosureText, { children: language.ssnDisclosure })] }));
}
