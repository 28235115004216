// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.N9RCh62RNKBBzaNXaBG3 {
  display: grid;
  position: relative;
  height: 100vh;
  grid-template-rows: auto 1fr;
  background-color: var(--background);
}

.pYA0l5W9IEshneaXyhyY {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: var(--margin-vertical-desktop);
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 620px) {
  .pYA0l5W9IEshneaXyhyY {
    margin-top: var(--margin-vertical-mobile);
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/error/Error.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,4BAA4B;EAC5B,mCAAmC;AACrC;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,0CAA0C;EAC1C,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE;IACE,yCAAyC;EAC3C;AACF","sourcesContent":[".outerContainer {\n  display: grid;\n  position: relative;\n  height: 100vh;\n  grid-template-rows: auto 1fr;\n  background-color: var(--background);\n}\n\n.content-wrapper {\n  position: relative;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  margin-top: var(--margin-vertical-desktop);\n  margin-right: auto;\n  margin-left: auto;\n}\n\n@media (max-width: 620px) {\n  .content-wrapper {\n    margin-top: var(--margin-vertical-mobile);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerContainer": `N9RCh62RNKBBzaNXaBG3`,
	"content-wrapper": `pYA0l5W9IEshneaXyhyY`,
	"contentWrapper": `pYA0l5W9IEshneaXyhyY`
};
module.exports = ___CSS_LOADER_EXPORT___;
