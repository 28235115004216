import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { getLockupPngUrl } from '../../api/getLockupImage/getLockupImage';
import { ApplicationLoader } from '../../components/ApplicationLoader/ApplicationLoader';
import useNextPage from '../../hooks/nextPage';
import { getProductUUIDFromPartnerKey } from '../../utils/productUUIDConstants';
import { isiOSWebkit } from '../../utils/sdkPostMessageHandler';
export default function ApplicationWrapper() {
    var nextPage = useNextPage();
    useEffect(function () {
        var partnerKey = sessionStorage.getItem('partner-key') || '';
        var productUUID = getProductUUIDFromPartnerKey(partnerKey);
        sendAssetsToHost(productUUID);
        nextPage();
    }, []);
    var sendAssetsToHost = function (productUUID) {
        var logoUrl = getLockupPngUrl(productUUID);
        var messageBody = { logoUrl: logoUrl };
        // send to iOS WKWebView
        if (isiOSWebkit()) {
            window.webkit.messageHandlers.imprintWebCallback.postMessage(messageBody);
        }
        //TODO: send to Android webview
    };
    return _jsx(ApplicationLoader, {});
}
