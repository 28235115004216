var _a;
import { createSlice } from '@reduxjs/toolkit';
export var getEmptyAmount = function () {
    return {
        displaySymbol: '',
        displayValue: '',
        displayUnit: '',
        exponent: 0,
        negative: false,
        valueInMinUnit: 0,
    };
};
var initialState = {
    merchantName: '',
    productUUID: '',
    cardImg: '',
    appUiImg: '',
    webUiImg: '',
    cardOfferImg: '',
    cardDesignOptions: [],
    cardDesignOptionsImages: {},
    counterOffer: {
        title: '',
        titleHighlight: '',
        subtitle: '',
        image: '',
        lightModeColor: '',
        darkModeColor: '',
        hyperlinkButtonName: '',
        url: '',
        disclosure: '',
    },
    rewardDetails: {
        merchantName: '',
        cardName: '',
        termCTA: '',
        rewardsType: '',
        signupBonus: {
            amount: '',
            amountSubtext: '',
            title: '',
            items: { text: '' },
        },
        rewards: [
            { amount: '', amountSubtext: '', title: '', items: { text: '' } },
            { amount: '', amountSubtext: '', title: '', items: { text: '' } },
        ],
        rewardsBonus: [],
        rewardsNote: '',
        primaryValueProp: '',
    },
    color: { dark: '', light: '' },
    logoIcon: '',
    deeplink: '',
    successQR: '',
    errorQR: '',
    checkApplicationQR: '',
    confirmLaterQR: '',
    manualReviewQR: '',
    lockupImage: '',
    downloadAppQR: '',
};
var merchantDataSlice = createSlice({
    name: 'merchantData',
    initialState: initialState,
    reducers: {
        setProductUUID: function (state, action) {
            state.productUUID = action.payload;
        },
        setCardImg: function (state, action) {
            state.cardImg = action.payload;
        },
        setCardDesignOptionImages: function (state, action) {
            var _a = action.payload, cardDesignID = _a.cardDesignID, physical = _a.physical, render = _a.render;
            state.cardDesignOptionsImages[cardDesignID] = {
                cardDesignID: cardDesignID,
                physical: physical,
                render: render,
            };
        },
        setAppUiImg: function (state, action) {
            state.appUiImg = action.payload;
        },
        setWebUiImg: function (state, action) {
            state.webUiImg = action.payload;
        },
        setCardOfferImg: function (state, action) {
            state.cardOfferImg = action.payload;
        },
        setRewardDetails: function (state, action) {
            state.rewardDetails = action.payload;
        },
        setColor: function (state, action) {
            state.color = action.payload;
        },
        setLogoIcon: function (state, action) {
            state.logoIcon = action.payload;
        },
        setupMerchantAndEnv: function (state, action) {
            state.productUUID = action.payload;
        },
        setMerchantName: function (state, action) {
            state.merchantName = action.payload;
        },
        setDeeplink: function (state, action) {
            state.deeplink = action.payload;
        },
        setSuccessQR: function (state, action) {
            state.successQR = action.payload;
        },
        setErrorQR: function (state, action) {
            state.errorQR = action.payload;
        },
        setCheckApplicationQR: function (state, action) {
            state.checkApplicationQR = action.payload;
        },
        setConfirmLaterQR: function (state, action) {
            state.confirmLaterQR = action.payload;
        },
        setManualReviewQR: function (state, action) {
            state.manualReviewQR = action.payload;
        },
        setDownloadAppQR: function (state, action) {
            state.downloadAppQR = action.payload;
        },
        setCounterOffer: function (state, action) {
            state.counterOffer = action.payload;
        },
        resetMerchantData: function (state) {
            state = initialState;
        },
        setLockupImage: function (state, action) {
            state.lockupImage = action.payload;
        },
        setRewardsNote: function (state, action) {
            state.rewardDetails.rewardsNote = action.payload;
        },
    },
});
export var setProductUUID = (_a = merchantDataSlice.actions, _a.setProductUUID), setCardImg = _a.setCardImg, setAppUiImg = _a.setAppUiImg, setWebUiImg = _a.setWebUiImg, setCardOfferImg = _a.setCardOfferImg, setCardDesignOptionImages = _a.setCardDesignOptionImages, setRewardDetails = _a.setRewardDetails, setColor = _a.setColor, setLogoIcon = _a.setLogoIcon, setupMerchantAndEnv = _a.setupMerchantAndEnv, setMerchantName = _a.setMerchantName, setDeeplink = _a.setDeeplink, setSuccessQR = _a.setSuccessQR, setErrorQR = _a.setErrorQR, setCheckApplicationQR = _a.setCheckApplicationQR, setConfirmLaterQR = _a.setConfirmLaterQR, setManualReviewQR = _a.setManualReviewQR, setDownloadAppQR = _a.setDownloadAppQR, setCounterOffer = _a.setCounterOffer, resetMerchantData = _a.resetMerchantData, setLockupImage = _a.setLockupImage;
export default merchantDataSlice.reducer;
