var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useImpState } from '../../app/hooks';
import close from '../../assets/icons/close.svg';
import Button from '../button/Button';
import DynamicQRCode from '../dynamicQRCode/dynamicQRCode';
import styles from '../popup/Popup.module.css';
export default function Modal(_a) {
    var header = _a.header, body = _a.body, buttonName1 = _a.buttonName1, buttonName2 = _a.buttonName2, button1Click = _a.button1Click, button2Click = _a.button2Click, handleCloseBtn = _a.handleCloseBtn, pillBtn1 = _a.pillBtn1, pillBtn2 = _a.pillBtn2, showQR = _a.showQR, qr = _a.qr, qrMessage = _a.qrMessage, bodyButtonName = _a.bodyButtonName, bodyButtonClick = _a.bodyButtonClick, pillBodyButton = _a.pillBodyButton, appDownload = _a.appDownload;
    var language = useImpState().language;
    if (language === null) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return _jsx(_Fragment, {});
    }
    return (_jsx("div", __assign({ className: styles.overlay }, { children: _jsxs("div", __assign({ className: styles.modal }, { children: [_jsx("img", { src: close, alt: "close button", className: styles.close, onClick: function () { return handleCloseBtn(); } }), _jsx("p", __assign({ className: styles.header, tabIndex: 0 }, { children: header })), _jsx("p", __assign({ className: styles.body, tabIndex: 0 }, { children: body })), showQR && (_jsx("div", __assign({ className: styles.qrCode }, { children: _jsx(DynamicQRCode, { width: "80", height: "80", image: qr || '' }) }))), qrMessage && (_jsx("p", __assign({ className: styles.qrMessage, tabIndex: 0 }, { children: qrMessage }))), bodyButtonName && (_jsx("div", __assign({ className: appDownload
                        ? "".concat(styles.bodyButton, " ").concat(styles.appDownload)
                        : styles.bodyButton }, { children: _jsx(Button, { value: bodyButtonName, pill: pillBodyButton ? true : false, handleClick: function () { return bodyButtonClick(); } }) }))), buttonName1 && (_jsx("div", __assign({ className: styles.button1 }, { children: _jsx(Button, { value: buttonName1, pill: pillBtn1 ? true : false, handleClick: function () { return button1Click(); } }) }))), buttonName2 && (_jsx("div", __assign({ className: styles.button2 }, { children: _jsx(Button, { value: buttonName2, pill: pillBtn2 ? true : false, handleClick: function () { return button2Click(); } }) })))] })) })));
}
